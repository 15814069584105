import { Box, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Fragment } from "react";
import ButtonBold from "../components/ButtonBold";
import StarIcon from "../icons/Star";

export default function DashboardPanelSurveys(props) {
  const { onAdd, titulo } = props;

  return (
    <Fragment>
      <Box marginBottom="20px" marginTop="20px">
        <Grid container spacing={1}>
          <Grid item xs>
            <Typography variant="h1">{titulo}</Typography>
          </Grid>
          <Grid item xs="auto">
            <ButtonBold
              variant="outlined"
              onClick={onAdd}
              // TODO: Cambiar el ícono
              startIcon={<StarIcon />}
            >
              Agregar pyme
            </ButtonBold>
          </Grid>
        </Grid>
      </Box>
      {props.children}
    </Fragment>
  );
}

DashboardPanelSurveys.propTypes = {
  onAdd: PropTypes.func.isRequired,
};
