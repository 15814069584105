import { SvgIcon } from "@material-ui/core";

export default function CircleCheckIcon(props) {
  return (
    <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="11.5" fill="white" stroke="#2B44FF" />
      <path
        d="M 16.453125 7.453125 C 16.667969 7.253906 16.949219 7.144531 17.238281 7.144531 C 17.53125 7.148438 17.808594 7.261719 18.019531 7.46875 C 18.226562 7.671875 18.347656 7.949219 18.355469 8.238281 C 18.363281 8.53125 18.257812 8.816406 18.058594 9.03125 L 12.074219 16.515625 C 11.972656 16.625 11.847656 16.714844 11.710938 16.777344 C 11.570312 16.839844 11.421875 16.871094 11.269531 16.875 C 11.121094 16.878906 10.96875 16.847656 10.828125 16.792969 C 10.6875 16.734375 10.5625 16.652344 10.453125 16.546875 L 6.484375 12.574219 C 6.375 12.472656 6.285156 12.347656 6.226562 12.210938 C 6.164062 12.074219 6.128906 11.921875 6.128906 11.773438 C 6.125 11.621094 6.152344 11.472656 6.210938 11.332031 C 6.265625 11.191406 6.351562 11.0625 6.457031 10.957031 C 6.5625 10.851562 6.691406 10.765625 6.832031 10.710938 C 6.972656 10.652344 7.121094 10.625 7.273438 10.628906 C 7.421875 10.628906 7.574219 10.664062 7.710938 10.726562 C 7.847656 10.785156 7.972656 10.875 8.074219 10.984375 L 11.21875 14.125 L 16.425781 7.488281 C 16.4375 7.476562 16.445312 7.464844 16.457031 7.453125 Z M 16.453125 7.453125 "
        fill="#2B44FF"
      />
    </SvgIcon>
  );
}
