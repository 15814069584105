import React, {useState, useContext, useEffect, Fragment} from 'react';
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import {ContextGlobal} from '../context/globalContext';
import axios from "axios";
import {
  BrowserRouter as Router,
  useHistory,
  Link,
  useParams
} from "react-router-dom";
import './../scss/style.scss';
import ResultPanelGeneral from "../lib/result/ResultPanelGeneral";
import ResultPanelNextSteps from "../lib/result/ResultPanelNextSteps";
import ResultPanelMaturityByDimensions from "../lib/result/ResultPanelMaturityByDimensions";
import ResultPanelComparison from "../lib/result/ResultPanelComparison";
import ResultPanelMaturityInTime from "../lib/result/ResultPanelMaturityInTime";

export default function ResultLayout() {
  let { cid, recent } = useParams();
  let history = useHistory();
  const {user} = useContext(ContextGlobal);
  const {dash} = useContext(ContextGlobal);
  const [result, setResult] = useState();
  const [rutp, setRutp] = useState();

  const onBack = () => history.push('/');
  const decimalToHexString = (number) => {
    if (number < 0) { number = 0xFFFFFFFF + number + 1; }
    return number.toString(16).toUpperCase();
  }  
  
  const apiUrl = "/api";

  const getSurvey = async (formBarsValues) => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
    });
    const result = await apiAxios
      .post("/resultado/"+parseInt(cid, 16), formBarsValues)
      .then((response) => {
        setResult(response.data.data);
        setRutp(response.data.data.header.companyRut);
        //console.log(response.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  }  

  const newSurvey = async (rut_pyme) => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
    });
    const result = await apiAxios
      .post("/evaluacion", {rut_pyme: rutp})
      .then((response) => {
        history.push('/mainsurvey/'+decimalToHexString(response.data.data.id));
      })
      .catch((error) => {
        //console.log(error.response?.data);
      });
  }

  const filtraenc = (dashc, filtro) => {
    if (!dashc) { return [] } 
    return dashc.filter((cmp) => filtro === cmp.id).puedeiniciarchequeo;
  } 
  
  const onViewOffer = () => {
    history.push('/offersurvey/'+cid+"/dim/0");
  }

  useEffect( () => { getSurvey(); }, [])
  //console.log("U:", user);
  //console.log("D:", dash);
  console.log("R:", result);


  return (
    <div style={{marginTop: "40px"}}>
    <Fragment>
      <Container style={{maxWidth: "1150px"}}>
        <Grid container justify="space-between">
          <Grid item>
            <Button variant="outlined" size="small" onClick={onBack}>
              Volver al perfil de usuario
            </Button>
            <br/>
            {recent==='1' && (<><br/><h4>Has terminado el Chequeo Digital</h4></>)}
            <h4>Descubre tus resultados</h4>
          </Grid>
          <Grid item>

          {dash?.header?.puedeiniciarchequeo &&
            <Button variant="outlined" color="primary" onClick={newSurvey}>
              Nuevo Chequeo
            </Button>
          }

          </Grid>
          <Grid item>
                <Button variant="contained" color="primary" onClick={
                  ()=>{
                    let form = document.createElement('form')
                    form.method = 'post'
                    form.target = '_blank'
                    form.action = '/api/descargaresultado/' + cid
                    form.innerHTML = '<input type="hidden" name="token" value="' + sessionStorage.getItem('access_token') + '">'
                
                    console.log('form:', form)
                
                    document.body.appendChild(form)
                    form.submit()
                    document.body.removeChild(form)
                
                  }
                }>Descargar Reporte</Button>

          </Grid>
        </Grid>
        <Box marginTop="33px" marginBottom="33px">
          <ResultPanelGeneral
            {...result?.header}
            onDownloadDocument={() => {
              //console.log("onDownloadDocument");
            }}
          />
        </Box>

{/*         <Box marginTop="33px" marginBottom="33px">
          <div style={{display: "flex", background: "#2B44FF", borderRadius: "8px", color: "white"}}>
          <div style={{width: "60%", padding: "40px"}}>
          También puedes acceder al Kit Digitalízate, un kit con herramientas digitales a precios preferenciales y beneficios exclusivos de más de 45 proveedores tecnológicos. Oferta disponible sólo hasta el domingo 31 de octubre
          </div>
          <div style={{width: "40%", padding: "40px"}}>
          <Button
            style={{background: "white"}}
            fullWidth
            variant="outlined"
            color="primary"
            onClick={()=>{window.open("https://kit.chequeodigital.cl");}}>
              Quiero Acceder al KIT DIGITALÍZATE
          </Button>
          </div>
          </div>
        </Box> */}

{/*
        <Box marginTop="33px" marginBottom="33px">
          <div style={{display: "flex", background: "#2B44FF", borderRadius: "8px", color: "white"}}>
          <div style={{width: "60%", padding: "40px"}}>
          Para avanzar en el proceso de digitalización de tu pyme, te invitamos a conocer la oferta gratuita de capacitaciones especialmente seleccionada para ti.
          </div>
          <div style={{width: "40%", padding: "40px"}}>
          <Button
            style={{background: "white"}}
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onViewOffer}
          >¡Quiero pasar al siguiente nivel!
          </Button>
          </div>
          </div>
        </Box>
      */}
{/*         <Box marginBottom="16px">
          <ResultPanelNextSteps title={result?.header?.title}>
            {result?.header?.text.split("\n").map((texto) => 
                (<Typography variant="body1" style={{ opacity: 0.7 }} paragraph>
                    {texto}
                </Typography>)
            )}
          </ResultPanelNextSteps>
        </Box> */}
        <Box marginBottom="33px">
          <ResultPanelMaturityByDimensions
            items={ (result?.dimensions?result.dimensions.sort((a,b)=>{return a.tier.value-b.tier.value;}).map(
                (dim, index)  => ({...dim,
                  onViewOffer: ((index) => () => history.push('/offersurvey/'+cid+"/dim/"+dim.id)) (index)
                  })) 
                : [])}
          />
        </Box>

        <Box marginBottom="33px">
          <ResultPanelComparison
            dimensions={result?.dimensions? result.dimensions : []}
            rubros  ={result?.rubros? result.rubros : []}
            tamanos ={result?.tamanos? result.tamanos : []}
            regiones={result?.regiones? result.regiones : []}
            barsData={result?.barsData? result.barsData : {
              my: {
                title: "Inicial",
                description: "Mi pyme",
                value: 0,
              },
              others: {
                title: "Inicial",
                description: "Otras pymes",
                value: 0,
              },
            }}
            onUpdateBars={(formBarsValues) => {
              getSurvey(formBarsValues);
            }}
          />
        </Box>
        {result?.showmaturity &&
        <Box marginBottom="33px">
          <ResultPanelMaturityInTime
            data={result?.intime? result.intime : []}
            dimensions={result?.intimedimensions? result.intimedimensions : []}
          />
        </Box>
        }
      </Container>
    </Fragment>
    </div>
  );
}
