import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flexStart",
    padding: "16px",
    background: "#19BF3E",
    borderRadius: "8px",
  },
  text: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
  },
});

export default function AlertSuccess(props) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.text}>{props.children}</Box>
    </Box>
  );
}

AlertSuccess.propTypes = {
  children: PropTypes.node,
};
