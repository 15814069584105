import { KeyboardDatePicker } from "@material-ui/pickers";
import TextField from "./TextField";
import PropTypes from "prop-types";
import { forwardRef, useCallback, useMemo } from "react";
import isValid from "date-fns/isValid";
import formatDate from "date-fns/format";
import parse from "date-fns/parse";

const DateTextField = forwardRef(function DateTextField(
  { value: valueProp, onChange: onChangeProp, ...props },
  ref
) {
  const value = useMemo(() => {
    return valueProp ? parse(valueProp, "yyyy-MM-dd", new Date()) : null;
  }, [valueProp]);
  const onChange = useCallback(
    (v) => {
      const valueString = isValid(v) ? formatDate(v, "yyyy-MM-dd") : "";
      onChangeProp && onChangeProp(valueString);
    },
    [onChangeProp]
  );
  return (
    <KeyboardDatePicker
      clearable
      orientation="portrait"
      disableFuture
      openTo="year"
      format="dd/MM/yyyy"
      views={["year", "month", "date"]}
      TextFieldComponent={TextField}
      KeyboardButtonProps={{ edge: "end" }}
      clearLabel="Limpiar"
      cancelLabel="Cancelar"
      placeholder="DD/MM/AÑO"
      ref={ref}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
});

DateTextField.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};

export default DateTextField;
