import { Box, Divider as DividerCore, makeStyles } from "@material-ui/core";
import * as React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    height: 20,
    display: "flex",
    alignItems: "center",
    opacity: 0.07,
  },
  divider: {
    flex: 1,
    backgroundColor: "#000000",
  },
});

const Divider = React.forwardRef(function Divider(
  { className, ...props },
  ref
) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <DividerCore
        className={clsx(classes.divider, className)}
        {...props}
        ref={ref}
      />
    </Box>
  );
});

Divider.propTypes = {
  /**
   * Absolutely position the element.
   */
  absolute: PropTypes.bool,
  /**
   * @ignore
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   */
  component: PropTypes.elementType,
  /**
   * If `true`, a vertical divider will have the correct height when used in flex container.
   * (By default, a vertical divider will have a calculated height of `0px` if it is the child of a flex container.)
   */
  flexItem: PropTypes.bool,
  /**
   * If `true`, the divider will have a lighter color.
   */
  light: PropTypes.bool,
  /**
   * The divider orientation.
   */
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  /**
   * @ignore
   */
  role: PropTypes.string,
  /**
   * The variant to use.
   */
  variant: PropTypes.oneOf(["fullWidth", "inset", "middle"]),
};

export default Divider;
