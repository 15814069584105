import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

function clamp(value, min, max) {
  return Math.min(Math.max(min, value), max);
}

const useStyles = makeStyles(
  () => ({
    rail: {
      position: "relative",
      display: "flex",
      flexDirection: "column-reverse",
      gap: "4px",
      width: "130px",
      height: "250px",
      borderRadius: 8,
      backgroundColor: "#DFE0E7",
      "&$colorPrimary": {
        backgroundColor: "#B5BEFF",
      },
    },
    track: {
      flex: 1,
      width: "100%",
      borderRadius: 8,
      backgroundColor: "#D6D7DF",
      "&$active": {
        backgroundColor: "#B5B6C6",
      },
      "&$colorPrimary": {
        backgroundColor: "#A3AEFF",
        "&$active": {
          backgroundColor: "#2B44FF",
        },
      },
    },
    colorPrimary: {},
    active: {},
  }),
  { name: "Bar" }
);

export default function Bar(props) {
  const { color = "default", value: valueProp = 0 } = props;
  const classes = useStyles();
  const value = clamp(valueProp, 0, 5);
  const boxes = [...Array(5)];
  return (
    <Box
      className={clsx(classes.rail, {
        [classes.colorPrimary]: Boolean(color === "primary"),
      })}
      width="130px"
      height="250px"
    >
      {boxes.map((b, index) => (
        <Box
          key={index}
          className={clsx(classes.track, {
            [classes.colorPrimary]: Boolean(color === "primary"),
            [classes.active]: index < value,
          })}
        />
      ))}
    </Box>
  );
}

Bar.propTypes = {
  color: PropTypes.oneOf(["default", "primary"]),
  /**
   * Debe se run valor mayor o igual 0 y menor o igual a 5
   */
  value: PropTypes.number,
};
