import { Box, Button, Container, Grid } from "@material-ui/core";
import OfferForm from "./OfferForm";
import OfferResults from "./OfferResults";

export default function OfferLayout() {
  const onBack = () => console.log("onBack");
  const formProps = {
    subtitle: "Mostrando  6 de 15 Resultados",
    dimensions: [],
    offerTypes: [
      { value: "CHARLAS", label: "Charlas" },
      { value: "SEMINARIOS", label: "Seminarios" },
      { value: "TALLERES", label: "Talleres" },
    ],
    organizers: [],
    onSubmit: (values) => console.log("onSubmit", { values }),
  };
  const results = {
    onLoadMore: () => console.log("onLoadMore"),
    results: [
      {
        overTags: ["Charla", "Online"],
        title: "Aprende cómo desarrollar tu e-commerce propio parte",
        tags: ["Comunicaciones"],
        organizer: "Corfo",
        date: "Sábado 07 octubre, 2021",
        location: "Picarte 2840, Valdivia, Región de los Ríos",
      },
      {
        overTags: ["Clase Magistral", "Online"],
        title: "Aprende cómo desarrollar tu e-commerce propio parte",
        tags: ["Comunicaciones"],
        organizer: "Corfo",
        date: "Sábado 07 octubre, 2021",
      },
    ],
  };
  return (
    <Container>
      <Box marginBottom="32px">
        <Button variant="outlined" size="small" onClick={onBack}>
          Volver
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md="auto">
          <Box
            paddingLeft="16px"
            paddingRight="16px"
            paddingBottom="16px"
            width="333px"
          >
            <OfferForm {...formProps} />
          </Box>
        </Grid>
        <Grid item xs={12} md>
          <OfferResults {...results} />
        </Grid>
      </Grid>
    </Container>
  );
}
