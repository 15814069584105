import React, { useState, useContext } from "react";
import { ContextGlobal } from "./../context/globalContext";
import axios from "axios";
import { Formik, useFormik } from "formik";
import ReactJson from "react-json-view";
import * as Yup from "yup";
import { BrowserRouter as Router, useHistory, Link } from "react-router-dom";

import claveuni from "./../img/login.svg";
import hand from "./../img/hand.svg";
import TextField from "../lib/components/TextField";
import "./../scss/style.scss";
import { Button } from "@material-ui/core";

function LoginPanel(props) {
  const [msg, cambiarMsg] = useState("");
  const { authSes } = useContext(ContextGlobal);

  const valSchema = Yup.object().shape({
    log_eml: Yup.string().email("Debe ingresar un correo electronico válido"),
    log_clave: Yup.string().required("Debe ingresar contraseña"),
  });

  const apiUrl = "/api";
  let history = useHistory();

  const form_reg = useFormik({
    initialValues: {
      log_eml: "",
      log_clave: "",
    },
    onSubmit: async (e) => {
      cambiarMsg("");
      const apiAxios = axios.create({
        baseURL: apiUrl,
      });
      const result = apiAxios
        .post("/auth/login", {
          email: `${form_reg.values.log_eml}`,
          password: `${form_reg.values.log_clave}`,
        })
        .then((response) => {
          authSes(response.data.access_token);
          sessionStorage.setItem("access_token", response.data.access_token);
          sessionStorage.setItem("user_log", "true");
          history.push("/");
        })
        .catch((error) => {
          if (error.response.data.message == "No Autorizado") {
            cambiarMsg("Correo Electrónico o Contraseña no existe.");
          } else if (error.response.data.status == "02") {
            cambiarMsg(
              "El correo esta registrado, pero aun no ha sido Validado. Por favor revise su bandeja de entrada y siga las instrucciones"
            );
          } else {
            cambiarMsg(
              "Algo salio mal, por favor intente mas tarde."
            );
          }
        });
    },
    validationSchema: valSchema,
  });

  const emailError = form_reg.touched.log_eml && form_reg.errors.log_eml;

  return (
    <div className="App" style={{ width: "100%" }}>
      <div className="si-content">
        <div className="si-marco">

        <div className="m-title">Iniciar sesión</div>
        <div className="m-bajada">
          ¡Hola! <img src={hand} alt="mano" /> Para continuar, ingresa tus datos de acceso
        </div>

          <hr className="si-separador"></hr>

          <form action="" onSubmit={form_reg.handleSubmit}>
            <div>
              <TextField
                label="CORREO ELECTRÓNICO"
                name="log_eml"
                id="log_eml"
                value={form_reg.values.log_eml}
                onChange={form_reg.handleChange}
                onBlur={form_reg.handleBlur}
                error={emailError}
                helperText={emailError}
                fullWidth
                autoComplete="username"
              />
            </div>
            <div style={{ marginTop: "15px" }}>
              <TextField
                label="CONTRASEÑA"
                type="password"
                name="log_clave"
                id="log_clave"
                value={form_reg.values.log_clave}
                onChange={form_reg.handleChange}
                onBlur={form_reg.handleBlur}
                fullWidth
                autoComplete="current-password"
              />
            </div>
            <span style={{ color: "red", fontSize: "14px" }}>{msg}</span>
            <hr className="si-separador"></hr>

            <Button
              color="primary"
              variant="contained"
              type="submit"
              name="ingresar"
              disabled={!(form_reg.isValid && form_reg.dirty)}
              fullWidth
            >
              Ingresar
            </Button>
          </form>
          <hr className="si-separador"></hr>
          <div className="m-bajada" style={{marginTop: "60px", marginBottom: "20px"}}>
            O puedes ingresar usando tu ClaveÚnica
          </div>
          <a href="/api/auth/initclaveunica">
          <button name="claveunica" className="btn-clave-unica">
            <img src={claveuni} className="icon-clave-unica" />Iniciar sesión
          </button>
          </a>
          <hr className="si-separador"></hr>
          <div style={{ fontSize: "14px", fontWeight: "400", marginTop: "30px" }}>
            ¿No recuerdas tu <Link to="/recoverydni">correo electrónico</Link> o
            tu <Link to="/recovery">contraseña</Link>?
          </div>
          <br />
          <span style={{ fontSize: "14px", fontWeight: "400" }}>
            ¿No tienes cuenta? <Link to="/register">regístrate aquí</Link>
          </span>
        </div>
{/*         <div style={{ marginTop: "50px" }}>
          <ReactJson src={form_reg.values} collapsed="true" />
          <ReactJson src={form_reg.errors} collapsed="true" />
          <ReactJson src={form_reg.touched} collapsed="true" />
        </div> */}
      </div>
    </div>
  );
}

export default LoginPanel;
