import { withStyles } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";

export const styles = (theme) => ({
  root: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "157.33%",
    color: "#000000",
    opacity: 0.3,
    "&$error": {
      opacity: 1,
    },
  },
  error: {},
});

export default withStyles(styles, { name: "BFormHelperText" })(FormHelperText);
