import React, {useState, useContext, useEffect} from 'react';
import {ContextGlobal} from './../context/globalContext';
import axios from 'axios';
import Countdown, {zeroPad} from 'react-countdown';
import { Formik, useFormik } from 'formik';
import ReactJson from 'react-json-view';
import * as Yup from 'yup'; 
import {
    BrowserRouter as Router,
    useHistory,
    Link
  } from "react-router-dom";
import './../scss/style.scss';
import mail from "./../img/logo_eml.svg";

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
};

function MainPanel() {
  const [msg, cambiarMsg] = useState('');
  const [activo, setActivo] = useState(true);
  const enableButton = () => setActivo(false);
  const blockButton = () => setActivo(true);
  const {user} = useContext(ContextGlobal);
  const apiUrl = '/api';
  let history = useHistory();

  const valSchema = Yup.object().shape({
  })

  const form_reg = useFormik({
    initialValues: {
      emptyt: ''  
    },
    onSubmit: async (e) => {
      cambiarMsg('');
      const apiAxios = axios.create({
        baseURL: apiUrl,
          })
      const result = apiAxios.post('/password/email', { 
            email: `${sessionStorage.getItem('u_eml')}`
            })
            .then((response) => {
              //console.log(response.data);
              history.go(0);
            }).catch((error) => {
              if(error.response.data.status=='06') { 
                cambiarMsg('Correo Electrónico no existe.');
              } else if(error.response.data.status=='02') {
                cambiarMsg('El correo esta registrado, pero aun no ha sido Validado. Por favor revise su bandeja de entrada y siga las instrucciones');
              } else {
                cambiarMsg('Algo salio mal, por favor intente mas tarde');
              }
              //console.log(error.response.data);
            })
      ;
    },
    validationSchema: valSchema
  });

  return (
    <>
    {sessionStorage.getItem('access_token') !== null}
    <div className="App" style={{width: '100%'}}>
        <div className="c-fullw"><img src={mail} alt="Correo" /></div>
        <div className="si-content-r" style={{textAlign:'center', fontSize: '14px'}}>
            <span style={{fontSize:'21px', fontWeight:'500'}}>Reactiva tu cuenta</span>
            <br/><br/>
            Hemos enviado un correo a {user.email ? user.email : sessionStorage.getItem('u_eml')}
            <hr className="si-separador"></hr>
            ¿No te llegó el correo?, espera <Countdown date={Date.now() + 60000} renderer={renderer} onComplete={enableButton} /> para reenviar uno nuevo
            <br/>
            <br/><br/>
            <form action="" onSubmit={form_reg.handleSubmit}>
                <button type="submit" name='ingresar' className='btn-registro' style={{maxWidth: "270px"}} disabled={activo}>Reenviar correo electrónico</button>
                <br/><span style={{color: 'red', fontSize: '14px'}}>{msg}</span>
            </form>
        </div>
    </div>
    </>
  );
}

export default MainPanel;
