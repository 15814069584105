import { SvgIcon } from "@material-ui/core";

export default function ExpandLess(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 21 21" fill="none">
      <path
        d="M3.65966 13.0803L10.0437 6.47461L16.4277 13.0803C16.6011 13.2603 16.6959 13.5019 16.6912 13.7518C16.6866 14.0018 16.5828 14.2396 16.4027 14.4131C16.2227 14.5865 15.9811 14.6813 15.7312 14.6766C15.4813 14.6719 15.2434 14.5682 15.07 14.3881L10.0437 9.18449L5.01183 14.3881C4.8384 14.5682 4.60055 14.6719 4.35061 14.6766C4.10066 14.6813 3.8591 14.5865 3.67905 14.4131C3.49901 14.2396 3.39523 14.0018 3.39056 13.7518C3.38588 13.5019 3.48069 13.2603 3.65411 13.0803H3.65966Z"
        fill="#2B44FF"
      />
    </SvgIcon>
  );
}
