import React, {useState, useContext, useEffect} from 'react';
import {ContextGlobal} from './../context/globalContext';
import {
  BrowserRouter as Router,
  useHistory,
  Link,
  useParams
} from "react-router-dom";


function ClaveUnica() {
  const {authSes} = useContext(ContextGlobal);
  let history = useHistory();
  let { token } = useParams();

  useEffect( () => {
    authSes(token);
    sessionStorage.setItem("access_token", token);
    sessionStorage.setItem("user_log", "true");
    history.push('/');
    } )

  return (
    <>
    </>
  );
}

export default ClaveUnica;