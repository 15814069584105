import { Paper, Box, makeStyles, Grid, Button } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { Fragment } from "react";
import PropTypes from "prop-types";
import TimeIcon from "../icons/Time";
import PlaceIcon from "../icons/Place";
import StarIcon from "../icons/Star";

const useStyles = makeStyles({
  header: {
    backgroundColor: "#2B44FF",
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  overTag: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    padding: "4px 8px",
    background: "#F5F6FF",
    borderRadius: "4px",
    color: "#425466",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "30px",
    color: "#FFFFFF",
  },
  tag: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    padding: "4px 8px",
    background: "#EEEFFB",
    borderRadius: "4px",
    color: "#2B44FF;",
  },
  cellIcon: {
    fontSize: "16px",
    verticalAlign: "middle",
    paddingRight: "13px",
  },
  cellText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.02em",
    color: "#425466",
    opacity: "0.8",
  },
});

export default function OfferResult(props) {
  const {
    overTags = [],
    title,
    tags = [],
    organizer,
    date,
    location,
    onAccessHere,
    url,
  } = props;
  const classes = useStyles();
  return (
    <Fragment>
      <Paper>
        <Box>
          <Box padding="16px" className={classes.header}>
            <Box marginBottom="10px">
              <Box className={classes.title}>{title}</Box>
            </Box>
            <Grid container spacing={1}>
              {tags.map((tag, index) => (
                <Grid item xs="auto" key={index}>
                  <Box component="span" className={classes.tag}>
                    {tag}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box padding="16px">
            <Box marginBottom="16px">
              <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs>
                  <Box display="table" style={{ opacity: 0.7 }}>
                    {organizer && (
                      <Box display="table-row">
                        <Box display="table-cell" className={classes.cellIcon}>
                          <StarIcon fontSize="inherit" />
                        </Box>
                        <Box display="table-cell" className={classes.cellText}>
                          {organizer}
                        </Box>
                      </Box>
                    )}
                    {date && (
                      <Box display="table-row">
                        <Box display="table-cell" className={classes.cellIcon}>
                          <TimeIcon fontSize="inherit" />
                        </Box>
                        <Box display="table-cell" className={classes.cellText}>
                          {date}
                        </Box>
                      </Box>
                    )}
                    {location && (
                      <Box display="table-row">
                        <Box display="table-cell" className={classes.cellIcon}>
                          <PlaceIcon fontSize="inherit" />
                        </Box>
                        <Box display="table-cell" className={classes.cellText}>
                          {location}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Box width={128}>
                    <Grid container spacing={1}>
                      {overTags.map((overTag, index) => (
                        <Grid item xs="auto" key={index}>
                          <Box component="span" className={classes.overTag}>
                            {overTag}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box textAlign="right">


              <Button
                component={Link}
                color="primary"
                variant="outlined"
                size="small"
                target="_blank"
                to={{ pathname: url }}
              >
                Accede aquí
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Fragment>
  );
}

OfferResult.propTypes = {
  overTags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  organizer: PropTypes.string,
  date: PropTypes.string,
  location: PropTypes.string,
  onAccessHere: PropTypes.func,
};
