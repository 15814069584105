import React, {useState, useContext, useEffect} from 'react';
import {
  BrowserRouter as Router,
  useHistory,
  Link,
  useParams
} from "react-router-dom";
import logo from './../img/logo_dgpy.svg';
import './../scss/style.scss';

function MainPanel() {
  const me = 'hola';
  return (
    <>
    {sessionStorage.getItem('access_token') !== null}
    <div className="App" style={{width: '100%'}}>
        <div className="si-content" style={{textAlign:'center', fontSize: '14px'}}>
            <span style={{fontSize:'21px', fontWeight:'500'}}>Tu correo ha sido verificado</span>
            <br/>
            
            <hr className="si-separador"></hr>
            por favor ingresa de nuevo al sistema
            <br/><br/>
            <Link to="/">
            <button type="submit" name='ingresar' className='btn-registro' style={{maxWidth: "270px"}}>ingresar</button>
            </Link>

        </div>
    </div>
    </>
  );
}

export default MainPanel;
