import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Caption from "../components/Caption";
import ResultPanelGeneralSpeedMeter from "./ResultPanelGeneralSpeedMeter";

export default function ResultPanelGeneral(props) {
  const {
    tier,
    companyName,
    madeBy,
    email,
    start,
    end,
    onDownloadDocument,
    onViewOffer,
  } = props;
  return (
    <Paper>
      <Box padding="26px">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box padding="16px">
              <ResultPanelGeneralSpeedMeter { ...tier } />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box padding="16px">
              <Box marginBottom="32px">
                <Typography variant="h1">{companyName}</Typography>
              </Box>
              <Box marginBottom="32px">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Box marginBottom="8px">
                      <Caption style={{ opacity: 0.5 }}>Realizado por</Caption>
                    </Box>
                    <Typography variant="body1">{madeBy}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box marginBottom="8px">
                      <Caption style={{ opacity: 0.5 }}>Correo electrónico</Caption>
                    </Box>
                    <Typography variant="body1">{email}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box marginBottom="8px">
                      <Caption style={{ opacity: 0.5 }}>Inicio</Caption>
                    </Box>
                    <Typography variant="body1">{start}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box marginBottom="8px">
                      <Caption style={{ opacity: 0.5 }}>Término</Caption>
                    </Box>
                    <Typography variant="body1">{end}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box marginBottom="8px">
                      <Caption style={{ opacity: 0.5 }}>Descripción Nivel</Caption>
                    </Box>
                    <Typography variant="body1" style={{textAlign: "justify"}}>{tier?.description}</Typography>
                  </Grid>
                </Grid>
              </Box>
{/*               <Box>
                <Box marginBottom="10px">
                  <Button
                    fullWidth
                    variant="outlined"
                    style={{ paddingTop: "7px", paddingBottom: "7px" }}
                    onClick={onDownloadDocument}
                  >
                    <img
                      src="/images/pdf.svg"
                      alt="Ícono PDF"
                      style={{ marginRight: "10px" }}
                    />
                    Descargar mi chequeo
                  </Button>
                </Box>
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

ResultPanelGeneral.propTypes = {
  tier: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  companyName: PropTypes.string,
  madeBy: PropTypes.string,
  email: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  onDownloadDocument: PropTypes.func,
  onViewOffer: PropTypes.func,
};
