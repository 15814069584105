import { makeStyles } from "@material-ui/core";
import { Chart } from "react-google-charts";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    "& svg > g:nth-child(3) > g:nth-child(2) > g > g > path": {
      fillOpacity: "0.08 !important",
    },
    "& svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(1) > rect": {
      fillOpacity: "0.35 !important",
    },
  },
});

export default function ResultPanelMaturityInTimeDimensionChart(props) {
  const classes = useStyles();
  const { data } = props;
  return (
    <Chart
      height="130px"
      chartType="AreaChart"
      loader={<div>Loading Chart</div>}
      className={classes.root}
      data={[["Fecha", "Nivel"]].concat(data.map((d) => [`${d}`, d]))}
      options={{
        legend: "none",
        hAxis: {
          textPosition: "none",
        },
        vAxis: {
          baselineColor: "none",
          textPosition: "none",
          minValue: 0,
          maxValue: 4,
          gridlines: { count: 0 },
        },
        // For the legend to fit, we make the chart area smaller
        chartArea: { width: "100%", height: "100%" },
        lineWidth: 0,
        colors: ["#2B44FF"],
        backgroundColor: "#F9FAFC",
      }}
      // For tests
      rootProps={{ "data-testid": "1" }}
    />
  );
}

ResultPanelMaturityInTimeDimensionChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
};
