import { Box, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import ResultPanelMaturityByDimensionsItem from "./ResultPanelMaturityByDimensionsItem";
export default function ResultPanelMaturityByDimensions(props) {
  const { items } = props;
  return (
    <Paper>
      <Box padding="26px" paddingBottom="0px">
        <Box marginBottom="18px">
          <Typography variant="h1">
            Conoce el detalle de tus resultados por dimensión
          </Typography>
        </Box>
        <Box>
          {items.map(({ onViewOffer, ...item }, index) => (
            <ResultPanelMaturityByDimensionsItem
              {...item}
              key={index}
              onViewOffer={() => onViewOffer(index)}
            />
          ))}
        </Box>
      </Box>
    </Paper>
  );
}

ResultPanelMaturityByDimensions.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      tier: PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired,
      onViewOffer: PropTypes.func.isRequired,
    })
  ).isRequired,
};
