import { Box, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyle = makeStyles({
  root: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.02em",
    color: "#425466",
  },
});

export default function TypographySmall({ className, ...props }) {
  const classes = useStyle();
  return <Box className={clsx(classes.root, className)} {...props} />;
}
