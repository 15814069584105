import { SvgIcon } from "@material-ui/core";

export default function ArrowIcon(props) {
  return (
    <SvgIcon {...props} style={{ color: "transparent", ...props.style }}>
      <path
        d="M 6.0000001,9.0000208 C 8.6521049,11.533561 10.053527,13.043815 12,15.000021 l 6,-6.0000002"
        stroke="#4263eb"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
