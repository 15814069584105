import React from "react";
import Slider from "./Slider";
import PropTypes from "prop-types";

const SliderDiscrete = React.forwardRef(function SliderDiscrete(props, ref) {
  const marks = props.options.map((option, index) => ({
    value: index,
    label: option,
  }));
  const step = 1;
  const min = 0;
  const max = marks.length - 1;

  return (
    <Slider
      marks={marks}
      step={step}
      min={min}
      max={max}
      ref={ref}
      value={props.value}
      onChange={props.onChange}
    />
  );
});

SliderDiscrete.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
};

export default SliderDiscrete;
