import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import axios from "axios";
import { ContextGlobal } from "../context/globalContext";
import { useHistory } from "react-router-dom";

const apiUrl = "/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function Menu({ name, companyName, children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const { logOff } = useContext(ContextGlobal);
  let history = useHistory();

  const lofOffAccount = () => {
    const apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    apiAxios
      .post("/auth/logout")
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        history.push("/");
        logOff();
        history.push("/");
        window.location.href =
          "https://accounts.claveunica.gob.cl/api/v1/accounts/app/logout?redirect_url=" +
          window.location.origin;
        history.push("/");
      });
  };

  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {children}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box paddingTop="39px" paddingBottom="29px">
                  <Box paddingX="16px">
                    <Box fontSize="24px" fontWeight="500" paddingBottom="24px">
                      {name}
                    </Box>
                    <Box fontSize="16px" fontWeight="500" paddingBottom="24px">
                      {companyName}
                    </Box>
                  </Box>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleClose}>Mis solicitudes</MenuItem>
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e);
                        lofOffAccount();
                      }}
                    >
                      Cerrar Sesión
                    </MenuItem>
                  </MenuList>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
