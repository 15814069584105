import { Box, Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import BigCaption from "../components/BigCaption";
import ButtonBold from "../components/ButtonBold";
import Header from "../components/Header";
import StarIcon from "../icons/Star";

const useStyles = makeStyles(
  {
    title: {
      fontStyle: "normal",
      fontSize: "20px",
      lineHeight: "30px",
      color: "#000000",
    },
    subtitle: {
      color: "#000000",
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "157.33%",
    },
    email: {
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "157.33%",
      color: "#000000",
    },
  },
  { name: "DashboardSidePanel" }
);

export default function DashboardSidePanel(props) {
  const classes = useStyles();
  const { name, rut, email, onEdit, onShare, onLogoff } = props;
  return (
    <React.Fragment>
      <Box marginBottom="20px" marginTop="20px">
        <Header>Chequeo Digital</Header>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={5} md={12}>
          <Box maxWidth={314}>
            <img
              style={{ width: "100%" }}
              // TODO: Reemplazar por la nueva imagen
              src="/images/people-with-board.svg"
              alt="Gente viendo una pizarra"
            />
          </Box>
        </Grid>
        <Grid item xs={7} md={12}>
          <Box marginBottom="51px">
            <BigCaption>Usuario</BigCaption>
            <Box className={classes.title}>{name}</Box>
            <Box className={classes.subtitle}>{rut}</Box>
          </Box>
          <Box marginBottom="16px">
            <BigCaption>Correo Electrónico</BigCaption>
            <Box className={classes.email}>{email}</Box>
          </Box>
          <ButtonBold
            variant="outlined"
            onClick={onEdit}
            // TODO: Cambiar el ícono
            startIcon={<StarIcon />}
            style={{ marginRight: "10px",marginBottom:"16px" }}
          >
            Editar
          </ButtonBold>
          <ButtonBold
            variant="outlined"
            onClick={onShare}
            style={{ marginRight: "10px",marginBottom:"16px" }}
          >
            Habilitar Permisos
          </ButtonBold>
          <ButtonBold
            variant="outlined"
            onClick={onLogoff}
            style={{ marginRight: "10px",marginBottom:"16px" }}
          >
            Cerrar Sesión
          </ButtonBold>
          
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

DashboardSidePanel.propTypes = {
  name: PropTypes.string.isRequired,
  rut: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
};
