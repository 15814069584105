import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import BigCaption from "../components/BigCaption";
import ButtonBold from "../components/ButtonBold";
import ProgressBarNoDot from "../components/ProgressBarNoDot";
import StarIcon from "../icons/Star";
import { useHistory, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  result: {},
  resultBold: {
    fontWeight: "bold",
  },
  label: {
    font: "normal normal normal 14px/21px Poppins",
    letterSpacing: "0.28px",
    color: "#787E8E",
  },
  percentage: {
    font: "normal normal normal 18px/27px Poppins",
    letterSpacing: "0px",
    color: "#000000",
  },
}));

// TODO: Aplicar cambios si es que no está completado
export default function DashboardCard(props) {
  const {
    progress,
    name,
    rut,
    size,
    region,
    regionId,
    id,
    init,
    empresa_id,
    onNewSurvey,
    puedeIniciar,
    maturityLevel,
    maturityLevelID,
  } = props;
  const completed = progress >= 100;
  const decimalToHexString = (number) => {
    if (number < 0) {
      number = 0xffffffff + number + 1;
    }
    return number.toString(16).toUpperCase();
  };

  const classes = useStyles();
  let history = useHistory();
  
  return (
    <Paper
      elevation={0}
      style={{
        boxShadow: "0px 40px 40px #E6E8EC",
      }}
    >
      <Box padding="54px">
        <Box marginBottom="40px">
          <Box marginBottom="20px">
            <Typography variant="h2">{name}</Typography>
            <Box paddingTop="4px" paddingBottom="4px">
              <Typography variant="body1" style={{ color: "#000000" }}>
                {rut}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box paddingTop="10px">
          <Box marginBottom="50px">
            <ProgressBarNoDot
              label={completed ? "Completado" : "Avance"}
              value={progress}
              color={completed ? "secondary" : "primary"}
              labelClassName={classes.label}
              percentageClassName={classes.percentage}
            />
          </Box>

          {completed ? <>

          <Box marginBottom="57px">
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item>
                <BigCaption>Resultado</BigCaption>
                <Box className={classes.result}>
                  Tu pyme posee una madurez digital nivel{" "}
                  <Box component="span" className={classes.resultBold}>
                    <span style={{textTransform: 'uppercase'}}>{maturityLevel}</span>
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <ButtonBold
                  variant="outlined"
                  //TODO: Cambiar el ícono
                  startIcon={<StarIcon />}
                  onClick={()=>{history.push("/resultsurvey/"+decimalToHexString(empresa_id));}}
                >
                  Ver Detalle
                </ButtonBold>
              </Grid>
            </Grid>
          </Box>

          {init && <>
            <Box marginBottom="57px">
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item>
                <BigCaption>Nueva Encuesta</BigCaption>
                <Box className={classes.result}>
                  Ya puedes iniciar una nueva encuesta pra medir tu avance.
                </Box>
              </Grid>
              <Grid item>
                <ButtonBold
                  variant="outlined"
                  //TODO: Cambiar el ícono
                  startIcon=''
                  onClick={onNewSurvey}
                > Iniciar Nueva Encuesta
                </ButtonBold>
              </Grid>
            </Grid>
          </Box>
          </>}

          <Box marginBottom="44px">
            <Typography variant="h2" style={{ fontSize: "18px" }}>
              ¿CÓMO SEGUIR?
            </Typography>
            <Typography variant="body">
              Te invitamos a conocer las capacitaciones y la oferta de
              herramientas y servicios digitales especialmente seleccionados
              para ti.
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={()=>{window.location=("/agendachequeo/"+maturityLevelID+"/"+regionId);}}
              >
                CAPACITACIONES GRATUITAS
              </Button>
            </Grid>



            
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                onClick={()=>{window.open("https://pymesenlinea.cl/alianza/", '_blank', 'noopener,noreferrer');}}
                
                
              >
                <Box component="span">
                  <Box component="span" fontWeight="bold">
                    PROXIMO
                  </Box>{" "}
                    PASO
                </Box>
              </Button>
            </Grid>
          </Grid>

          </> : <>
  
          { id !== 0 ?
           <Box marginBottom="10px">
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item>
                <Box className={classes.result}>
                  Aún no haz completado tu Encuesta
                </Box>
              </Grid>
              <Grid item>
                <ButtonBold
                  variant="outlined"
                  //TODO: Cambiar el ícono
                  startIcon=''
                  onClick={()=>{history.push("/mainsurvey/"+decimalToHexString(id));}}
                > Continuar Encuesta
                </ButtonBold>
              </Grid>
            </Grid>
          </Box>
          : <>

          <Box marginBottom="57px">
            <Grid container justifyContent="space-between" spacing={1}>
              <Grid item>
                <BigCaption>Nueva Encuesta</BigCaption>
                <Box className={classes.result}>
                  Ya puedes iniciar una nueva encuesta pra medir tu avance.
                </Box>
              </Grid>
              <Grid item>
                <ButtonBold
                  variant="outlined"
                  //TODO: Cambiar el ícono
                  startIcon=''
                  onClick={onNewSurvey}
                > Iniciar Nueva Encuesta
                </ButtonBold>
              </Grid>
            </Grid>
          </Box>

          </>
          }



          </>}
          {/* {completed ? (
            <Fragment>
              {init && (
                <Box paddingTop="9px" paddingBottom="9px" marginBottom="8px">
                  <Link onClick={onNewSurvey}>Nuevo chequeo</Link>
                </Box>
              )}
              <Box paddingTop="9px" paddingBottom="9px">
                <Link to={"/resultsurvey/" + decimalToHexString(empresa_id)}>
                  Ver resultado{" "}
                </Link>
              </Box>
            </Fragment>
          ) : (
            <Fragment>
              {init && (
                <Box paddingTop="9px" paddingBottom="9px" marginBottom="8px">
                  <Link onClick={onNewSurvey}>Iniciar chequeo</Link>
                </Box>
              )}
              {id > 0 && (
                <Box paddingTop="9px" paddingBottom="9px">
                  <Link
                    to={{
                      pathname: "/mainsurvey/" + decimalToHexString(id),
                      state: { sid: decimalToHexString(id) },
                    }}
                  >
                    Continuar chequeo{" "}
                  </Link>
                </Box>
              )}
            </Fragment>
          )} */}
        </Box>
      </Box>
    </Paper>
  );
}

DashboardCard.propTypes = {
  progress: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  rut: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  init: PropTypes.bool.isRequired,
  maturityLevel: PropTypes.string.isRequired,
};
