import React, { useState, useContext } from "react";
import { ContextGlobal } from "./../context/globalContext";
import axios from "axios";
import { useFormik } from "formik";
import ReactJson from "react-json-view";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

import registro from "./../img/logo_reg.svg";
import "./../scss/style.scss";
import TextField from "../lib/components/TextField";
import { Box, Button, Divider, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';


import DateTextField from "../lib/components/DateTextField";
import SelectTextField from "../lib/components/SelectTextField";
import FormHelperText from "../lib/components/FormHelperText";
import CheckboxField from "../lib/components/CheckboxField";
import { validate as validateRut, format } from "rut.js";

const valSchema = Yup.object().shape({
  email: Yup.string()
    .required("Este campo es requerido (*)")
    .email("Debe ingresar un correo electrónico válido"),
  email_val: Yup.string()
    .required("Este campo es requerido (*)")
    .oneOf([Yup.ref('email'), null], 'Tu correo electrónico no coincide'),
  nombre: Yup.string().required("Este campo es requerido (*)"),
  apellido: Yup.string().required("Este campo es requerido (*)"),
  rut: Yup.string()
    .min(5, 'RUT demasiado pequeño')
    .max(14, 'RUT demasiado largo')
    .required("Debes ingresar tu RUT/RUN")
    .test("rut-valido", "Ingrese un RUT/RUN válido", (value) => validateRut(value)),
  genero: Yup.string().ensure().required("Debe seleccionar género"),
  fecha_nacimiento: Yup.date().required("Debe ingresar su fecha de nacimiento"),
  password: Yup.string()
    .required("Debe ingresar una Contraseña")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[\_^\w\s])(?=.{8,})/,
      "Su Contraseña debe ser de 8 o más caracteres y combinar mayúsculas/minúsculas, números y un caracter especial"
    ),
  password_confirm: Yup.string()
    .required("Debe reingresar la contraseña para confirmar")
    .oneOf(
      [Yup.ref("password"), null],
      "Las contraseñas no coinciden. Inténtelo nuevamente."),
  /* terminos_condiciones: Yup.boolean().oneOf([true],
    "Debe aceptar los Terminos y Condiciones"), */
});

function Register(props) {
  let history = useHistory();
  const [msg, cambiarMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const apiUrl = "/api";

  const form_reg = useFormik({
    initialValues: {
      email: "",
      nombre: "",
      apellido: "",
      rut: "",
      fecha_nacimiento: "",
      genero: "",
      password: "",
      password_confirm: "",
      terminos_condiciones: false,
    },
    onSubmit: async (e) => {
      cambiarMsg("");
      const apiAxios = axios.create({
        baseURL: apiUrl,
      });
      const result = apiAxios
        .post("/auth/register", form_reg.values)
        .then((response) => {
          sessionStorage.setItem("email", form_reg.values.email);
          history.push("/registermsg");
          //console.log(response.data);
        })
        .catch((error) => {
          if (error.response?.data && error.response.data.status !== "0") {
            cambiarMsg(error.response.data.message);
          } else {
            cambiarMsg("Algo salio mal, por favor intente mas tarde.");
          }
          //console.log(error.response?.data);
        });
    },
    validationSchema: valSchema,
  });

  const { touched, errors } = form_reg;
  const fieldErrors = {
    email: touched.email && errors.email,
    email_val: touched.email_val && errors.email_val,
    nombre: touched.nombre && errors.nombre,
    apellido: touched.apellido && errors.apellido,
    rut: touched.rut && errors.rut,
    fecha_nacimiento: touched.fecha_nacimiento && errors.fecha_nacimiento,
    genero: touched.genero && errors.genero,
    password: touched.password && errors.password,
    password_confirm: touched.password_confirm && errors.password_confirm,
//    terminos_condiciones:
//      touched.terminos_condiciones && errors.terminos_condiciones,
  };
  
  return (
    <div className="App" style={{ width: "100%" }}>
      <Box className="si-content-r" width={520}>
        <div className="si-marco">
          <img src={registro} />
          <div style={{ fontSize: "26px" }}>Registro Manual</div>
          <div style={{ fontSize: "12px" }}>
          Completa los datos para crear tu cuenta en Chequeo Digital
          </div>

          <hr className="si-separador"></hr>

          <form action="" onSubmit={form_reg.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="CORREO ELECTRÓNICO"
                  type="text"
                  name="email"
                  id="email"
                  value={form_reg.values.email}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  error={Boolean(fieldErrors.email)}
                  success={Boolean(!fieldErrors.email && form_reg.values.email)}
                  helperText={
                    fieldErrors.email ||
                    "Utiliza un correo válido para tu próximo ingreso"
                  }
                  autoComplete="username"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  label="REPETIR CORREO ELECTRÓNICO"
                  type="text"
                  name="email_val"
                  id="email_val"
                  value={form_reg.values.email_val}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  error={Boolean(fieldErrors.email_val)}
                  success={Boolean(!fieldErrors.email_val && form_reg.values.email_val)}
                  helperText={
                    fieldErrors.email_val ||
                    "Reingresa tu correo electrónico"
                  }
                  autoComplete="username"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="NOMBRE"
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={form_reg.values.nombre}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  error={Boolean(fieldErrors.nombre)}
                  helperText={fieldErrors.nombre}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="APELLIDO"
                  type="text"
                  name="apellido"
                  id="apellido"
                  value={form_reg.values.apellido}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  error={Boolean(fieldErrors.apellido)}
                  helperText={fieldErrors.apellido}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="RUT"
                  type="text"
                  name="rut"
                  id="rut"
                  value={form_reg.values.rut}
                  onChange={form_reg.handleChange}
                  // onBlur={form_reg.handleBlur}
                  onBlur={(e) => {form_reg.setFieldValue('rut', format(form_reg.values.rut)); form_reg.handleBlur(e); }}
                  error={Boolean(fieldErrors.rut)}
                  helperText={fieldErrors.rut}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateTextField
                  label="FECHA DE NACIMIENTO"
                  type="text"
                  name="fecha_nacimiento"
                  id="fecha_nacimiento"
                  value={form_reg.values.fecha_nacimiento}
                  onChange={(value) => {
                    form_reg.setFieldValue("fecha_nacimiento", value);
                  }}
                  onBlur={form_reg.handleBlur}
                  error={Boolean(fieldErrors.fecha_nacimiento)}
                  helperText={fieldErrors.fecha_nacimiento}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectTextField
                  label="GÉNERO"
                  name="genero"
                  id="genero"
                  value={form_reg.values.genero}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  options={[
                    { value: "Femenino", label: "Femenino" },
                    { value: "Masculino", label: "Masculino" },
                    { value: "Otros", label: "Otros" },
                    { value: "Prefiero no responder", label: "Prefiero no responder" },
                  ]}
                  error={Boolean(fieldErrors.genero)}
                  helperText={fieldErrors.genero}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="CONTRASEÑA"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  value={form_reg.values.password}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  fullWidth
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="CONFIRME CONTRASEÑA"
                  type={showPassword ? "text" : "password"}
                  name="password_confirm"
                  id="password_confirm"
                  value={form_reg.values.password_confirm}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  fullWidth
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <Box style={{ marginTop: "-8px" }} marginBottom={1}>
                  {fieldErrors.password || fieldErrors.password_confirm ? (
                    <FormHelperText error>
                      {fieldErrors.password || fieldErrors.password_confirm}
                    </FormHelperText>
                  ) : (
                    <FormHelperText
                      style={{
                        opacity: 0.5,
                        paddingInlineStart: "24px",
                        margin: 0,
                      }}
                      component="ul"
                    >
                      <li>
                        8 caracteres mínimos combinando números, mayúsculas y
                        símbolos
                      </li>
                    </FormHelperText>
                  )}
                </Box>
                <CheckboxField
                  label="Mostrar contraseña"
                  type="checkbox"
                  value={showPassword}
                  onChange={(evt) => setShowPassword(evt.target.checked)}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* <Grid item xs={12}>
                <Box paddingTop={2}>
                  <CheckboxField
                    label="Acepto Términos y Condiciones"
                    type="checkbox"
                    name="terminos_condiciones"
                    id="terminos_condiciones"
                    value={form_reg.values.terminos_condiciones}
                    onChange={form_reg.handleChange}
                    onBlur={form_reg.handleBlur}
                  />
                </Box>
                {fieldErrors.terminos_condiciones ? (
                  <FormHelperText error>
                    {fieldErrors.terminos_condiciones}
                  </FormHelperText>
                ) : null}
              </Grid> */}
              {Boolean(msg) && (
                <Grid item xs={12}>
                  <span style={{ color: "red", fontSize: "14px" }}>{msg}</span>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  name="ingresar"
                  disabled={!(form_reg.isValid && form_reg.dirty)}
                  fullWidth
                >
                  Comenzar
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
{/*         <div style={{ marginTop: "50px" }}>
          <ReactJson src={form_reg.values} collapsed="true" />
          <ReactJson src={form_reg.errors} collapsed="true" />
          <ReactJson src={form_reg.touched} collapsed="true" />
        </div> */}
      </Box>
    </div>
  );
}

export default Register;
