import { Box, Container, Grid } from "@material-ui/core";
import SurveyPanelFinished from "./SurveyPanelFinished";
import SurveyPanelInProgress from "./SurveyPanelInProgress";
import SurveyPanelToDevelop from "./SurveyPanelToDevelop";
import SurveysSidePanel from "./SurveysSidePanel";

export default function QuestionLayout() {
  const surveys = [
    {
      image: (
        <Box padding="8px">
          <img
            height="64px"
            src="/encuestas/cultura-y-liderazgo.svg"
            alt="Comunicaciones"
          />
        </Box>
      ),
      title: "Comunicaciones",
      description:
        "Refiere a las formas en que tú y tus trabajadores se adaptan a la tecnología.",
      numberOfQuestions: "16",
      estimatedTime: "20 min",
      onContinue: () => console.log("onContinue"),
    },
    {
      image: (
        <Box padding="8px">
          <img
            height="64px"
            src="/encuestas/comunicaciones.svg"
            alt="Comunicaciones"
          />
        </Box>
      ),
      title: "Comunicaciones",
      description:
        "Refiere a las formas en que tú y tus trabajadores se adaptan a la tecnología.",
      numberOfQuestions: "16",
      estimatedTime: "20 min",
      completition: { value: 30, hint: "Quedan 9 preguntas" },
      onContinue: () => console.log("onContinue"),
    },
  ];
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md="auto">
          <Box padding="24px">
            <Box width={300}>
              <SurveysSidePanel
                caption="Chequeo digital"
                companyName="Empresa distribuidora Nombre  y Asociados SPA"
                subHeader="Por: Editha Fuentes - Diseñadora UX"
                completitionValue={30}
                details={[
                  { title: "RUT", description: "79.461.346-k" },
                  { title: "Tamaño Empresa", description: "Mediana" },
                  { title: "Empleados", description: "Solo Yo" },
                  {
                    title: "Rubro",
                    description: "Agricultura, ganaderia, silvicultura y pesca",
                  },
                  {
                    title: "Sub Rubro",
                    description:
                      "Actividades de apoyo a la agricultura y la ganaderia y actividades poscosecha",
                  },
                  {
                    title: "Actividad",
                    description: "Agricultura, ganaderia, silvicultura y pesca",
                  },
                ]}
                onClick={() => {
                  console.log("exit");
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md>
          <Box marginBottom="16px">
            <SurveyPanelInProgress surveys={surveys} />
          </Box>
          <Box marginBottom="16px">
            <SurveyPanelToDevelop surveys={surveys} />
          </Box>
          <Box marginBottom="16px">
            <SurveyPanelFinished surveys={surveys} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
