import { Box, Grid, makeStyles, Paper, Hidden } from "@material-ui/core";
import PropTypes from "prop-types";
import Slider from "../components/SliderDiscrete";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  selectedLabel: {
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "32px",
    lineHeight: "30px",
    height: "30px",
    color: theme.palette.primary.main,
  },
  description: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#425466",
  },
  hint: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: "#000000",
    opacity: "0.5",
  },
}));

export default function QuestionSlider(props) {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:360px)');
  return (
    <Paper elevation={1}>
      <Box padding="24px 30px">
      {props.descriptions ? (
          <Grid container justify="space-between" alignItems="center">
            {props.descriptions.map((description, index) => (
              (index==0 || index==(props.descriptions.length-1))?(
                <Grid item xs="auto" key={index}>
                  <Box width={matches?"100px":"70px"} className={classes.description}>
                    {description}
                  </Box>
                </Grid>
              ):(
              <Hidden xsDown>
                <Grid item xs="auto" key={index}>
                  <Box width={matches?"100px":"70px"} className={classes.description}>
                    {description}
                  </Box>
                </Grid>
              </Hidden>
              )
            ))}
          </Grid>
        ) : null}
        <Box marginBottom="20px" marginLeft={matches?"50px":"0px"} marginRight={matches?"50px":"0px"}>
          <Slider
            value={props.value}
            onChange={(_evt, v) => {
              props.onChange(v);
            }}
            options={props.options}
          />
        </Box>
        <Box className={classes.selectedLabel} marginBottom="16px">
          {props.options[props.value]}
        </Box>
        {props.hint ? <Box className={classes.hint}>{props.hint}</Box> : null}
      </Box>
    </Paper>
  );
}

QuestionSlider.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.string),
  hint: PropTypes.string,
};
