import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Chart from "./ResultPanelMaturityInTimeChart";
import Divider from "../components/Divider";
import ResultPanelMaturityInTimeDimension from "./ResultPanelMaturityInTimeDimension";

const useStyles = makeStyles(
  {
    title: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "36px",
      color: "#0A2540",
    },
  },
  { name: "ResultPanelMaturityInTime" }
);

export default function ResultPanelMaturityInTime(props) {
  const { dimensions, data } = props;
  const classes = useStyles();
  return (
    <Paper>
      <Box padding="26px">
        <Box paddingTop="6px" paddingBottom="6px" marginBottom="20px">
          <Box className={classes.title}>
            Avance madurez digital en el tiempo
          </Box>
        </Box>
        <Box marginBottom="20px">
          <Divider />
        </Box>
        <Box marginBottom="20px">
          <Paper style={{ backgroundColor: "#F9FAFC" }}>
            <Box padding="24px">
              <Box marginBottom="32px">
                <Typography variant="h2">Visualización general</Typography>
              </Box>
              <Chart data={data} />
            </Box>
          </Paper>
        </Box>
        <Box marginBottom="20px">
          <Divider />
        </Box>
        <Box marginBottom="20px">
          <Paper style={{ backgroundColor: "#F9FAFC" }}>
            <Box padding="24px">
              <Box marginBottom="32px">
                <Typography variant="h2">
                  Visualización por dimensión
                </Typography>
              </Box>
              <Grid container spacing={3}>
                {dimensions.map((dimension, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <ResultPanelMaturityInTimeDimension {...dimension} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Paper>
  );
}

ResultPanelMaturityInTime.propTypes = {
  dimensions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string.isRequired,
          graphValue: PropTypes.number.isRequired,
          value: PropTypes.number.isRequired,
          tier: PropTypes.string.isRequired,
          direction: PropTypes.oneOf(["inc", "dec"]),
        })
      ).isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
};
