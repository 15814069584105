import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import TypographySmall from "../components/TypographySmall";
import ResultPanelComparisonBars from "./ResultPanelComparisonBars";
import ResultPanelComparisonDimension from "./ResultPanelComparisonDimension";
import ResultPanelComparisonForm from "./ResultPanelComparisonForm";

const useStyles = makeStyles({
  dot: {
    height: "8px",
    width: "8px",
    borderRadius: "100px",
    display: "inline-block",
    background: "#C2C3D3",
  },
  dotPrimary: {
    background: "#2B44FF",
  },
  dotLabel: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#191847",
    opacity: "0.5",
  },
});

export default function ResultPanelComparison(props) {
  const { rubros, tamanos, regiones, dimensions, barsData, onUpdateBars } =
    props;
  const classes = useStyles();
  return (
    <Paper>
      <Box padding="26px">
        <Box paddingBottom="12px" marginBottom="20px">
          <Grid container spacing={1}>
            <Grid item xs>
              <Typography variant="h1">¿Qué tan digital es mi pyme respecto al resto?</Typography>
            </Grid>
            <Grid item xs="auto">
              <Box>
                <Box
                  component="span"
                  className={clsx(classes.dot, classes.dotPrimary)}
                />
                <Box
                  component="span"
                  marginLeft="8px"
                  className={classes.dotLabel}
                >
                  Mi pyme
                </Box>
                <Box
                  component="span"
                  marginLeft="8px"
                  className={classes.dot}
                />
                <Box
                  component="span"
                  marginLeft="8px"
                  className={classes.dotLabel}
                >
                  Otras pymes
                </Box>
              </Box>
            </Grid>
          </Grid>
          <TypographySmall>
            Compara tus resultados con el promedio obtenido por el total de pymes que se han chequeado.
          </TypographySmall>
        </Box>
        <Paper style={{ backgroundColor: "#F9FAFC" }}>
          <Box padding="16px" marginBottom="16px">
            <Grid container spacing={2}>
              <Grid item xs={12} sm="auto">
                <ResultPanelComparisonForm
                  rubros={rubros}
                  tamanos={tamanos}
                  regiones={regiones}
                  onSubmit={onUpdateBars}
                />
              </Grid>
              <Grid item xs={12} sm>
                <Box padding="16px">
                  <Paper>
                    <Box padding="26px">
                      <ResultPanelComparisonBars {...barsData} />
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box padding="16px">
            <h5>Comparación por dimensiones</h5>
            <Grid container spacing={3}>
              {dimensions.sort((a,b)=>{return a.tier.value-b.tier.value;}).map((dimension, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <ResultPanelComparisonDimension {...dimension} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Paper>
  );
}

ResultPanelComparison.propTypes = {
  rubros: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  tamanos: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  regiones: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  barsData: PropTypes.shape({
    my: PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
    others: PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onUpdateBars: PropTypes.func.isRequired,
};
