import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import {
  createMuiTheme,
  CssBaseline,
  ThemeProvider as ThemeProviderCore,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CheckboxIcon from "./icons/Checkbox";
import CheckboxCheckedIcon from "./icons/CheckboxChecked";
import shadows from "@material-ui/core/styles/shadows";

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "d MMM yyyy", { locale: this.locale });
  }
}

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Poppins"',
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      "sans-serif",
    ].join(","),
    button: {
      fontWeight: 400,
    },
    h1: {
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "36px",
    },
    h2: {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "30px",
    },
    body1: {
      fontSize: 16,
    },
  },
  palette: {
    background: {
      default: "#F9FAFC",
      paper: "#FFFFFF",
    },
    primary: {
      // Purple and green play nicely together.
      main: "#2B44FF",
      dark: "#0017C2",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#FF357F",
    },
    error: {
      main: "#F03D3E",
    },
    action: {
      hoverOpacity: 0.54,
      disabled: "#FFFFFF",
    },
    warning: {
      main: "#FFB800",
    },
    text: {
      primary: "#495057",
    },
  },
  shadows: ["none", "0px 1px 2px rgba(0, 0, 0, 0.12)"].concat(shadows.slice(2)),
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiCheckbox: {
      color: "primary",
      icon: <CheckboxIcon />,
      checkedIcon: <CheckboxCheckedIcon />,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: "#191847",
        textTransform: "none",
      },
      outlined: {
        padding: "11px 15px",
      },
      contained: {
        padding: "12px 16px",
        "&$disabled": {
          opacity: 0.5,
          backgroundColor: null,
        },
      },
      outlinedSizeSmall: {
        padding: "4px 9px",
      },
      containedSizeSmall: {
        padding: "5px 10px",
      },
      containedSizeLarge: {
        padding: "20px 12px",
        fontSize: "1.25rem",
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        "&$checked": {
          "&:hover": {
            backgroundColor: "unset",
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "#F1F3F4",
        },
      },
    },
  },
});

export default function ThemeProvider(props) {
  return (
    <ThemeProviderCore theme={theme}>
      <MuiPickersUtilsProvider utils={LocalizedUtils} locale={esLocale}>
        <CssBaseline />
        {props.children}
      </MuiPickersUtilsProvider>
    </ThemeProviderCore>
  );
}
