import {Box, Button, Grid, makeStyles, Paper, Typography, } from "@material-ui/core";
import React, { useState, useContext } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import ReactJson from "react-json-view";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import axios from "axios";
import { Fragment } from "react";
import { validate as validateRut } from "rut.js";
import * as Yup from "yup";
import DateTextField from "../components/DateTextField";
import Divider from "../components/Divider";
import FormHelperText from "../components/FormHelperText";
import SelectTextField from "../components/SelectTextField";
import TextField from "../components/TextField";

const useStyles = makeStyles(
  {
    inputLabel: {
      opacity: 0.5,
      fontSize: "11px",
      lineHeight: "16px",
    },
    emailHint: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "157.33%",
      color: "#000000",
    },
  },
  { name: "DashboardPanelForm" }
);

const validationSchema = Yup.object().shape({
  nombre: Yup.string().required("Este campo es Requerido (*)"),
  apellido: Yup.string().required("Este campo es Requerido (*)"),
  rut: Yup.string()
    .required("Debes ingresar tu RUT/RUN")
    .test("rut-valido", "Ingrese un RUT/RUN válido", (value) =>
      validateRut(value)
    ),
  genero: Yup.string().ensure().required("Debe seleccionar Genero"),
  fecha_nacimiento: Yup.date().required("Debe ingresar su Fecha de Nacimiento"),
  password: Yup.string(),
  password_new: Yup.string().when("password", {
    is: (p) => Boolean(p),
    then: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Su Contraseña debe ser de 8 o mas caracteres y combinar mayusculas/minusculas, numeros y un caracter Especial"
      )
      .required("Debe ingresar una Contraseña"),
  }),
});

export default function DashboardPanelForm(props) {
  const classes = useStyles();
  const apiUrl = "/api";
  const [msg, cambiarMsg] = useState("");
  let history = useHistory();
  const {
    onBack,
    onSubmit,
    logcu,
    values: initialValues = {
      email: "",
      nombre: "",
      apellido: "",
      rut: "",
      fecha_nacimiento: "",
      genero: "",
      password: "",
      password_new: "",
     },
  } = props;
  
  const form_reg = useFormik({
    initialValues,
    onSubmit: async (e) => {
      const apiAxios = axios.create({
        baseURL: apiUrl,
        headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
      });
      const result = apiAxios
        .post("/auth/updateme", form_reg.values)
        .then((response) => {
          //console.log(response.data);
          onBack();
        })
        .catch((error) => {
          if (error.response?.data && error.response.data.status !== "0") {
            cambiarMsg(error.response.data.message);
          }
          //console.log(error.response?.data);
        });
    },
    validationSchema,
  });

  const { touched, errors } = form_reg;
  const fieldErrors = {
    email: touched.email && errors.email,
    nombre: touched.nombre && errors.nombre,
    apellido: touched.apellido && errors.apellido,
    rut: touched.rut && errors.rut,
    fecha_nacimiento: touched.fecha_nacimiento && errors.fecha_nacimiento,
    genero: touched.genero && errors.genero,
    password: touched.password && errors.password,
    password_new: touched.password_new && errors.password_new,
  };

  return (
    <Fragment>
      <Box marginBottom="32px" marginTop="20px">
        <Typography variant="h1">
          <Box display="inline" marginRight="16px">
            <Button variant="outlined" size="small" onClick={onBack}>
              Volver
            </Button>
          </Box>
          Mis Datos
        </Typography>
      </Box>
      <Paper>
        <Box padding="24px">
          <form action="" onSubmit={form_reg.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ className: classes.inputLabel }}
                  label="CORREO ELECTRÓNICO"
                  type="text"
                  name="email"
                  id="email"
                  value={form_reg.values.email}
                  fullWidth
                  disabled
                  autoComplete="username"
                />
                <Box marginTop="8px" className={classes.emailHint}>
                  El correo electrónico no puede editarse, si tiene problema
                  contáctese con fast@economia.cl
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h2">Mis datos</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  InputLabelProps={{ className: classes.inputLabel }}
                  label="RUT"
                  type="text"
                  name="rut"
                  id="rut"
                  value={form_reg.values.rut}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  error={Boolean(fieldErrors.rut)}
                  helperText={fieldErrors.rut}
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ className: classes.inputLabel }}
                  label="NOMBRE"
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={form_reg.values.nombre}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  error={Boolean(fieldErrors.nombre)}
                  helperText={fieldErrors.nombre}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ className: classes.inputLabel }}
                  label="APELLIDO"
                  type="text"
                  name="apellido"
                  id="apellido"
                  value={form_reg.values.apellido}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  error={Boolean(fieldErrors.apellido)}
                  helperText={fieldErrors.apellido}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateTextField
                  InputLabelProps={{ className: classes.inputLabel }}
                  label="FECHA NACIMIENTO"
                  type="text"
                  name="fecha_nacimiento"
                  id="fecha_nacimiento"
                  value={form_reg.values.fecha_nacimiento}
                  onChange={(value) => {
                    form_reg.setFieldValue("fecha_nacimiento", value);
                  }}
                  onBlur={form_reg.handleBlur}
                  error={Boolean(fieldErrors.fecha_nacimiento)}
                  helperText={fieldErrors.fecha_nacimiento}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectTextField
                  InputLabelProps={{ className: classes.inputLabel }}
                  label="GÉNERO"
                  name="genero"
                  id="genero"
                  value={form_reg.values.genero}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  options={[
                    { value: "Femenino", label: "Femenino" },
                    { value: "Masculino", label: "Masculino" },
                    { value: "Otros", label: "Otros" },
                    { value: "Prefiero no responder", label: "Prefiero no responder" },
                  ]}
                  error={Boolean(fieldErrors.genero)}
                  helperText={fieldErrors.genero}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              { !logcu && 
              <>
              <Grid item xs={12}>
                <Typography variant="h2">Contraseña</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ className: classes.inputLabel }}
                  label="CONTRASEÑA ACTUAL"
                  type="password"
                  name="password"
                  id="password"
                  value={form_reg.values.password}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  fullWidth
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  InputLabelProps={{ className: classes.inputLabel }}
                  label="Nueva Contraseña"
                  type="password"
                  name="password_new"
                  id="password_new"
                  value={form_reg.values.password_new}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  fullWidth
                  autoComplete="new-password"
                />
              </Grid>
              
              <Grid item xs={12}>
                <Box style={{ marginTop: "-8px" }} marginBottom={1}>
                  {fieldErrors.password || fieldErrors.password_new ? (
                    <FormHelperText error>
                      {fieldErrors.password || fieldErrors.password_new}
                    </FormHelperText>
                  ) : (
                    <FormHelperText
                      style={{
                        opacity: 0.5,
                        paddingInlineStart: "24px",
                        margin: 0,
                      }}
                      component="ul"
                    >
                      <li>
                        8 caracteres mínimos combinando números, mayúsculas y
                        símbolos
                      </li>
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}><Divider /></Grid>
              </>
              }
              
              <Grid item xs={12}>
                <Box margin="auto" width="212px">
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    name="ingresar"
                    disabled={!(form_reg.isValid && form_reg.dirty)}
                    fullWidth
                  >Guardar
                  </Button>
                </Box>
                <div style={{color: "red", with: "100%", textAlign:"center", paddingTop: "20px" }}>{msg}</div>
              </Grid>
            </Grid>
          </form>
{/*           <div style={{ marginTop: "50px"}}>
          <ReactJson src={form_reg.values} collapsed="true" />
          <ReactJson src={form_reg.errors} collapsed="true" />
          <ReactJson src={form_reg.touched} collapsed="true" />
        </div> */}
        </Box>
      </Paper>
    </Fragment>
  );
}

DashboardPanelForm.propTypes = {
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.any,
  logcu: PropTypes.bool,
};
