import { SvgIcon } from "@material-ui/core";

export default function PlaceIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 16" fill="none">
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.75244C0 2.57216 2.60366 0 5.82289 0C9.04211 0 11.6458 2.57216 11.6458 5.75244C11.6458 9.17925 7.96904 13.9045 6.4634 15.7042C6.13067 16.0986 5.52342 16.0986 5.19069 15.7042C3.67674 13.9045 0 9.17925 0 5.75244ZM3.74219 5.7522C3.74219 6.88625 4.67385 7.80664 5.82179 7.80664C6.96973 7.80664 7.90139 6.88625 7.90139 5.7522C7.90139 4.61814 6.96973 3.69775 5.82179 3.69775C4.67385 3.69775 3.74219 4.61814 3.74219 5.7522Z"
        fill="#191847"
      />
    </SvgIcon>
  );
}
