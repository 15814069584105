import React, {useState, useContext} from 'react';
import {ContextGlobal} from './../context/globalContext';
import axios from 'axios';
import { Formik, useFormik } from 'formik';
import ReactJson from 'react-json-view';
import * as Yup from 'yup'; 
import {
  BrowserRouter as Router,
  useHistory,
  Link
} from "react-router-dom";

import './../scss/style.scss';

function RecoverPanel() {

const [msg, cambiarMsg] = useState('');
let history = useHistory();

const valSchema = Yup.object().shape({
  log_eml:    Yup.string()
              .email('Debe ingresae un correo electrónico válido')
})

const apiUrl = '/api';

const form_reg = useFormik({
  initialValues: {
    log_eml: ''  
  },
  onSubmit: async (e) => {
    cambiarMsg('');
    sessionStorage.setItem('u_eml', form_reg.values.log_eml);
    const apiAxios = axios.create({
      baseURL: apiUrl,
        })
    const result = apiAxios.post('/password/email', { 
            email: `${form_reg.values.log_eml}`
        })
          .then((response) => {
            history.push('/recovermsg');
          }).catch((error) => {
            if(error.response.data.status=='06') { 
              cambiarMsg('Correo Electrónico no existe.');
            } else if(error.response.data.status=='02') {
              cambiarMsg('El correo esta registrado, pero aun no ha sido Validado. Por favor revise su bandeja de entrada y siga las instrucciones');
            } else {
              cambiarMsg('Algo salio mal, por favor intente mas tarde');
            }
          })
    ;
  },
  validationSchema: valSchema
});

  return (
    <div className="App" style={{width: '100%'}}>

      <div className="si-content" style={{marginTop: '10%'}}>
          <h4>
             Recuperar tu contraseña
          </h4>
          <div className="si-marco">
            <form action="" onSubmit={form_reg.handleSubmit}>
            <div>
              <label className="lbl-reg" htmlFor="log_eml">CORREO ELECTRÓNICO</label>
              <input 
                type='text'
                name="log_eml" 
                id="log_eml" 
                className="inp-log"
                value={form_reg.values.log_eml}
                onChange={form_reg.handleChange}
                onBlur={form_reg.handleBlur}
              />
              {form_reg.touched.log_eml && form_reg.errors.log_eml ? 
                      <div className='f-error'>{form_reg.errors.log_eml}</div> : null}
              </div>
              <div style={{marginTop: '15px'}}>
              <button 
                type="submit" 
                name='ingresar' 
                className='btn-registro'
                disabled={!(form_reg.isValid && form_reg.dirty)}
                >Solicitar</button>
              </div>
            </form>
              <div style={{fontSize:'14px', fontWeight: '400', textAlign: 'center'}}>
              <span style={{color: 'red', fontSize: '14px'}}>{msg}</span>
              <br/>  
              No recuerdo mi <Link to="/recoverydni">correo electrónico</Link>. <br/>
              </div>
          </div>
{/*           <div style={{marginTop: '50px'}}> 
          <ReactJson src={form_reg.values} collapsed='true'/>
          <ReactJson src={form_reg.errors} collapsed='true'/>
          <ReactJson src={form_reg.touched} collapsed='true'/>
          </div> */}
      </div>
    </div>
  );
}

export default RecoverPanel;
