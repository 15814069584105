import { Box, Grid, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Divider from "../components/Divider";

export default function ResultPanelNextSteps(props) {
  const { children, title } = props;
  return (
    <Paper>
      <Box padding="26px">
        <Grid container spacing={4}>
          <Grid item xs={12} sm="auto">
            <Box padding="10px">
              <img src="/images/woman.svg" alt="Mujer" />
            </Box>
          </Grid>
          <Grid item xs={12} sm>
            <Box marginBottom="10px">
              <Typography variant="h1">{title}</Typography>
            </Box>
            <Box marginBottom="10px">
              <Divider />
            </Box>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

ResultPanelNextSteps.propTypes = {
  children: PropTypes.node,
};
