import { Box, Button, Container, Grid } from "@material-ui/core";
import React, {useState, useContext, useEffect, Fragment} from 'react';
import {ContextGlobal} from '../context/globalContext';
import axios from "axios";
import {
  BrowserRouter as Router,
  useHistory,
  Link,
  useParams
} from "react-router-dom";
import './../scss/style.scss';
import OfferForm from "../lib/offer/OfferForm";
import OfferResults from "../lib/offer/OfferResults";

export default function OfferLayout() {
  const [result, setResult] = useState();
  let { cid, did } = useParams();
  let history = useHistory();
  const ini = {dimension: did}

  const apiUrl = "/api";
  const getOffer = async (val) => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
    });
    const result = await apiAxios
      .post("/oferta/"+parseInt(cid, 16), val)
      .then((response) => {
        setResult(response.data.data);
        //console.log(response.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  }  

  useEffect( () => { getOffer(ini); }, [])
  //console.log("R:", result);


  const decimalToHexString = (number) =>
    {
      if (number < 0) { number = 0xFFFFFFFF + number + 1; }
      return number.toString(16).toUpperCase();
    }  

  return (
    <Container>
      <Box marginBottom="32px">
        <Button variant="outlined" size="small" onClick={() => {history.push('/resultsurvey/'+decimalToHexString(cid));}}>
          Volver
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md="auto">
          <Box
            paddingLeft="16px"
            paddingRight="16px"
            paddingBottom="16px"
            width="333px"
          >
            <OfferForm 
              ini = {+did}
              subtitle=""
              dimensions={result?.dimensions? result.dimensions.map((act)=>({value:act.id,label:act.nombre})) : []}
              offerTypes={result?.offerTypes? result.offerTypes.map((act)=>({value:act.id,label:act.nombre})) : []}
              organizers={result?.organizers? result.organizers.map((act)=>({value:act.id,label:act.nombre})) : []}
              onSubmit= {(values) => getOffer(values)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md>
          <OfferResults  results={result?.offer? result.offer : [] } />
        </Grid>
      </Grid>
    </Container>
  );
}
