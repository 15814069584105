import { Button, withStyles } from "@material-ui/core";

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  label: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    textTransform: "uppercase",
  },
});

export default withStyles(styles, { name: "ButtonBold" })(Button);
