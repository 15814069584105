import React, { useContext } from "react";

import { AppBar, Box, Typography } from "@material-ui/core";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Menu from "./Menu";
import ExpandMore from "../lib/icons/ExpandMore";
import { ContextGlobal } from "./../context/globalContext";
import "./../scss/style.scss";
import { HeaderTabs } from "./HeaderTabs";

function Header() {
  const { vg, user } = useContext(ContextGlobal);

  const email = "digitalizatupyme@economia.cl";

  return (
    <header className="App-header">
      <AppBar
        elevation={0}
        position="static"
        style={{
          paddingLeft: "24px",
          paddingRight: "40px",
          background:
            "transparent linear-gradient(90deg, #3D69AF 0%, #8C5174 61%, #CA3E47 100%) 0% 0% no-repeat padding-box",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <HeaderTabs />
          <Box color="white" display="flex" alignItems="center">
            <Typography color="inherit">{email}</Typography>
          </Box>
        </Box>
      </AppBar>
    </header>
  );
}

export default Header;
