import { Switch, withStyles } from "@material-ui/core";

export default withStyles((theme) => ({
  root: {
    width: 38,
    height: 20,
    padding: 0,
  },
  switchBase: {
    padding: 0,
    top: "2px",
    left: "2px",
    color: "#DDE2E5",
    "&$checked": {
      transform: "translateX(18px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
    borderRadius: "16px",
  },
  track: {
    borderRadius: "16px",
    border: "1px solid #DDE2E5",
    backgroundColor: "#FFFFFF",
    opacity: 1,
    transition: "none",
  },
  checked: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
