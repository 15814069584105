import {
  Box,
  createStyles,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import InputLabel from "./InputLabel";
import Caption from "./Caption";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        height: ({ size }) => (size === "normal" ? 8 : 16),
        borderRadius: ({ size }) => (size === "normal" ? 4 : 8),
      },
      bar: {
        borderRadius: ({ size }) => (size === "normal" ? 4 : 8),
      },
      colorPrimary: {
        backgroundColor: "#F5F6FF",
      },
      colorSecondary: {
        backgroundColor: "#F5F6FF",
      },
      barColorPrimary: ({ color }) => ({
        backgroundColor: color === "default" ? "#C2C3D3" : "#2B44FF",
      }),
      barColorSecondary: {
        backgroundColor: "#19BF3E",
      },
    }),
  { name: "BProgressBar" }
);

const useCaptionStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: "flex",
        marginBottom: ({ size }) => (size === "normal" ? 6 : 8),
      },
    }),
  { name: "BProgressBarCaption" }
);

export default function ProgressBarNoDot({
  color = "primary",
  value = 0,
  size = "normal",
  label,
  hint,
  formatter = (v) => `${v.toFixed(0)}%`,
  labelClassName = "",
  percentageClassName = "",
}) {
  const classes = useStyles({ size, color });
  const captionClasses = useCaptionStyles({ size });
  return (
    <Fragment>
      {Boolean(label) && (
        <InputLabel classes={captionClasses}>
          <Box
            flexGrow={1}
            maxWidth="100%"
            flexBasis="0"
            className={labelClassName}
          >
            {label}
          </Box>
          <Box className={percentageClassName}>{formatter(value)}</Box>
        </InputLabel>
      )}
      <Box position="relative">
        <LinearProgress
          color={color === "default" ? "primary" : color}
          value={value}
          classes={classes}
          variant="determinate"
        />
      </Box>
      {Boolean(hint) && (
        <Box marginTop="8px">
          <Caption>{hint}</Caption>
        </Box>
      )}
    </Fragment>
  );
}

ProgressBarNoDot.propTypes = {
  value: PropTypes.number,
  color: PropTypes.oneOf(["primary", "secondary", "default"]),
  size: PropTypes.oneOf(["normal", "large"]),
  label: PropTypes.string,
  hint: PropTypes.string,
  /**
   * Function to format value as percentage.
   */
  formatter: PropTypes.func,
};
