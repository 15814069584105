import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "11px",
      lineHeight: "16px",
      letterSpacing: "0.05em",
      textTransform: "uppercase",
      color: ({ color }) => {
        if (color === "black") {
          return "#000000";
        } else if (color === "primary") {
          return theme.palette.primary.main;
        } else if (color === "success") {
          return theme.palette.success.main;
        } else {
          return "#425466";
        }
      },
    },
  }),
  { name: "Caption" }
);

export default function Caption({ color = "default", className, ...props }) {
  const classes = useStyles({ color });
  return <div className={clsx(classes.root, className)} {...props} />;
}

Caption.propTypes = {
  color: PropTypes.oneOf(["default", "black", "primary", "success"]),
  className: PropTypes.string,
  children: PropTypes.node,
};
