import { SvgIcon } from "@material-ui/core";

export default function StarIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17" fill="none">
      <path
        opacity="0.8"
        d="M4.06322 16.0528C3.88956 16.0528 3.71711 15.9986 3.56994 15.8922C3.29579 15.6935 3.16773 15.352 3.24234 15.0225L4.26202 10.5308L0.803915 7.4981C0.549721 7.27619 0.452614 6.92477 0.556977 6.60321C0.661339 6.28227 0.945282 6.05504 1.28147 6.02396L5.85697 5.60857L7.66596 1.37518C7.79934 1.06415 8.10312 0.863281 8.44063 0.863281C8.77815 0.863281 9.08192 1.06415 9.21531 1.37445L11.0243 5.60857L15.5991 6.02396C15.936 6.05431 16.2199 6.28227 16.3243 6.60321C16.4287 6.92416 16.3321 7.27619 16.078 7.4981L12.6198 10.5302L13.6395 15.0217C13.7143 15.352 13.5861 15.6935 13.312 15.8916C13.0386 16.0897 12.674 16.1049 12.3861 15.9319L8.44063 13.5741L4.49518 15.9332C4.36179 16.0124 4.21317 16.0528 4.06322 16.0528ZM8.44063 12.5584C8.59059 12.5584 8.73909 12.5987 8.87259 12.6779L12.5961 14.9049L11.6338 10.6655C11.5651 10.3638 11.6675 10.0487 11.9007 9.84462L15.1659 6.981L10.8459 6.5887C10.5349 6.56028 10.2674 6.36474 10.1459 6.07814L8.44063 2.0831L6.73335 6.07874C6.61314 6.36341 6.34564 6.55895 6.03534 6.58737L1.71476 6.97967L4.97986 9.84329C5.21374 10.048 5.31605 10.3624 5.24676 10.6649L4.28512 14.9042L8.00867 12.6779C8.14206 12.5987 8.29068 12.5584 8.44063 12.5584ZM5.82202 5.69044C5.82202 5.69044 5.82202 5.69116 5.82129 5.69177L5.82202 5.69044ZM11.0579 5.6885L11.0586 5.68983C11.0586 5.68911 11.0586 5.68911 11.0579 5.6885Z"
        fill="#191847"
        stroke="#191847"
        strokeWidth="0.7"
      />
    </SvgIcon>
  );
}
