import React, {useState, useContext, useEffect} from 'react';
import {ContextGlobal} from './../context/globalContext';
import axios from 'axios';
import Countdown, {zeroPad} from 'react-countdown';
import { Formik, useFormik } from 'formik';
import ReactJson from 'react-json-view';
import * as Yup from 'yup'; 
import {
    BrowserRouter as Router,
    useHistory,
    Link
  } from "react-router-dom";
import './../scss/style.scss';
import mail from "./../img/logo_eml.svg";

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
};

function MainPanel() {

    const [msg, cambiarMsg] = useState('');
    const [activo, setActivo] = useState(true);
    const enableButton = () => setActivo(false);
    const {user} = useContext(ContextGlobal);
    const apiUrl = '/api';
    let history = useHistory();

    const valSchema = Yup.object().shape({
      })

    const form_reg = useFormik({
        initialValues: {
          emptyt: ''  
        },
        onSubmit: async (e) => {
          cambiarMsg('');
          const apiAxios = axios.create({
            baseURL: apiUrl,
              })
          const result = apiAxios.get('/email/resend', { 
                baseURL: apiUrl,
                headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
              })
                .then((response) => {
                  history.push('/registercumsg');
                  //console.log(response.data);
                }).catch((error) => {
                  if(error.response.data.status=='06') { 
                    cambiarMsg('Correo Electrónico no existe.');
                  } else if(error.response.data.status=='02') {
                    cambiarMsg('El correo esta registrado, pero aun no ha sido Validado. '+
                    'Por favor revise su bandeja de entrada y siga las instrucciones');
                  } else {
                    cambiarMsg('Algo salio mal, por favor intente mas tarde');
                  }
                  //console.log(error.response.data);
                })
          ;
        },
        validationSchema: valSchema
      });

  return (
    <>
    {sessionStorage.getItem('access_token') !== null}
    <div className="App" style={{width: '100%'}}>
        <div className="c-fullw"><img src={mail} alt="Correo" /></div>
        <div className="si-content" style={{textAlign:'center', fontSize: '14px'}}>
            <span style={{fontSize:'21px', fontWeight:'500'}}>Te enviamos un correo de validación.</span>
            <br/>
            Revisa tu correo electrónico {user.email} para validar tu cuenta. No olvides revisar tu carpeta de spam.
            <hr className="si-separador"></hr>
            ¿No te llegó el correo?, espera <Countdown date={Date.now() + 60000} renderer={renderer} onComplete={enableButton} /> para solicitar un nuevo envío.
            <br/>
            <br/><br/>
            <form action="" onSubmit={form_reg.handleSubmit}>
                <button type="submit" name='ingresar' className='btn-registro' disabled={activo}>Haz click aquí para para solicitar reenvío</button>
                <span style={{color: 'red', fontSize: '14px'}}>{msg}</span>
            </form>

        </div>
    </div>
     
    </>
  );
}

export default MainPanel;
