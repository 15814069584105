import { withStyles } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";

export const styles = (theme) => ({
  root: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "18.88px",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    color: "#000000",
    "&$focused": {
      color: "#000000",
    },
    "&$disabled": {
      color: "#000000",
    },
    "&$error": {
      color: "#000000",
    },
  },
  focused: {},
  disabled: {},
  error: {},
});

export default withStyles(styles, { name: "BFormLabel" })(FormLabel);
