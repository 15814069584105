import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { Fragment } from "react";
import ResultPanelGeneral from "./ResultPanelGeneral";
import ResultPanelNextSteps from "./ResultPanelNextSteps";
import ResultPanelMaturityByDimensions from "./ResultPanelMaturityByDimensions";
import ResultPanelComparison from "./ResultPanelComparison";
import ResultPanelMaturityInTime from "./ResultPanelMaturityInTime";

function generateRandomDimensionDataItem() {
  const index = Math.floor(Math.random() * 6);
  return {
    date: "01 Jun, 2021",
    graphValue: index,
    value: index * 20,
    tier: ["", "Inicial", "Novato", "Competente", "Avanzado", "Experto"][index],
    direction: ["inc", null, "dec"][Math.floor(Math.random() * 3)],
  };
}

export default function ResultLayout() {
  const onBack = () => console.log("onBack");
  const onNewCheck = () => console.log("onNewCheck");
  return (
    <Fragment>
      <Container>
        <Grid container justify="space-between">
          <Grid item>
            <Button variant="outlined" size="small" onClick={onBack}>
              Volver al escritorio
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onNewCheck}>
              Nuevo Chequeo
            </Button>
          </Grid>
        </Grid>
        <Box marginTop="33px" marginBottom="33px">
          <ResultPanelGeneral
            tier={{
              value: 0,
              name: "Inicial",
              description:
                "En términos generales, carece de desarrollo de ténologías digitales en el que hacer diario de su negocio",
            }}
            companyName="Empresa distribuidora Nombre  y Asociados SPA"
            madeBy="Editha Fuentes"
            email="edithaux@gmail.com"
            start="12 junio, 2021"
            end="12 junio, 2021"
            onDownloadDocument={() => {
              console.log("onDownloadDocument");
            }}
            onViewOffer={() => {
              console.log("onViewOffer");
            }}
          />
        </Box>
        <Box marginBottom="16px">
          <ResultPanelNextSteps>
            <Typography variant="body1" style={{ opacity: 0.7 }} paragraph>
              Para iniciar el proceso de transformación digital de su pyme es
              necesario que genere ciertas acciones. Amet minim mollit non
              deserunt ullamco est sit aliqua dolor do amet sint.
            </Typography>
            <Typography variant="body1" style={{ opacity: 0.7 }} paragraph>
              Para iniciar el proceso de transformación digital de su pyme es
              necesario que genere ciertas acciones. Amet minim mollit non
              deserunt ullamco est sit aliqua dolor do amet sint. Para iniciar
              el proceso de transformación digital de su pyme es necesario que
              genere cierta... Para iniciar el proceso de transformación digital
              de su pyme es necesario que genere ciertas acciones. Amet minim
              mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            </Typography>
            <Typography variant="body1" style={{ opacity: 0.7 }}>
              Para iniciar el proceso de transformación digital de su pyme es
              necesario que genere ciertas acciones.
            </Typography>
          </ResultPanelNextSteps>
        </Box>
        <Box marginBottom="33px">
          <ResultPanelMaturityByDimensions
            items={[
              {
                image: "/madurez-por-dimension/procesos.svg",
                name: "Comunicaciones",
                description:
                  "Para iniciar el proceso de transformación digital de su pyme es necesario que genere ciertas acciones. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.  Para iniciar el proceso de transformación digital de su pyme es necesario que genere ciertas acciones. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
                tier: { name: "Novato", value: 10 },
                onViewOffer: (index) => console.log("onViewOffer", index),
              },
              {
                image: "/madurez-por-dimension/procesos.svg",
                name: "Cultura y liderazgo",
                description:
                  "Para iniciar el proceso de transformación digital de su pyme es necesario que genere ciertas acciones. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.  Para iniciar el proceso de transformación digital de su pyme es necesario que genere ciertas acciones. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
                tier: { name: "Inicial", value: 5 },
                onViewOffer: (index) => console.log("onViewOffer", index),
              },
            ]}
          />
        </Box>
        <Box marginBottom="33px">
          <ResultPanelComparison
            dimensions={[
              {
                name: "Comunicaciones",
                tier: {
                  name: "Inicial",
                  value: Math.floor(Math.random() * 6) * 20,
                },
                industryTier: {
                  name: "Novato",
                  value: Math.floor(Math.random() * 6) * 20,
                },
              },
              {
                name: "Cultura y liderazgo",
                tier: {
                  name: "Inicial",
                  value: Math.floor(Math.random() * 6) * 20,
                },
                industryTier: {
                  name: "Novato",
                  value: Math.floor(Math.random() * 6) * 20,
                },
              },
              {
                name: "Tecnologías y habilidades digitales",
                tier: {
                  name: "Inicial",
                  value: Math.floor(Math.random() * 6) * 20,
                },
                industryTier: {
                  name: "Novato",
                  value: Math.floor(Math.random() * 6) * 20,
                },
              },
              {
                name: "Estrategia y transformación digital",
                tier: {
                  name: "Inicial",
                  value: Math.floor(Math.random() * 6) * 20,
                },
                industryTier: {
                  name: "Novato",
                  value: Math.floor(Math.random() * 6) * 20,
                },
              },
              {
                name: "Datos y analítica",
                tier: {
                  name: "Inicial",
                  value: Math.floor(Math.random() * 6) * 20,
                },
                industryTier: {
                  name: "Novato",
                  value: Math.floor(Math.random() * 6) * 20,
                },
              },
              {
                name: "Personas y organización",
                tier: {
                  name: "Inicial",
                  value: Math.floor(Math.random() * 6) * 20,
                },
                industryTier: {
                  name: "Novato",
                  value: Math.floor(Math.random() * 6) * 20,
                },
              },
              {
                name: "Procesos",
                tier: {
                  name: "Inicial",
                  value: Math.floor(Math.random() * 6) * 20,
                },
                industryTier: {
                  name: "Novato",
                  value: Math.floor(Math.random() * 6) * 20,
                },
              },
            ]}
            rubros={[
              {
                value: "Agricultura",
                label: "10",
              },
            ]}
            tamanos={[
              {
                value: "PYME",
                label: "PYME",
              },
              {
                value: "Grande",
                label: "Grande",
              },
            ]}
            regiones={[
              {
                value: "RM",
                label: "Metropolitana",
              },
            ]}
            barsData={{
              my: {
                title: "Inicial",
                description: "My PYME",
                value: Math.floor(Math.random() * 6),
              },
              others: {
                title: "Competente",
                description: "1.500 pymes",
                value: Math.floor(Math.random() * 6),
              },
            }}
            onUpdateBars={(formBarsValues) => {
              console.log({ formBarsValues });
            }}
          />
        </Box>
        <Box marginBottom="33px">
          <ResultPanelMaturityInTime
            data={[
              { label: "10 jun 21", value: 0 },
              { label: "10 jun 21", value: 1 },
              { label: "10 jun 21", value: 4 },
              { label: "10 jun 21", value: 3 },
              { label: "10 jun 21", value: 2 },
            ]}
            dimensions={[
              {
                name: "Comunicaciones",
                data: [
                  generateRandomDimensionDataItem(),
                  generateRandomDimensionDataItem(),
                  generateRandomDimensionDataItem(),
                ],
              },
              {
                name: "Personas y organización",
                data: [
                  generateRandomDimensionDataItem(),
                  generateRandomDimensionDataItem(),
                  generateRandomDimensionDataItem(),
                ],
              },
            ]}
          />
        </Box>
      </Container>
    </Fragment>
  );
}
