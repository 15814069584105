import React, { useEffect } from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import InputLabel from "../components/InputLabel";
import SelectChipTextField from "../components/SelectChipTextField";
import SelectTextField from "../components/SelectTextField";
import Switch from "../components/Switch";

const useStyles = makeStyles({
  subtitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "157.33%",
    display: "flex",
    alignItems: "center",
    color: "#0A2540",
    opacity: "0.5",
    flex: "none",
    order: "1",
    alignSelf: "stretch",
    flexGrow: "0",
    margin: "0px 0px",
  },
});

export default function OfferForm(props) {
  const { subtitle, dimensions, offerTypes, organizers, onSubmit, ini } = props;
  const classes = useStyles();

  const { values, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        dimension: ini ?? "",
        tipos_de_oferta: [],
        organizador: "",
        online: true,
        presencial: true,
      },
      onSubmit,
    });

  const checkOnile = () => {
    if (values.online === true && values.presencial === false) {
      setFieldValue("presencial", true);
    }
  };

  const checkPres = () => {
    if (values.online === false && values.presencial === true) {
      setFieldValue("online", true);
    }
  };

  const array1 = [{ value: "0", label: "Todos" }];
  const array2 = [...array1, ...dimensions];
  const array3 = [...array1, ...organizers];

  return (
    <form action="" onSubmit={handleSubmit}>
      <Box marginBottom="32px">
        <Typography variant="h1">Capacitaciones</Typography>
        <Box className={classes.subtitle}>{subtitle}</Box>
      </Box>
      <Box marginBottom="32px">
        <SelectTextField
          label="Dimensiones"
          name="dimension"
          id="dimension"
          value={values.dimension}
          onChange={handleChange}
          onBlur={handleBlur}
          options={array2}
          fullWidth
        />
      </Box>
      <Box marginBottom="32px">
        <SelectChipTextField
          label="Tipo de Capacitación"
          options={offerTypes}
          value={values.tipos_de_oferta}
          onChange={(newTypes) => setFieldValue("tipos_de_oferta", newTypes)}
        />
      </Box>
      <Box marginBottom="32px">
        <Box marginBottom="4px">
          <InputLabel>Modalidad</InputLabel>
        </Box>
        <Box marginBottom="16px">
          <Grid container spacing={1}>
            <Grid item xs>
              Online
            </Grid>
            <Grid item xs="auto">
              <Switch
                checked={values.online}
                onChange={(evt) => {
                  setFieldValue("online", evt.target.checked);
                  checkOnile();
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs>
              Presencial
            </Grid>
            <Grid item xs="auto">
              <Switch
                checked={values.presencial}
                onChange={(evt) => {
                  setFieldValue("presencial", evt.target.checked);
                  checkPres();
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box marginBottom="32px">
        <SelectTextField
          label="Organizado por"
          name="organizador"
          id="organizador"
          value={values.organizador}
          onChange={handleChange}
          onBlur={handleBlur}
          options={array3}
          fullWidth
        />
      </Box>
      <Box>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Filtrar
        </Button>
      </Box>
    </form>
  );
}

OfferForm.propTypes = {
  subtitle: PropTypes.string,
  dimensions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })
  ),
  offerTypes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })
  ),
  organizers: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })
  ),
  onSubmit: PropTypes.func,
};
