import { SvgIcon } from "@material-ui/core";

export default function CheckboxIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect
        x="2.5"
        y="2.5"
        width="19"
        height="19"
        rx="3.5"
        fill="#ffffff"
        stroke="#dde2e5"
      />
    </SvgIcon>
  );
}
