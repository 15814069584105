import { Box, Button, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import Caption from "../components/Caption";
import Divider from "../components/Divider";
import ProgressBar from "../components/ProgressBarNoDot";

export default function QuestionSidePanel(props) {
  const {
    caption,
    companyName,
    subHeader,
    completitionValue,
    onClick,
    details,
  } = props;
  return (
    <React.Fragment>
      <Box marginBottom="32px">
        <Button size="small" variant="outlined" onClick={onClick}>
          Volver a perfil del usuario
        </Button>
      </Box>
      <Box marginBottom="20px">
        <Caption style={{ opacity: 0.6 }}>{caption}</Caption>
        <Typography variant="h2">{companyName}</Typography>
        <Typography variant="body1">{subHeader}</Typography>
      </Box>
      <Box marginBottom="16px">
        <Divider />
      </Box>
      <Box marginBottom="20px" paddingBottom="10px">
        <ProgressBar
          label="Estado de avance"
          value={completitionValue}
          color="primary"
        />
      </Box>
      <Box marginBottom="16px">
        <Divider />
      </Box>
      <Box marginBottom="20px">
        <Typography variant="h2">Información</Typography>
      </Box>
      {details.map((detail, index) => {
        const isLast = index >= details.length - 1;
        return (
          <React.Fragment>
            <Box>
              <Box marginBottom="8px">
                <Caption>{detail.title}</Caption>
              </Box>
              <Typography variant="body1">{detail.description}</Typography>
            </Box>
            {!isLast && (
              <Box marginTop="8px" marginBottom="8px">
                <Divider />
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

QuestionSidePanel.propTypes = {
  caption: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  completitionValue: PropTypes.number.isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
};
