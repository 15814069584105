import { MenuItem, withStyles } from "@material-ui/core";

const styles = () => ({
  root: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#191847",
    "&:not(:last-child)::after": {
      height: "1px",
      background: "black",
      content: "''",
      width: "calc(100% - 32px)",
      position: "absolute",
      bottom: "0",
      opacity: "0.2",
    },
    "& .MuiListItemText-primary.MuiTypography-body1": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#191847",
    },
    "& .MuiListItemText-secondary": {
      fontFamily: "Poppins",
      fontStyle: "italic",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "157.33%",
      color: "#6F7B87",
    },
  },
});

export default withStyles(styles, { name: "BSelectItem" })(MenuItem);
