import axios from "axios";
import { BrowserRouter as Router, useHistory, Link, useParams } from "react-router-dom";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import Caption from "../components/Caption";
import Divider from "../components/Divider";
import ProgressBar from "../components/ProgressBarNoDot";
import TypographySmall from "../components/TypographySmall";




export default function SurveyCard(props) {
  const {
    image,
    title,
    description,
    numberOfQuestions,
    estimatedTime,
    onContinue,
    completition,
    state_id,
    survey_id
  } = props;
  return (
    <Paper>
      <Box padding="24px">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs="auto">
            <Box padding="8px">
            <img
              height="64px"
              src={image}
              alt={title}
            />
          </Box>
          </Grid>
          <Grid item xs>
            <Box marginBottom="5px">
              <Typography variant="h2" color="primary">
                {title}
              </Typography>
            </Box>
            <Typography variant="body1">{description}</Typography>
          </Grid>
        </Grid>
        <Box marginTop="20px" marginBottom="20px">
          <Divider />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <Box width="150px">
              <Caption color="black">Preguntas</Caption>
              <Typography variant="h2" style={{ color: "#425466" }}>
                {numberOfQuestions}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Box width="150px">
              <Caption color="black">Tiempo Estimado</Caption>
              <Typography variant="h2" style={{ color: "#425466" }}>
                {estimatedTime}:00 min
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box marginLeft="auto" width="212px">
              <Button
                variant="contained"
                color="primary"
                onClick={onContinue}
                fullWidth
              >
                Continuar
              </Button>
            </Box>
          </Grid>
        </Grid>
        {Boolean(completition) ? (
          <React.Fragment>
            <Box marginTop="20px" marginBottom="20px">
              <Divider />
            </Box>
            <ProgressBar
              label="Estado de avance"
              value={completition.value}
              color="secondary"
            />
            <Box marginTop="8px">
              <TypographySmall>{completition.hint}</TypographySmall>
            </Box>
          </React.Fragment>
        ) : null}
      </Box>
    </Paper>
  );
}

SurveyCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  numberOfQuestions: PropTypes.string.isRequired,
  estimatedTime: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  completition: PropTypes.shape({
    value: PropTypes.number,
    hint: PropTypes.string,
  }),
};
