import { makeStyles } from "@material-ui/core";
import { Chart } from "react-google-charts";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    "& svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(2) > g > path": {
      fillOpacity: "0.08 !important",
    },
    "& svg > g:nth-child(3) > g:nth-child(2) > g:nth-child(1) > rect": {
      fillOpacity: "0.35 !important",
    },
  },
});

const textStyle = {
  fontName: "Poppins",
  fontSize: "12",
  textAlign: "right",
  color: "#425466",
  opacity: "0.7",
};

export default function ResultPanelMaturityInTimeChart(props) {
  const { data } = props;
  const classes = useStyles();
  const tiers = ["Inicial", "Novato", "Competente", "Avanzado", "Experto"];
  return (
    <Chart
      height="290px"
      chartType="AreaChart"
      loader={<div>Loading Chart</div>}
      className={classes.root}
      data={[["Fecha", "Nivel"]].concat(
        data.map((d) => [d.label, d.value + 1])
      )}
      options={{
        legend: "none",
        hAxis: {
          textStyle,
          viewWindow: {
            min: -0.2,
            max: data.length * 1.05,
          },
        },
        vAxis: {
          baselineColor: "none",
          gridlines: { count: 0, color: "#C9C9C9" },
          ticks: [{ v: 0, f: "" }].concat(
            tiers.map((tier, i) => ({
              v: i + 1,
              f: tier,
            }))
          ),
          textStyle,
          maxValue: 5,
        },
        // For the legend to fit, we make the chart area smaller
        // chartArea: { width: "50%", height: "70%" },
        chartArea: { width: "90%", height: "80%", right: 10, top: 10 },
        lineWidth: 0,
        pointSize: 4,
        pointShape: "circle",
        colors: ["#2B44FF"],
        backgroundColor: "#F9FAFC",
      }}
      // For tests
      rootProps={{ "data-testid": "1" }}
    />
  );
}

ResultPanelMaturityInTimeChart.propTypes = {
  /**
   * Los value deben ir desde 0 a 4
   * Representan en orden: Inicial, Novato, Competente, Avanzado, Experto
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ),
};
