import React from "react";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import ProgressBar from "../components/ProgressBarNoDot";
import Divider from "../components/Divider";
import Caption from "../components/Caption";

export default function QuestionSidePanel(props) {
  const {
    image,
    caption,
    companyName,
    name,
    description,
    completition,
    completitionHint,
    onExit,
  } = props;
  return (
    <React.Fragment>
      <Box style={{ opacity: 0.3 }} marginBottom="20px">
        <Caption>{caption}</Caption>
        <Typography variant="h2">{companyName}</Typography>
      </Box>
      <Paper>
        <Box
          paddingTop="24px"
          paddingRight="30px"
          paddingLeft="30px"
          paddingBottom="24px"
        >
          {image ? <Box padding="13px">{image}</Box> : null}
          <Box marginBottom="4px">
            <Typography variant="h2">{name}</Typography>
          </Box>
          <Box marginBottom="16px">
            <Typography variant="body1">{description}</Typography>
          </Box>
          <Box marginBottom="16px">
            <Divider />
          </Box>
          <Box marginBottom="16px" paddingTop="10px" paddingBottom="10px">
            <ProgressBar
              label="Estado de avance"
              value={completition}
              color="secondary"
              hint={completitionHint}
            />
          </Box>
          <Box marginBottom="16px">
            <Divider />
          </Box>
          <Box textAlign="center">
            <Button variant="outlined" size="small" onClick={onExit}>
              Salir
            </Button>
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}

QuestionSidePanel.propTypes = {
  /**
   * Retornar la imagen. Como argumentos van datos
   * del renderizado de la imagen
   */
  image: PropTypes.node,
  caption: PropTypes.string,
  companyName: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  completition: PropTypes.number,
  completitionHint: PropTypes.string,
  onExit: PropTypes.func,
};
