import React, {useState, useContext, useEffect} from 'react';
import {
  BrowserRouter as Router,
  useHistory,
  Link,
  useParams
} from "react-router-dom";
import loading from "./../img/loading.gif";


function LandingColaborador() {
  let { organizacion, iniciativa } = useParams();
  let history = useHistory();
  let land = {colaborador: 'Asech'}; 

  useEffect( () => {
    switch (organizacion.toLowerCase()) {
      case "asech":
        sessionStorage.setItem('colabora', "Asech");
        break;
      case "bancoestado":
        sessionStorage.setItem('colabora', "BancoEstado");
        break;
      case "corfo":
        sessionStorage.setItem('colabora', "Corfo");
        break;
      case "sercotec":
        sessionStorage.setItem('colabora', "Sercotec");
        break;
      default:
        sessionStorage.setItem('colabora', organizacion);
        break;
    }
    
    sessionStorage.setItem('colabora_ini', iniciativa);
    sessionStorage.setItem('aceptaTC',null);
    window.location.href ='/landing/'+organizacion+'/Index.html';
    })

  return (
    <>
    <div style={{width: "100%", textAlign: "center", marginTop: "50px"}}>
    <img src={loading} style={{width: "50px"}}/>
    </div>
    </>
  );
}

export default LandingColaborador;