import { Typography, Box, Paper, makeStyles } from "@material-ui/core";
import ProgressBar from "../components/ProgressBar";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles(
  {
    name: {
      width: "1%",
      whiteSpace: "nowrap",
      textAlign: "right",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0.02em",
    },
    primary: {
      paddingLeft: "32px",
      color: "#2B44FF",
    },
  },
  { name: "ResultPanelComparisonDimension" }
);

export default function ResultPanelComparisonDimension(props) {
  const { name, tier, industryTier } = props;
  const classes = useStyles();
  return (
    <Paper>
      <Box padding="26px">
        <Box marginBottom="20px">
          <Typography variant="h2">{name}</Typography>
        </Box>
        <table>
          <tbody>
            <tr>
              <td>
                <ProgressBar color="primary" value={tier.value} />
              </td>
              <td className={clsx(classes.name, classes.primary)}>
                {tier.name}
              </td>
            </tr>
            <tr>
              <td>
                <ProgressBar color="default" value={industryTier.value} />
              </td>
              <td className={classes.name}>{industryTier.name}</td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Paper>
  );
}

ResultPanelComparisonDimension.propTypes = {
  name: PropTypes.string.isRequired,
  tier: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  industryTier: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
};
