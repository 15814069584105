import React, { useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { ContextGlobal } from "./../context/globalContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import axios from "axios";
import ReactJson from "react-json-view";
import { Box, Button, Divider, Grid } from "@material-ui/core";
import { validate as validateRut } from "rut.js";
import TextField from "../lib/components/TextField";
import SelectTextField from "../lib/components/SelectTextField";
import SelectBox from "../lib/components/SelectBox";
import CheckboxField from "../lib/components/CheckboxField";
import "./../scss/style.scss";
import hand from './../img/hand.svg';
import l_fast from './../img/logoFast.png';
import l_best from './../img/logoBancoEstado.jpg';
import l_serco from './../img/logoSercotec.png';
import l_corfo from './../img/Logo_Corfo.png';
import l_asech from './../img/logoAsech.svg';
import l_ruta from './../img/logo_RutaDigital.jpg';
import close from './../img/close.svg';

const valSchema = Yup.object().shape({
  constituida:        Yup.string().required("Este campo es Requerido (*)"),
  rut_pyme:           Yup.string().required("Debes ingresar RUT de tu pyme.")
                      .test("rut-valido", "RUT no válido.", (value) => validateRut(value)),
  nombre:             Yup.string().when('step', {is: true, then: Yup.string().required("Este campo es Requerido (*)")}),                   
  empresatamano_id:   Yup.string().when('step', {is: true, then: Yup.string().required("Este campo es Requerido (*)")}),
  empresaempleado_id: Yup.string().when('step', {is: true, then: Yup.string().required("Este campo es Requerido (*)")}),
  actividad_codigo:   Yup.string().when('step', {is: true, then: Yup.string().required("Este campo es Requerido (*)")}),
  rubro_id:           Yup.string().when('step', {is: true, then: Yup.string().required("Este campo es Requerido (*)")}),
  cargo_id:           Yup.string().when('step', {is: true, then: Yup.string().required("Este campo es Requerido (*)")}),
  region_id:          Yup.string().when('step', {is: true, then: Yup.string().required("Este campo es Requerido (*)")}),
  comuna_id:          Yup.string().when('step', {is: true, then: Yup.string().required("Este campo es Requerido (*)")}),
  terminos_condiciones: Yup.bool().when('step', {is: true, then: Yup.bool().oneOf([true], "Requerido (*)").required()}),
});

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  paper: {
      position: 'absolute',
      width: 650,
      height: 500,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
  },
}));


function RegisterCompany(props) {
    const {user, authSes} = useContext(ContextGlobal);
    const {survey, setSurvey} = useContext(ContextGlobal);
    let history = useHistory();
    const [msg, cambiarMsg] = useState("");
    const [pyme, setPyme] = useState({
        razon_social: "",
        region: "",
        comuna: ""
    });

    const [sw01, set01] = useState("");
    const [sw02, set02] = useState(false);
    const [sw03, set03] = useState("hide");
    const [sw04, set04] = useState("hide");
    const [sw05, set05] = useState("hide");
    const [sw06, set06] = useState("hide");
    const [sw07, set07] = useState("");
    const [sw_a, seta] = useState("hide");
    const [sw_r, setr] = useState("hide");
    const [disable, setDisable] = useState(true);
    const [disabler, setDisabler] = useState(false);
    const [listarubros, setListarubros] = useState([]);
    const [listatamannos, setListatammanos] = useState([]);
    const [listaemplea, setListaemplea] = useState([]);
    const [listacargos, setListacargos] = useState([]);
    const [listaregion, setListaregion] = useState([]);
    const [listacomuna, setListacomuna] = useState([]);
    const [terminos, setTerminos] = useState([]);
    
    const [showPassword, setShowPassword] = useState(false);
    const apiUrl = "/api";
    const colaborador = sessionStorage.getItem('colabora');
    const {validate, clean, format }  = require('rut.js');

    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
  
    const handleOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };

    const decimalToHexString = (number) =>
    {
      if (number < 0) { number = 0xFFFFFFFF + number + 1; }
      return number.toString(16).toUpperCase();
    }  


    const cargaTerminos = () => {
      let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
    });
    const result = apiAxios
      .post("/terminosycondiciones/texto/" + colaborador)
      .then((response) => {
        if (response.data.data) {
          setTerminos(response.data.data);
          //console.log("T:", response.data.data);
        } 
      })
      .catch((error) => {
        //console.log(error.response?.data);
      });
    }


    const cargaRubro = () => {
          let apiAxios = axios.create({
          baseURL: apiUrl,
          headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
        });
        const result = apiAxios
          .get("/rubros", form_reg.values)
          .then((response) => {
            if (response.data.data) {
              let tmprubros=response.data.data.map((act)=>({value:act.id,label:act.nombre}));
              setListarubros(tmprubros);
            } 
          })
          .catch((error) => {
            //console.log(error.response?.data);
          });
        }
 
        const cargaTamanno = () => {
          let apiAxios = axios.create({
          baseURL: apiUrl,
          headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
        });
        const result = apiAxios
          .get("/empresatamanos", form_reg.values)
          .then((response) => {
            if (response.data.data) {
              let tmp=response.data.data.map((act)=>({value:act.id,label:act.nombre,hint:act.descripcion}));
              setListatammanos(tmp);
            } 
          })
          .catch((error) => {
            //console.log(error.response?.data);
          });
        }

        const cargaEmplea = () => {
          let apiAxios = axios.create({
          baseURL: apiUrl,
          headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
        });
        const result = apiAxios
          .get("/empresacantidadempleados", form_reg.values)
          .then((response) => {
            if (response.data.data) {
              let tmp=response.data.data.map((act)=>({value:act.id,label:act.nombre}));
              setListaemplea(tmp);
            } 
          })
          .catch((error) => {
            //console.log(error.response?.data);
          });
        }

        const cargaCargos = () => {
          let apiAxios = axios.create({
          baseURL: apiUrl,
          headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
        });
        const result = apiAxios
          .get("/cargos", form_reg.values)
          .then((response) => {
            if (response.data.data) {
              let tmp=response.data.data.map((act)=>({value:act.id,label:act.nombre}));
              setListacargos(tmp);
            } 
          })
          .catch((error) => {
            //console.log(error.response?.data);
          });
        }

        const cargaRegion = () => {
          let apiAxios = axios.create({
          baseURL: apiUrl,
          headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
        });
        const result = apiAxios
          .get("/regiones", form_reg.values)
          .then((response) => {
            if (response.data.data) {
              let tmp=response.data.data.map((act)=>({value:act.id,label:act.nombre}));
              setListaregion(tmp);
            } 
          })
          .catch((error) => {
            //console.log(error.response?.data);
          });
        }

        const cargaComuna = () => {
          let apiAxios = axios.create({
          baseURL: apiUrl,
          headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
        });
        const result = apiAxios
          .get("/comunas", form_reg.values)
          .then((response) => {
            if (response.data.data) {
              let tmp=response.data.data.map((act)=>({value:act.id, label:act.nombre, region_id:act.region_id}));
              setListacomuna(tmp);
            } 
          })
          .catch((error) => {
            //console.log(error.response?.data);
          });
        }


  const form_reg = useFormik({
    initialValues: {
        step: false,
        constituida: true,
        rut_pyme: "",
        nombre: "",
        empresatamano_id: "",
        empresaempleado_id: "",
        actividad_codigo: "",
        rubro_id: "",
        cargo_id: "",
        region_id: "",
        comuna_id: "",
        terminos_condiciones: false,
        acepta_notificaciones: false,
        colaborador: sessionStorage.getItem('colabora'),
        iniciativa: sessionStorage.getItem('colabora_ini')
    },
    onSubmit: async (e) => {
      setDisable(false);
      cambiarMsg("");
      cargaTamanno();
      cargaEmplea();
      cargaCargos();
      cargaRegion();
      cargaComuna();
      cargaTerminos();
      
      /* Pyme NO esta costituida */
      if (form_reg.values.constituida === false) {
        set01("hide");
        set02(true);
        set03("");
        set04("");
        set05("hide");
        cargaRubro();
        setr("");
        seta("hide");
        form_reg.setFieldValue('actividad_codigo', "no");
        form_reg.setFieldValue('step', true)
        form_reg.setTouched({}, false);
      /* Pyme SI esta costituida */
      } else {
        const apiAxios = axios.create({
          baseURL: apiUrl,
          headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
        });
        const result = apiAxios
          .post("/empresa/consultaRut", form_reg.values)
          .then((response) => {
            //console.log(response.data);
            set01("hide");
            set02(true);
            set03("");
            set05("");
            setr("hide");
            seta("");
            setPyme({
                ...pyme, 
                razon_social: response.data.data.Contribuyente.NMB,
                region: response.data.data.Direcciones.REGION.nombre,
                comuna: response.data.data.Direcciones.COMUNA.nombre 
              });
              form_reg.setFieldValue('region_id', response.data.data.Direcciones.REGION.id);
              form_reg.setFieldValue('comuna_id', response.data.data.Direcciones.COMUNA.id);
              form_reg.setFieldValue('nombre', response.data.data.Contribuyente.NMB);
              form_reg.setFieldValue('rubro_id', "no");
              form_reg.setFieldValue('step', true);
              form_reg.setTouched({}, false);

            if (response.data.data.ActividadEco && response.data.data.ActividadEco.actividadeschequeo) {
              let tmprubros=response.data.data.ActividadEco.actividadeschequeo.map((act)=>({value:act.codigo,label:act.nombre}));
              setListarubros(tmprubros);
            } else {
              cargaRubro();
            }
            authSes(sessionStorage.getItem('access_token'));
          })
          .catch((error) => {
            if (error.response?.data && error.response.data.status !== "0") {
              cambiarMsg(error.response.data.message);
            } else {
              cambiarMsg(
                "Algo salio mal, por favor intente mas tarde."
              );
            }
            setDisable(true);
          });
      } 
  },
    validationSchema: valSchema,
  });
 
  const getSurveyID = async (rut_pyme) => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
    });
    const result = await apiAxios
      .post("/evaluacion", {rut_pyme: rut_pyme})
      .then((response) => {
         history.push('/mainsurvey/'+decimalToHexString(response.data.data.id));
      })
      .catch((error) => {
        //console.log(error.response?.data);
      });
  }

  const registraEmpresa = () => {
    setDisabler(true);
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
    });

    const result = apiAxios
      .post("/empresa/registro", form_reg.values)
      .then((response) => {
        set03("hide");
        set04("hide");
        set04("");
        getSurveyID(form_reg.values.rut_pyme);        
      })
      .catch((error) => {
        cambiarMsg(error.response.data.message);
        set07("hide");
        set06("");
      });
  }

  const aceptoTC = () => {
    form_reg.setFieldValue('terminos_condiciones', true);
    setOpen(false);
  };

  const { touched, errors } = form_reg;
  const fieldErrorsRut = () => !touched.rut_pyme;
  const fieldErrors = {
    constituida:        touched.constituida && errors.constituida,
    rut_pyme:           touched.rut_pyme && errors.rut_pyme,
    nombre:             touched.nombre && errors.nombre,
    empresatamano_id:   touched.empresatamano_id && errors.empresatamano_id,
    empresaempleado_id: touched.empresaempleado_id && errors.empresaempleado_id,
    actividad_codigo:   touched.actividad_codigo && errors.actividad_codigo,
    rubro_id:           touched.rubro_id && errors.rubro_id,
    cargo_id:           touched.cargo_id && errors.cargo_id,
    region_id:          touched.region_id && errors.region_id,
    comuna_id:          touched.comuna_id && errors.comuna_id,
    terminos_condiciones: touched.terminos_condiciones && errors.terminos_condiciones,
  };

  
  return (
    <div className="App" style={{ width: "100%" }}>
      <Box className="si-content-r" width={600}>
        <div className="si-marco">
        <h4>
          Hola {user.nombre} <img src={hand} alt="mano" /> 
          </h4>
          Antes de comenzar tu chequeo, necesitamos algunos detalles sobre tu pyme
          <br/><br/>
          

          <form action="" onSubmit={form_reg.handleSubmit}>
            <Grid container spacing={2}>
            <Grid item xs={12} className={sw01}>
            ¿Tu pyme cuenta con inicio de actividades ante el SII?  
            </Grid>
            <Grid item xs={12} className={sw01}>
                <SelectBox
                  name="constituida"
                  id="constituida"
                  value={form_reg.values.constituida}
                  onChange={(v) => {
                          form_reg.setFieldValue("constituida", v); 
                          form_reg.values.constituida ? 
                              form_reg.setFieldValue("rut_pyme", user.rut) 
                              : form_reg.setFieldValue("rut_pyme", "")}
                        }
                  onBlur={form_reg.handleBlur}
                  options={[
                    {label: 'Si', value: true}, 
                    {label: 'No', value: false}
                    ]} 
                  error={Boolean(fieldErrors.constituida)}
                  helperText={fieldErrors.constituida}
                  
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                  label="RUT DE LA EMPRESA"
                  type="text"
                  name="rut_pyme"
                  id="rut_pyme"
                  value={form_reg.values.rut_pyme}
                  onChange={form_reg.handleChange}
                  // onBlur={form_reg.handleBlur}
                  onBlur={(e) => {form_reg.setFieldValue('rut_pyme', format(form_reg.values.rut_pyme)); form_reg.handleBlur(e)}}
                  error={Boolean(fieldErrors.rut_pyme)}
                  helperText={fieldErrors.rut_pyme}
                  fullWidth
                  readOnly={sw02}
                />

            </Grid>
            <Grid item xs={4} className={sw05}>
                  <span className="r-bold">RAZÓN SOCIAL</span><br/>{pyme.razon_social}
            </Grid>
            <Grid item xs={4} className={sw05}>
                  <span className="r-bold">REGIÓN</span><br/>{pyme.region}
            </Grid>
            <Grid item xs={4} className={sw05}>
                  <span className="r-bold">COMUNA</span><br/>{pyme.comuna}
            </Grid>

            <Grid item xs={12} className={sw01}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  name="ingresar"
                  disabled={!(form_reg.isValid && form_reg.dirty && disable)}
                  fullWidth>Comenzar
                </Button>
              </Grid>

              <Grid item xs={12} className={sw04}>
                <TextField
                  label="NOMBRE EMPRESA"
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={form_reg.values.nombre}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  error={Boolean(fieldErrors.nombre)}
                  helperText={fieldErrors.nombre}
                  fullWidth/>
            </Grid>
            <Grid item xs={6} className={sw04}>
                <SelectTextField
                  label="REGION"
                  name="region_id"
                  id="region_id"
                  value={form_reg.values.region_id}
                  error={Boolean(fieldErrors.region_id)}
                  helperText={fieldErrors.region_id}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  options={listaregion.sort((a,b)=>{if (a.label<b.label) return -1;if (a.label>b.label) return 1; return 0;})}
                  fullWidth/>
            </Grid>
            <Grid item xs={6} className={sw04}>
                <SelectTextField
                  label="COMUNA"
                  name="comuna_id"
                  id="comuna_id"
                  value={form_reg.values.comuna_id}
                  error={Boolean(fieldErrors.comuna_id)}
                  helperText={fieldErrors.comuna_id}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  options={form_reg?.values?.region_id?listacomuna.filter(com=>com.region_id==form_reg.values.region_id).sort((a,b)=>{if (a.label<b.label) return -1;if (a.label>b.label) return 1; return 0;}):[]}
                  fullWidth/>
            </Grid>
            <Grid item xs={12} className={sw03}><Divider /></Grid>

            <Grid item xs={6} className={sw03}>
                <SelectTextField
                  label="TAMAÑO DE LA EMPRESA"
                  name="empresatamano_id"
                  id="empresatamano_id"
                  value={form_reg.values.empresatamano_id}
                  error={Boolean(fieldErrors.empresatamano_id)}
                  helperText={fieldErrors.empresatamano_id}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  options={listatamannos}
                  fullWidth/>
            </Grid>

            <Grid item xs={6} className={sw03}>
                <SelectTextField
                  label="NÚMERO DE TRABAJADORES"
                  name="empresaempleado_id"
                  id="empresaempleado_id"
                  value={form_reg.values.empresaempleado_id}
                  error={Boolean(fieldErrors.empresaempleado_id)}
                  helperText={fieldErrors.empresaempleado_id}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  options={listaemplea}
                  fullWidth/>
            </Grid>

            <Grid item xs={12} className={sw_r}>
                <SelectTextField
                  label="RUBRO PRINCIPAL"
                  name="rubro_id"
                  id="rubro_id"
                  value={form_reg.values.rubro_id}
                  error={Boolean(fieldErrors.rubro_id)}
                  helperText={fieldErrors.rubro_id}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  options={listarubros}
                  fullWidth/>
            </Grid>

            <Grid item xs={12} className={sw_a}>
                <SelectTextField
                  label="ACTIVIDAD ECONÓMICA PRINCIPAL"
                  name="actividad_codigo"
                  id="actividad_codigo"
                  value={form_reg.values.actividad_codigo}
                  error={Boolean(fieldErrors.actividad_codigo)}
                  helperText={fieldErrors.actividad_codigo}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  options={listarubros}
                  fullWidth/>
            </Grid>

            <Grid item xs={12} className={sw03}><Divider /></Grid>

            <Grid item xs={12} className={sw03}>
                <SelectTextField
                  label="Área en la cual trabaja"
                  name="cargo_id"
                  id="cargo_id"
                  value={form_reg.values.cargo_id}
                  error={Boolean(fieldErrors.cargo_id)}
                  helperText={fieldErrors.cargo_id}

                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  options={listacargos}
                  fullWidth
                />
            </Grid>

            <Grid item xs={12} className={sw03}></Grid>

            <Grid item xs={1} className={sw03}>
                <Box>
                  <CheckboxField
                    label=""
                    type="checkbox"
                    name="terminos_condiciones"
                    id="terminos_condiciones"
                    checked={form_reg.values.terminos_condiciones}
                    value={form_reg.values.terminos_condiciones}
                    onChange={form_reg.handleChange}
                    onBlur={form_reg.handleBlur}
                  />
                </Box>
            </Grid>
            <Grid item xs={11} className={sw03} style={{paddingTop: "15px"}}>
            Para continuar debes aceptar los <Link onClick={handleOpen}>Términos y Condiciones</Link>
            </Grid>

            <Grid item xs={1} className={sw03}>
                <Box>
                  <CheckboxField
                    label=""
                    type="checkbox"
                    name="acepta_notificaciones"
                    id="acepta_notificaciones"
                    checked={form_reg.values.acepta_notificaciones}
                    value={form_reg.values.acepta_notificaciones}
                    onChange={form_reg.handleChange}
                    onBlur={form_reg.handleBlur}
                  />
                </Box>
            </Grid>
            <Grid item xs={11} className={sw03} style={{paddingTop: "10px"}}>
            Autorizo al Ministerio de Economía 
            {colaborador ? 
            ( <> y a {colaborador} para que me envíen </> )
            :  ( <>  para que me envíe </> ) }
            información al correo electrónico.
            </Grid>

              <Grid item xs={6} className={sw03 + sw07}>
                <Button
                  className="btn"
                  variant="contained"
                  type="button"
                  name="ingresar"
                  fullWidth
                  disabled={(disabler)}
                  onClick={() => {window.location.href="/registerpyme"}}
                >Limpiar</Button>
              </Grid>

              <Grid item xs={6} className={sw03}> 
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  name="ingresar"
                  disabled={!(form_reg.isValid && form_reg.dirty && !disabler)}
                  onClick={registraEmpresa}
                  fullWidth
                >Registrar</Button>
              </Grid>

              <Grid item xs={6} className={sw06}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    name="ingresar"
                    fullWidth
                    onClick={() => {window.location.href="/registerpyme"}}
                  >Iniciar de nuevo</Button>
              </Grid>

              <Grid item xs={12}><span style={{ color: "red", fontSize: "14px" }}>{msg}</span></Grid>

            </Grid>
          </form>
        </div>
      
{/*         <div style={{ marginTop: "50px" }}>
          <ReactJson src={form_reg.values} collapsed="true" />
          <ReactJson src={form_reg.errors} collapsed="true" />
          <ReactJson src={form_reg.touched} collapsed="true" />
          <ReactJson src={listacomuna} collapsed="true" /> 
        </div>
 */} 
      </Box>

      <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classes.paper}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}> 
                  <div>
                    <span className="tct">Términos y Condiciones</span>
                  </div>
                  <div><img src={close} onClick={handleClose} alt="Close"/></div>
                </div>
                  <div style={{ width: "600px", height: "370px", overflowY: "scroll" }} className="tc" dangerouslySetInnerHTML={{__html:terminos.texto_tyc}}>
                  </div>
                  <br/>
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%"}}> 
                      <div style={{width: "40%"}}>
                        <Button
                          color="primary"
                          variant="contained"
                          type="button"
                          name="aceptar"
                          style={{width: "100px", height: "45px"}}
                          onClick={aceptoTC}>Aceptar</Button>
                      </div>
                      {(() => {
                        switch (colaborador) {
                          case 'BancoEstado':
                            return <div style={{textAlign: "right"}}>
                              <img src={l_fast} alt="FAST" style={{height: "40px", paddingRight: "25px"}}/>
                              <img src={l_best} alt="BancoEstado" style={{height: "40px"}}/></div>;
                          case 'Corfo':
                            return <div style={{textAlign: "right"}}>
                              <img src={l_fast} alt="FAST" style={{height: "40px", paddingRight: "25px"}}/>
                              <img src={l_corfo} alt="Corfo" style={{height: "40px"}}/></div>;
                          case 'Sercotec':
                            return <div style={{textAlign: "right"}}>
                              <img src={l_fast} alt="FAST" style={{height: "40px", paddingRight: "25px"}}/>
                              <img src={l_serco} alt="Sercotec" style={{height: "40px"}}/></div>;
                          case 'Asech':
                            return <div style={{textAlign: "right"}}>
                              <img src={l_fast} alt="FAST" style={{height: "40px", paddingRight: "25px"}}/>
                              <img src={l_asech} alt="Asech" style={{height: "40px"}}/></div>;
                          case 'RutaDigital':
                            return <div style={{textAlign: "right"}}>
                              <img src={l_fast} alt="FAST" style={{height: "40px", paddingRight: "25px"}}/>
                              <img src={l_ruta} alt="Asech" style={{height: "40px"}}/></div>;
                          default:
                            return <div style={{textAlign: "right"}}>
                              <img src={l_fast} alt="FAST" style={{height: "40px", paddingRight: "25px"}}/></div>;
                        }
                      })()}
                  </div>
                </div>
            </Modal>
        </div>

    </div>
  );
}

export default RegisterCompany;
