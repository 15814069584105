import { SvgIcon } from "@material-ui/core";

export default function CheckIcon(props) {
  return (
    <SvgIcon {...props} style={{ color: "transparent", ...props.style }}>
      <path
        d="M20 6L9 17L4 12"
        stroke="#57D5DB"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
