import { SvgIcon } from "@material-ui/core";

export default function ExpandMore({ fill = "#2B44FF", ...props }) {
  return (
    <SvgIcon {...props} viewBox="0 0 21 20" fill="none">
      <path
        d="M16.4302 7.51151L10.0462 14.1172L3.66216 7.51151C3.48873 7.33146 3.39393 7.0899 3.3986 6.83996C3.40328 6.59001 3.50705 6.35216 3.6871 6.17874C3.86714 6.00531 4.10871 5.9105 4.35865 5.91518C4.60859 5.91985 4.84644 6.02363 5.01987 6.20367L10.0462 11.4073L15.078 6.20367C15.2514 6.02363 15.4893 5.91985 15.7392 5.91518C15.9892 5.9105 16.2307 6.00531 16.4108 6.17874C16.5908 6.35216 16.6946 6.59001 16.6993 6.83996C16.704 7.0899 16.6092 7.33146 16.4357 7.51151H16.4302Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
