import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles(
  {
    root: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "34px",
      lineHeight: "51px",
      color: "#000000",
    },
  },
  { name: "Header" }
);

export default function Header({ className, ...props }) {
  const classes = useStyles();
  return <div className={clsx(classes.root, className)} {...props} />;
}

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
