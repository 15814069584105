import { SvgIcon } from "@material-ui/core";

export default function TimeIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 16" fill="none">
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08987 0C3.61979 0 0 3.584 0 8C0 12.416 3.61979 16 8.08987 16C12.5681 16 16.1959 12.416 16.1959 8C16.1959 3.584 12.5681 0 8.08987 0ZM8.09557 14.4001C4.51626 14.4001 1.61719 11.5361 1.61719 8.0001C1.61719 4.4641 4.51626 1.6001 8.09557 1.6001C11.6749 1.6001 14.5739 4.4641 14.5739 8.0001C14.5739 11.5361 11.6749 14.4001 8.09557 14.4001ZM7.86912 4.00005H7.91771C8.24163 4.00005 8.50076 4.25605 8.50076 4.57605V8.20805L11.6347 10.0481C11.9181 10.2081 12.0072 10.5681 11.8371 10.8401C11.6752 11.1121 11.3189 11.1921 11.0435 11.0321L7.68287 9.04005C7.43183 8.89605 7.28607 8.63205 7.28607 8.35205V4.57605C7.28607 4.25605 7.5452 4.00005 7.86912 4.00005Z"
        fill="#191847"
      />
    </SvgIcon>
  );
}
