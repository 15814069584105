import { Box, Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { Fragment } from "react";
import Bar from "../components/Bar";

const useStyles = makeStyles(
  {
    title: {
      textAlign: "center",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "30px",
      color: "#000000",
    },
    description: {
      textAlign: "center",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0.02em",
      color: "#191847",
      opacity: 0.5,
    },
  },
  { name: "ResultPanelComparisonBars" }
);

export default function ResultPanelComparisonBars(props) {
  const { my, others } = props;
  const classes = useStyles();
  return (
    <Fragment>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item>
          <Box marginTop="18px">
            <Bar value={my.value} color="primary" />
          </Box>
          <Box>
            <Box className={classes.title}>{my.title}</Box>
            <Box className={classes.description}>{my.description}</Box>
          </Box>
        </Grid>
        <Grid item>
          <Box marginTop="18px">
            <Bar value={others.value} />
          </Box>
          <Box>
            <Box className={classes.title}>{others.title}</Box>
            <Box className={classes.description}>{others.description}</Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

ResultPanelComparisonBars.propTypes = {
  my: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  others: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};
