import { Box, createStyles, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: ({ color }) =>
          color === "default" ? "#C2C3D3" : "#2B44FF",
        position: "absolute",
        height: 6,
        width: 6,
        borderRadius: 3,
        top: 1,
      },
    }),
  { name: "BProgressBarMark" }
);

export default function ProgressBarMark({ color = "primary" }) {
  const classes = useStyles({ color });
  const marksLength = 4;
  const marksPositions = [...Array(marksLength)].map((_, index) => {
    return ((index + 1) * 100) / (marksLength + 1);
  });
  return (
    <Fragment>
      {marksPositions.map((position, index) => (
        <Box
          key={index}
          component="span"
          classes={classes}
          left={`calc(${position}% - 6px)`}
        />
      ))}
    </Fragment>
  );
}

ProgressBarMark.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "default"]),
};
