import {
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import { refType } from "@material-ui/utils";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 12,
      },
      colorPrimary: {
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&$checked": {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
      checked: {},
    }),
  { name: "BCheckbox" }
);

export default function CheckboxField({ label, ...props }) {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={<Checkbox classes={classes} {...props} />}
      label={label}
    />
  );
}

CheckboxField.propTypes = {
  /**
   * If `true`, the component is checked.
   */
  checked: PropTypes.bool,

  /**
   * The icon to display when the component is checked.
   */
  checkedIcon: PropTypes.node,

  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object,

  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf(["default", "primary", "secondary"]),

  /**
   * If `true`, the checkbox will be disabled.
   */
  disabled: PropTypes.bool,

  /**
   * If `true`, the ripple effect will be disabled.
   */
  disableRipple: PropTypes.bool,

  /**
   * The icon to display when the component is unchecked.
   */
  icon: PropTypes.node,

  /**
   * The id of the `input` element.
   */
  id: PropTypes.string,

  /**
   * If `true`, the component appears indeterminate.
   * This does not set the native input element to indeterminate due
   * to inconsistent behavior across browsers.
   * However, we set a `data-indeterminate` attribute on the input.
   */
  indeterminate: PropTypes.bool,

  /**
   * The icon to display when the component is indeterminate.
   */
  indeterminateIcon: PropTypes.node,

  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes) applied to the `input` element.
   */
  inputProps: PropTypes.object,

  /**
   * Pass a ref to the `input` element.
   */
  inputRef: refType,

  /**
   * Callback fired when the state is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new checked state by accessing `event.target.checked` (boolean).
   */
  onChange: PropTypes.func,

  /**
   * If `true`, the `input` element will be required.
   */
  required: PropTypes.bool,

  /**
   * The size of the checkbox.
   * `small` is equivalent to the dense checkbox styling.
   */
  size: PropTypes.oneOf(["medium", "small"]),

  /**
   * The value of the component. The DOM API casts this to a string.
   * The browser uses "on" as the default value.
   */
  value: PropTypes.any,

  /**
   * The label to show next to the Checkbox
   */
  label: PropTypes.node,
};
