import { Box, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Fragment } from "react";
import SpeedMeter from "../components/SpeedMeter";

const useStyles = makeStyles(
  {
    title: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "34px",
      lineHeight: "51px",
      textAlign: "center",
      color: "#2B44FF",
    },
  },
  { name: "ResultPanelGeneralSpeedMeter" }
);

export default function ResultPanelGeneralSpeedMeter(props) {
  const { value, name, description } = props;
  const classes = useStyles();
  return (
    <Fragment>
      <Box width="274px" margin="auto">
        <SpeedMeter currentStep={value-1} />
      </Box>
      <Box marginBottom="16px" className={classes.title}>
        {name}
      </Box>
    </Fragment>
  );
}

ResultPanelGeneralSpeedMeter.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
};
