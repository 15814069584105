import React, {useState, useContext, useEffect} from 'react';
import {ContextGlobal} from './../context/globalContext';
import axios from 'axios';
import { Formik, useFormik } from 'formik';
import { Box, Button, Divider, Grid } from "@material-ui/core";
import TextField from "../lib/components/TextField";
import FormHelperText from "../lib/components/FormHelperText";

import ReactJson from 'react-json-view';
import * as Yup from 'yup'; 
import {
  BrowserRouter as Router,
  useHistory,
  Link,
  useParams
} from "react-router-dom";

import './../scss/style.scss';

function RecoverPanel() {

const [msg, cambiarMsg] = useState('');
const [showPassword, setShowPassword] = useState(false);
let history = useHistory();
let { email } = useParams();
let { token } = useParams();

const {validate, clean, format }  = require('rut.js');
const valSchema = Yup.object().shape({
  password: Yup.string()
    .required("Debe ingresar una Contraseña")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Su Contraseña debe ser de 8 o mas caracteres y combinar mayusculas/minusculas, numeros y un caracter Especial"
    ),
  password_confirmation: Yup.string()
    .required("Debe reingresar la Contraseña para confirmar")
    .oneOf(
      [Yup.ref("password"), null],
      "La Confirmacion de Contraseña no concuerda con la Contraseña"
    )
})

const apiUrl = '/api';

const form_reg = useFormik({
  initialValues: {
    password: '',
    password_confirmation: '',
    email: email,
    token: token,
  },
  onSubmit: async (e) => {
    cambiarMsg('');
    const apiAxios = axios.create({
      baseURL: apiUrl,
        })
    const result = apiAxios.post('/password/reset',
           form_reg.values)
          .then((response) => {
            history.push('/passwordmsg');
            //console.log(response.data);
          }).catch((error) => {
            if (error.response?.data && error.response.data.status !== "0") {
              cambiarMsg(error.response.data.message);
            } else {
              cambiarMsg("Algo salio mal, por favor intente mas tarde.");
            }
            //console.log(error.response?.data);
          })
    ;
  },
  validationSchema: valSchema
});

const { touched, errors } = form_reg;
const fieldErrors = {
  password: touched.password && errors.password,
  password_confirmation: touched.password_confirm && errors.password_confirm,
};



  return (
    <div className="App" style={{width: '100%'}}>

      <div className="si-content" style={{marginTop: '5%'}}>
          <h4>
            Crea nueva contraseña
          </h4>
          <div className="si-marco">
            <div>
            <form action="" onSubmit={form_reg.handleSubmit}>
            <Grid item xs={12}>
                <TextField
                  label="NUEVA CONTRASEÑA"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  value={form_reg.values.password}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  fullWidth
                  autoComplete="new-password"
                />
              </Grid>
              <br/>              
              <Grid item xs={12}>
                <TextField
                  label="CONFIRMAR CONTRASEÑA"
                  type={showPassword ? "text" : "password"}
                  name="password_confirmation"
                  id="password_confirmation"
                  value={form_reg.values.password_confirmation}
                  onChange={form_reg.handleChange}
                  onBlur={form_reg.handleBlur}
                  fullWidth
                  autoComplete="new-password"
                />
              </Grid>
              <br/>
              <Grid item xs={12}>
              <Box style={{ marginTop: "-8px" }} marginBottom={1}>
                  {fieldErrors.password || fieldErrors.password_confirmation ? (
                    <FormHelperText error>
                      {fieldErrors.password || fieldErrors.password_confirmation}
                    </FormHelperText>
                  ) : (
                    <FormHelperText
                      style={{
                        opacity: 0.5,
                        paddingInlineStart: "24px",
                        margin: 0,
                      }}
                      component="ul"
                    >
                      <li>
                        8 caracteres mínimos combinando números, mayúsculas y
                        símbolos
                      </li>
                    </FormHelperText>
                  )}
              </Box>
              </Grid>
              <div style={{marginTop: '15px'}}>
              <button 
                type="submit" 
                name='ingresar' 
                className='btn-registro'
                disabled={!(form_reg.isValid && form_reg.dirty)}
                >Solicitar</button>
              </div>
            </form>
              <div style={{fontSize:'14px', fontWeight: '400', textAlign: 'center'}}>
              <span style={{color: 'red', fontSize: '14px'}}>{msg}</span>
              <br/>  
              </div>
          </div>
{/*           <div style={{marginTop: '50px'}}> 
          <ReactJson src={form_reg.values} collapsed='true'/>
          <ReactJson src={form_reg.errors} collapsed='true'/>
          <ReactJson src={form_reg.touched} collapsed='true'/>
          </div> */}
      </div>
    </div>
    </div>
  );
}

export default RecoverPanel;
