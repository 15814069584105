import { SvgIcon } from "@material-ui/core";

export default function ArrowDownRight(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 17"
      fill="none"
      style={{ fill: "none" }}
    >
      <path
        d="M12 12.1973L4 4.19727"
        stroke="#EB3C46"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33398 12.1973H12.0007V5.5306"
        stroke="#EB3C46"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
