import { Typography, Box, Paper, makeStyles } from "@material-ui/core";
import ProgressBar from "../components/ProgressBar";
import PropTypes from "prop-types";
import Chart from "./ResultPanelMaturityInTimeDimensionChart";
import ArrowUpRight from "../icons/ArrowUpRight";
import ArrowDownRight from "../icons/ArrowDownRight";
import { useMemo } from "react";
import clsx from "clsx";

const useStyles = makeStyles(
  {
    date: {
      width: "1%",
      whiteSpace: "nowrap",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0.02em",
      color: "#425466",
      opacity: "0.6",
    },
    bar: {
      padding: "0px 8px",
    },
    tier: {
      width: "100px",
      whiteSpace: "nowrap",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      textAlign: "right",
      letterSpacing: "0.02em",
      "&$colorIncrease": {
        color: "#2B44FF",
      },
      "&$colorDecrease": {
        color: "#FF0000",
      },
    },
    direction: {
      height: "26px",
      width: "16px",
      whiteSpace: "nowrap",
      "$colorIncrease &": {
        color: "#2B44FF",
      },
      "$colorDecrease &": {
        color: "#FF0000",
      },
    },
    colorIncrease: {},
    colorDecrease: {},
  },
  { name: "ResultPanelMaturityInTimeDimension" }
);

export default function ResultPanelMaturityInTimeDimension(props) {
  const { name, data } = props;
  const classes = useStyles();
  const graphValues = useMemo(() => {
    return data.map(({ graphValue }) => graphValue);
  }, [data]);
  return (
    <Paper>
      <Box padding="26px">
        <Box marginBottom="20px">
          <Typography variant="h2">{name}</Typography>
        </Box>
        <Box marginBottom="20px">
          <Chart data={graphValues} />
        </Box>
        <table>
          <tbody>
            {data.map((item, index) => {
              let colorClass = undefined;
              if (item.direction === "inc") {
                colorClass = classes.colorIncrease;
              } else if (item.direction === "dec") {
                colorClass = classes.colorDecrease;
              }
              let directionIcon = null;
              if (item.direction === "inc") {
                directionIcon = <ArrowUpRight />;
              } else if (item.direction === "dec") {
                directionIcon = <ArrowDownRight />;
              }
              return (
                <tr key={index}>
                  <td className={classes.date}>{item.date}</td>
                  <td className={classes.bar}>
                    <ProgressBar color="primary" value={item.value} />
                  </td>
                  <td className={clsx(classes.tier, colorClass)}>
                    {item.tier}
                  </td>
                  <td className={classes.direction}>
                    <Box component="span" display="flex">
                      {directionIcon}
                    </Box>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Paper>
  );
}

ResultPanelMaturityInTimeDimension.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      /** Valor en el gráfico. De 0 a 4 */
      graphValue: PropTypes.number.isRequired,
      /** Valor en el ProgressBar. De 0 a 100 */
      value: PropTypes.number.isRequired,
      tier: PropTypes.string.isRequired,
      direction: PropTypes.oneOf(["inc", "dec"]),
    })
  ).isRequired,
};
