import {
  createStyles,
  makeStyles,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@material-ui/core";
import CircleIcon from "../icons/Circle";
import CircleCheckIcon from "../icons/CircleCheck";
import PropTypes from "prop-types";

const useListMenuItemStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: "15px",
      color: "#0A2540",
      backgroundColor: "white",
      border: "1px solid rgba(0, 0, 0, 0.15)",
      boxSizing: "border-box",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.12)",
      borderRadius: theme.shape.borderRadius,
      fontSize: 14,
      height: "100%",
      "&$selected": {
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: "white",
        boxShadow: "none",
      },
      "&$selected:hover": {
        background: theme.palette.primary.dark,
      },
      "&$disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
        borderColor: "rgba(10, 37, 64, 0.02)",
      },
      "&:not(:last-child)": {
        marginBottom: 16,
      },
      "& .MuiListItemIcon-root": {
        minWidth: 40,
      },
      "& .MuiListItemText-root": {
        margin: 0,
      },
      "&$disabled .MuiListItemIcon-root": {
        opacity: 0.8,
      },
      "&$disabled .MuiListItemText-root": {
        opacity: 0.8,
      },
    },
    selected: {},
    disabled: {},
  })
);

const useListItemTextStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiListItemText-primary": {
        fontSize: 14,
      },
    },
  })
);

function splitArray(options, columns = 2) {
  const optionsPerColumn = Math.ceil(options.length / columns);
  const optionsInColumns = [];
  for (let index = 0; index < optionsPerColumn; index++) {
    optionsInColumns.push([]);
  }
  for (let index = 0; index < options.length; index++) {
    const element = options[index];
    const row = index % optionsPerColumn;
    optionsInColumns[row].push(element);
  }

  return optionsInColumns;
}

export default function SelectBox(props) {
  const menuClasses = useListMenuItemStyles();
  const menuItemTextClasses = useListItemTextStyles();
  return (
    <List>
      <Grid container spacing={2}>
        {splitArray(props.options).map((optionsInColumn) =>
          optionsInColumn.map((option) => {
            const selected = props.value === option.value;
            const disabled = option.disabled;
            let icon = null;
            if (disabled) {
              icon = <CircleIcon style={{ color: "#191847" }} />;
            } else if (selected) {
              icon = <CircleCheckIcon />;
            } else {
              icon = <CircleIcon style={{ color: "transparent" }} />;
            }
            return (
              <Grid item xs={12} sm={6} key={option.value}>
                <ListItem
                  classes={menuClasses}
                  selected={selected}
                  button
                  onClick={() => {
                    props.onChange && props.onChange(option.value);
                  }}
                  disabled={disabled}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText
                    primary={option.label}
                    classes={menuItemTextClasses}
                  />
                </ListItem>
              </Grid>
            );
          })
        )}
      </Grid>
    </List>
  );
}

SelectBox.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  value: PropTypes.any,
  /**
   * Callback function fired when a menu item is selected.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (any).
   * @param {object} [child] The react element that was selected when `native` is `false` (default).
   */
  onChange: PropTypes.func,
};
