import React, {useState, useContext} from 'react';
import {ContextGlobal} from './../context/globalContext';
import axios from 'axios';
import { Formik, useFormik } from 'formik';
import ReactJson from 'react-json-view';
import * as Yup from 'yup'; 
import {
  BrowserRouter as Router,
  useHistory,
  Link
} from "react-router-dom";

import './../scss/style.scss';
import mail from "./../img/logo_eml.svg";

function RecoverPanel() {

const [msg, cambiarMsg] = useState('');
let history = useHistory();

const {validate, clean, format }  = require('rut.js');
const valSchema = Yup.object().shape({
  log_rut:    Yup.string()
                  .required("Debes ingresar tu RUT/RUN")
                  .test("rut-valido", "Ingrese un RUT/RUN válido", value => validate(value)) 
})

const apiUrl = '/api';

const form_reg = useFormik({
  initialValues: {
    log_rut: ''  
  },
  onSubmit: async (e) => {
    cambiarMsg('');
    const apiAxios = axios.create({
      baseURL: apiUrl,
        })
    const result = apiAxios.post('/email/byrut', { 
            rut: `${form_reg.values.log_rut}`
        })
          .then((response) => {
            sessionStorage.setItem('u_eml', response.data.email);
            sessionStorage.setItem('u_rut', form_reg.values.log_rut);
            history.push('/recoverdnimsg');
          }).catch((error) => {
            if(error.response.data.status=='08') { 
              cambiarMsg('El RUT no esta registrado.');
            } else {
              cambiarMsg('Algo salio mal, por favor intente mas tarde');
            }
          })
    ;
  },
  validationSchema: valSchema
});

  return (
    <div className="App" style={{width: '100%'}}>

      <div className="si-content" style={{marginTop: '10%'}}>
          <h4>
          Recupera tu correo electrónico
          </h4>
          <div className="si-marco">
            <form action="" onSubmit={form_reg.handleSubmit}>
            <div>
              <label className="lbl-reg" htmlFor="log_rut">RUT</label>
              <input 
                type='text'
                name="log_rut"  
                id="log_rut" 
                className="inp-log"
                value={form_reg.values.log_rut}
                onChange={form_reg.handleChange}
                onBlur={(e) => {
                  form_reg.setFieldValue('log_rut', format(form_reg.values.log_rut))
                            }}
              />
              <div className='f-error'>{form_reg.errors.log_rut}</div>
              </div>
              <div style={{marginTop: '15px'}}>
              <button 
                type="submit" 
                name='ingresar' 
                className='btn-registro' 
                disabled={!(form_reg.isValid && form_reg.dirty)}
                >Solicitar</button>
              </div>
            </form>
              <div style={{fontSize:'14px', fontWeight: '400', textAlign: 'center'}}>
              <span style={{color: 'red', fontSize: '14px'}}>{msg}</span>
              <br/><br/>  
              Ya recuerdo mi <Link to="/">contraseña</Link>.
              </div>
          </div>
      {/* <div style={{marginTop: '50px'}}> 
            <ReactJson src={form_reg.values} collapsed='true'/>
            <ReactJson src={form_reg.errors} collapsed='true'/>
            <ReactJson src={form_reg.touched} collapsed='true'/>
          </div> */}
      </div>
    </div>
  );
}

export default RecoverPanel;
