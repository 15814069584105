import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  indicator: {
    height: 6,
    /* UI Properties */
    backgroundColor: "#CA3E47",
  },
});

const useTabStyles = makeStyles({
  root: {
    opacity: 1,
    fontSize: "1rem",
    textTransform: "none",
    "&$selected": {
      fontWeight: "bold",
    },
  },
  selected: {},
});

// TODO: cambiar el Tab activo según la página / url / link
export function HeaderTabs() {
  const classes = useStyles();
  const tabClasses = useTabStyles();
  const [value, setValue] = React.useState(1);
  let history = useHistory();

  const handleChange = (event, newValue) => {
    var link = links[newValue];
    if (link?.link!=null) {
      link.link();
    }
    if (newValue!=1 && newValue!=2) {
      if (value!=1 && value!=2 ) {
        newValue=1;
      } else {
        newValue=value;
      }
    }
    setValue(newValue);
  };

  const links = [
    {"label":"Digitaliza tu Pyme","link":()=>{
      setValue(1);
      window.open("https://digitalizatupyme.cl/", '_blank', 'noopener,noreferrer');
    }},
    {"label":"Chequeo Digital","link":()=>{history.push("/");}},
    {"label":"Capacitaciones","link":()=>{window.location=("/agendachequeo");}},
    {"label":"Próximo Paso","link":()=>{
      window.open("https://pymesenlinea.cl/alianza/", '_blank', 'noopener,noreferrer');
    }}
  ];

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="simple tabs example"
      classes={{
        indicator: classes.indicator,
      }}
    >
      {links.map((link, index) => {
        return <Tab label={link.label} classes={tabClasses} {...a11yProps(index)} />;
      })}
    </Tabs>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
