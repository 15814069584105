import { Box } from "@material-ui/core";
import Caption from "../components/Caption";
import SelectBox from "../components/SelectBox";

export default function QuestionChoice(props) {
  const { label, value, onChange, options } = props;
  return (
    <Box paddingTop="16px" paddingBottom="16px">
      <Box marginBottom="8px">
        <Caption>{label}</Caption>
      </Box>
      <SelectBox value={value} onChange={onChange} options={options} />
    </Box>
  );
}
