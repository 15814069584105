import { SvgIcon } from "@material-ui/core";

export default function CheckboxCheckedIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect width="20" height="20" rx="4" fill="#2b44ff" x="2" y="2" />
      <path
        d="m 7.892865,12.714299 2.5,2.5 5.71427,-6.4285297"
        stroke="#ffffff"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
