import { Slider, withStyles } from "@material-ui/core";

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    height: 8,
    width: "100%",
    boxSizing: "content-box",
    padding: "21px 0",
  },
  rail: {
    display: "block",
    position: "absolute",
    width: "100%",
    height: 8,
    borderRadius: 100,
    backgroundColor: "#F0F0F0",
  },
  /* Styles applied to the track element. */
  track: {
    display: "block",
    position: "absolute",
    height: 8,
    borderRadius: 100,
    backgroundColor: "currentColor",
  },
  /* Styles applied to the thumb element. */
  thumb: {
    position: "absolute",
    width: 20,
    height: 20,
    marginLeft: -10,
    marginTop: -6,
    border: "solid 3px white",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    boxSizing: "border-box",
    borderRadius: "50%",
    outline: 0,
    backgroundColor: "currentColor",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create(["box-shadow"], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  mark: {
    display: "none",
  },
  /* Styles applied to the mark label element. */
  markLabel: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",

    color: "#000000",

    position: "absolute",
    top: 53,
    transform: "translateX(-50%)",
    whiteSpace: "nowrap",
  },
});

export default withStyles(styles, { name: "MuiSlider" })(Slider);
