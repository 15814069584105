import { Box, Container, Grid } from "@material-ui/core";
import React, {useState, useContext, useEffect} from 'react';
import {
  BrowserRouter as Router,
  useHistory,
  Link,
  useParams
} from "react-router-dom";
import axios from "axios";
import {ContextGlobal} from './../context/globalContext';
import QuestionChoice from "../lib/question/QuestionChoice";
import QuestionPanel from "../lib/question/QuestionPanel";
import QuestionSidePanel from "../lib/question/QuestionSidePanel";
import QuestionSlider from "../lib/question/QuestionSlider";

export default function QuestionLayout() {
  const {user} = useContext(ContextGlobal);
  const {survey, getSurveyID} = useContext(ContextGlobal);
  let history = useHistory();
  const [quest, setQuest] = useState(); 
  const [forceLoad, setForceLoad] = useState(0); 
  const [dis, setDis] = useState(true); 
  let { sid, tid } = useParams();

  const decimalToHexString = (number) =>
    {
      if (number < 0) { number = 0xFFFFFFFF + number + 1; }
      return number.toString(16).toUpperCase();
    }  


  if(!survey) { getSurveyID(sid); }

  const apiUrl = "/api";
  const getQuest = async () => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
    });
    const result = await apiAxios
      .get("/evaluacion/"+sid+"/etapa/"+tid+"/siguientepregunta")
      .then((response) => {
        setQuest(response.data.data);
        setValue(-1);
        setDis(true);
      })
      .catch((error) => {
        //console.log(error.response?.data);
      });
  }  

  const putAnsw = async (resp) => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
    });
    // Para los componente las respuestas parten desde el cero, para el sistema desde 1
    const result = await apiAxios
      .post("/evaluacion/"+sid+"/pregunta/"+quest.id+"/respuesta/"+(parseInt(resp)+1))
      .then((response) => {
        if (response.data.data.finetapa === false) {
          setForceLoad(forceLoad+1);
          getSurveyID(sid); 
          // setValue(0);
        } else if (response.data.data.finetapa === true) {
          getSurveyID(sid); 
          setDis(true);
          history.push('/mainsurvey/'+decimalToHexString(sid));
        } else {
          getSurveyID(sid); 
          setDis(true);
          history.push('/mainsurvey/'+decimalToHexString(sid));
        }
      })
      .catch((error) => {
        //console.log(error.response?.data);
        history.push('/');
      });
  }  

  useEffect( () => {
    getQuest();
  }, [forceLoad])

  const stage = survey ? survey.estadoetapa.find(stage => stage.id == tid) : {};


  const type = "slider";
  const label = "Seleccione una alternativa";
  const [value, setValue] = useState(-1);

  //console.log("U:", user);
  //console.log("S:", survey);
  //console.log("Q:", quest);
 
  return (
    <Container>
      
      <Grid container spacing={2}>
        <Grid item xs={12} md="auto">
          <Box padding="24px">
            <Box width={300}>
              <QuestionSidePanel
                image={
                  <img
                    src={stage.imgpath}
                    alt={stage.nombre}
                  />
                }
                caption={stage.nombre}
                companyName={survey && survey.empresa.nombre}
                name={stage.nombre}
                description={stage.descripcion}
                completition={stage.avance}
                completitionHint={"Quedan "+ stage.preguntassincontestar +" preguntas"}
                onExit={() => {
                  history.push('/mainsurvey/'+decimalToHexString(sid));
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md>
          <Box padding="24px">
            <QuestionPanel
              title={ quest && quest.enunciado }
              subtitle={ quest && quest.ejemplo }
              onContinue={() => {
                putAnsw(value);
              }}
              ondisabled={ dis }
            >
              { quest && quest.tipo == "1" && (
                <QuestionChoice
                  label={label}
                  value={value}
                  onChange={(v) => {
                    setValue(v);
                    setDis(false);
                  }}
                  options={
                    Object.entries(quest).filter((e)=>/^(alternativa[0-9])$/.test(e[0]) && e[1]!="" && e[1]!=null).map((arr) => ({ value:parseInt(arr[0].replace("alternativa",""))-1, label: arr[1]}) )
                  }
                />
              )}
              { quest && quest.tipo == "2" && (
                <QuestionSlider
                  value={value}
                  onChange={(v) => {
                    setValue(v);
                    setDis(false);
                  }}
                  options={["1", "2", "3", "4", "5"]}
                  descriptions={quest.alternativas}
                  hint="Deslice de 1 a 5 para completar su respuesta"
                />
              )}
            </QuestionPanel>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
