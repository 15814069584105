import { SvgIcon } from "@material-ui/core";

export default function ArrowUpRight(props) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 17"
      fill="none"
      style={{ fill: "none" }}
    >
      <path
        d="M12 4.19727L4 12.1973"
        stroke="#2B44FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33398 4.19727H12.0007V10.8639"
        stroke="#2B44FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
