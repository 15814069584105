import React, {useState} from 'react';
import axios from 'axios';
import {
    BrowserRouter as Router,
    useHistory,
    Link,
    useParams
  } from "react-router-dom";

const ContextGlobal = React.createContext();
const apiUrl = '/api';

const ProviderGlobal = ({children}) => {
    // const history = useHistory();

    const [user, setUser] = useState({
        nombre:''
    });
    const [survey, setSurvey] = useState();
    const [dash, setDash] = useState();
    const [vg, altVg] = useState({
        log: false,
        acces_token: '',
    });

    const getDataUser = (token) => {
        const apiAxios = axios.create({
            baseURL: apiUrl,
            headers: {Authorization: `Bearer ${token}`}
        })
        const result = apiAxios.post('/auth/me', { 
        })
          .then((response) => {
            setUser(response.data.data);
            sessionStorage.setItem('u_nom', response.data.data.nombre);
            sessionStorage.setItem('u_ape', response.data.data.apellido);
            sessionStorage.setItem('u_eml', response.data.data.mail);
            sessionStorage.setItem('u_rut', response.data.data.rut);
            sessionStorage.setItem('cureg', response.data.data.requiere_registro_claveunica);
        }).catch((error) => {
            sessionStorage.clear();
            //console.log("session cerrada");
            logOff();
            window.location.href=window.location.origin;
          });
    }

    const getSurveyID = (enc) => {
        let apiAxios = axios.create({
          baseURL: apiUrl,
          headers: {Authorization: `Bearer ${sessionStorage.getItem('access_token')}`}
        });
        const result = apiAxios
          .get("/evaluacion/"+enc)
          .then((response) => {
            setSurvey(response.data.data);
          })
          .catch((error) => {
            //console.log(error.response?.data);
          });
      }

    const logOff = () => {
      altVg({log: false, acces_token: ''});
      sessionStorage.clear();
      setUser({nombre:''})
      setSurvey(null);
      setDash(null);
      }

    const authSes = (tk) => {
        let newvg = {log: true, acces_token: `${tk}`}; 
        altVg(newvg)
        getDataUser(tk);
        window.setInterval(function(){ logOff(); window.location.href="/" }, 120*60*1000);
    }
    
    return(
        <ContextGlobal.Provider value={{vg, user, setUser, survey, dash, setDash, logOff, authSes, getSurveyID}}>
            {children}
        </ContextGlobal.Provider>)
        ;
}
 
export {ContextGlobal, ProviderGlobal};