import React from 'react';
import ReactDOM from 'react-dom';
import './scss/style.scss';
import App from './App';
import {ProviderGlobal} from './context/globalContext';

ReactDOM.render(
  <React.StrictMode>
    <ProviderGlobal>
      <App />
    </ProviderGlobal>  
  </React.StrictMode>,
  document.getElementById('root')
);

