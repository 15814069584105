import { Box, Button } from "@material-ui/core";
import TypographySmall from "../components/TypographySmall";
import SelectTextField from "../components/SelectTextField";
import { useFormik } from "formik";
import PropTypes from "prop-types";

export default function ResultPanelComparisonForm(props) {
  const { rubros, tamanos, regiones, onSubmit } = props;
  const { values, handleChange, handleBlur, handleSubmit } = useFormik({
    onSubmit,
    initialValues: {
      rubro: "",
      tamano: "",
      region: "",
    },
  });
  return (
    <Box padding="16px">
      <Box marginBottom="16px">
        <TypographySmall>Selecciona los filtros para comparar:</TypographySmall>
      </Box>
      <form action="" onSubmit={handleSubmit}>
        <Box marginBottom="16px">
          <SelectTextField
            label="Rubro"
            name="rubro"
            id="rubro"
            value={values.rubro}
            onChange={handleChange}
            onBlur={handleBlur}
            options={rubros}
            fullWidth
            style={{maxWidth:"230px"}}
          />
        </Box>
        <Box marginBottom="16px">
          <SelectTextField
            label="Tamaño"
            name="tamano"
            id="tamano"
            value={values.tamano}
            onChange={handleChange}
            onBlur={handleBlur}
            options={tamanos}
            fullWidth
            style={{maxWidth:"230px"}}
          />
        </Box>
        <Box marginBottom="16px">
          <SelectTextField
            label="Región"
            name="region"
            id="region"
            value={values.region}
            onChange={handleChange}
            onBlur={handleBlur}
            options={regiones}
            fullWidth
            style={{maxWidth:"230px"}}
          />
        </Box>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          Filtrar
        </Button>
      </form>
    </Box>
  );
}

ResultPanelComparisonForm.propTypes = {
  rubros: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  tamanos: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  regiones: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
