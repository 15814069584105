import { Grid } from "@material-ui/core";
import { Fragment } from "react";
import PropTypes from "prop-types";
import OfferResult from "./OfferResult";

export default function OfferResults(props) {
  const { onLoadMore, results = [] } = props;
  return (
    <Fragment>
      <Grid container spacing={2}>
        {results.map((result, index) => (
          <Grid item xs={12} md={6} key={index}>
            <OfferResult {...result} />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
}

OfferResults.propTypes = {
  onLoadMore: PropTypes.func,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      overTags: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      organizer: PropTypes.string,
      date: PropTypes.string,
      location: PropTypes.string,
      onAccessHere: PropTypes.func,
      url: PropTypes.string,
    })
  ),
};
