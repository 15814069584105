import React, {useState, useContext, useEffect} from 'react';
import {ContextGlobal} from './../context/globalContext';
import axios from "axios";
import hand from './../img/hand.svg';
import './../scss/style.scss';
import {
  BrowserRouter as Router,
  useHistory,
  Link,
  useParams
} from "react-router-dom";

import { Box, Container, Grid } from "@material-ui/core";
import SurveyPanelFinished from "../lib/surveys/SurveyPanelFinished";
import SurveyPanelInProgress from "../lib/surveys/SurveyPanelInProgress";
import SurveyPanelToDevelop from "../lib/surveys/SurveyPanelToDevelop";
import SurveysSidePanel from "../lib/surveys/SurveysSidePanel";

function MainSurvey() {
  const {user, authSes} = useContext(ContextGlobal);
  const {survey, getSurveyID} = useContext(ContextGlobal);
  const apiUrl = "/api";
  let history = useHistory();
  let { sid } = useParams();
  

  const decimalToHexString = (number) =>
    {
      if (number < 0) { number = 0xFFFFFFFF + number + 1; }
      return number.toString(16).toUpperCase();
    }

  useEffect( () => {
    if (!survey || survey.id!=sid) {
      getSurveyID(parseInt(sid, 16));
      authSes(sessionStorage.getItem('access_token'));

    }
  }, [])

  function abrirEtapa(evaluacion_id, etapa_id) {
    return function() {
      history.push('/survey/'+evaluacion_id+'/stage/'+etapa_id);
    }
  }
  
  if (survey?.avance === 100) {
    history.push('/resultsurvey/'+decimalToHexString(survey.empresa_id)+"/1");
  }

//  console.log("s:", survey?.avance);

  const mapeoenc = (state, index) => (
    {
      image: state.imgpath,
      title: state.nombre,
      description: state.descripcion,
      numberOfQuestions: state.preguntas,
      estimatedTime: state.preguntassincontestar?(Math.round(state.preguntassincontestar/2)):1,
      onContinue: abrirEtapa(survey.id, state.id),
      completition: {
      value: state.avance,
      hint: ("Quedan "+state.preguntassincontestar+" preguntas"),
      }
    }
  );

  const filtraenc = (survey, filtro) => {
    if (!survey) { return [] } 
    return survey.estadoetapa.filter((state) => filtro === state.estado).map(mapeoenc);
  } 
 

  return (
    <>
        <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md="auto">
          <Box padding="24px">
            <Box width={300}>
              <SurveysSidePanel
                caption="Chequeo digital"
                companyName={survey && survey?.empresa?.nombre}
                subHeader={survey && ("Por: " + user?.nombre +" "+user?.apellido + " - " + survey?.cargo?.nombre)}
                completitionValue={(survey && survey?.avance)?survey.avance:0}
                details={[
                  { title: "RUT", description: (survey && survey?.empresa?.rut) },
                  { title: "Tamaño Empresa", description: (survey && survey?.empresa?.empresatamano?.nombre) },
                  { title: "Empleados", description: (survey && survey?.empresa?.empresaempleado?.nombre) },
                  {
                    title: "Rubro",
                    description: (survey && survey?.empresa?.rubro?.nombre),
                  },
                ]}
                onClick={() => {
                  history.push('/');
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md>
          <Box marginBottom="16px">
            <SurveyPanelInProgress surveys={filtraenc(survey, "ABIERTO")} />
          </Box>
          <Box marginBottom="16px">
            <SurveyPanelToDevelop surveys={filtraenc(survey, "PENDIENTE")} />
          </Box>
          <Box marginBottom="16px">
            <SurveyPanelFinished surveys={filtraenc(survey, "COMPLETADO")} />
          </Box>
        </Grid>
      </Grid>
    </Container>
    </>
  );
}

export default MainSurvey;
