import React, { useContext, useState } from "react";
import {ContextGlobal} from './../context/globalContext';
import { Box, Container, Grid, Button } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import claveuni from "./../img/logo_claveunica.png";
import registro from "./../img/logo_reg.svg";
import people from './../img/people.svg';
import "./../scss/style.scss";

function RegisterPanel() {
  const [log_eml, cambiarUsuario] = useState("");
  const [log_clave, cambiarClave] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    alert("Enviar Formulario");
  };

  return (


<Container>
  <Grid container spacing={2} style={{marginTop:"10px"}}>
    <Grid item xs={12} md={6} style={{padding:"50px"}}>
          <div style={{ fontSize: "42px", fontWeight: "600", color: "black" }}>
            Chequeo digital
          </div>
          <div style={{textAlign:"center"}}>
            <img src={people} alt="Chequeo digital"/>
          </div>
          <div style={{ fontSize: "24px", fontWeight: "500"}}>
            Conoce el nivel de madurez digital de tu pyme 
          </div>
          <div style={{ marginTop:"10px"}}>
          El Chequeo Digital es un test en línea que te permite conocer el nivel
          de madurez digital de tu pyme y abarca, a través de diversas
          preguntas, siete aspectos del negocio.
          </div>
    </Grid>
    <Grid item xs={12} md={6} style={{padding:"50px"}}>

      <div className="si-marco" style={{maxWidth:"470px"}}>
          <div className="m-title">Crea tu cuenta</div>
          <div className="m-bajada" style={{marginTop: "10px", marginBottom: "20px"}}>
            Selecciona una de las opciones para continuar
          </div>

          <a href="/api/auth/initclaveunica">
          <button name="claveunica" className="btn-clave-unica">
            <img src={claveuni}  style={{height:"30px", marginTop:"15px"}} />
          </button>
          </a>
          <br />
          <br />
          <Link to="/registerform">
            <button name="claveunica" className="btn-crear-registro">
              <div style={{ display: "flex", marginTop: "5px"}}>
                <div style={{ width: "35%", textAlign: "right", }}>
                  <img src={registro} style={{ width:"35px", marginTop: "5px" }} />
                </div>
                <div
                  style={{
                    width: "65%",
                    textAlign: "left",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: "500",
                    padding: "15px 10px",
                    fontSize: "18px"
                  }}
                >Registro manual</div>
              </div>
            </button>
          </Link>
      </div>
          <br />
          <br />
      <div className="si-marco" style={{maxWidth:"470px", height: "64px", fontSize:"18px", lineHeight:"24px", textAlign:"center"}}>
        Ya tienes cuenta <Link to="/login">ingresa aquí</Link>
      </div>
    </Grid>
  </Grid>
</Container>
  );
}

export default RegisterPanel;
