import { Box, Button, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Divider from "../components/Divider";

export default function QuestionPanel(props) {
  const { title, subtitle, onBack, onContinue, ondisabled } = props;

  return (
    <Box>
      <Box marginBottom="8px">
        <Typography variant="h1">{title}</Typography>
      </Box>
      <Box marginBottom="16px">
        <Typography variant="body1">{subtitle}</Typography>
      </Box>
      <Box marginBottom="16px">
        <Divider />
      </Box>
      <Box marginBottom="16px">{props.children}</Box>
      <Box textAlign="right" paddingTop="10px" paddingBottom="10px">
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            {onBack ? (
              <Button variant="outlined" onClick={onBack}>
                Regresar
              </Button>
            ) : null}
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={onContinue} disabled={ondisabled}>
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

QuestionPanel.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
};
