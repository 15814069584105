import { Box, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import SurveyCardCondensed from "./SurveyCardCondensed";

export default function SurveyPanelToDevelop(props) {
  const { surveys } = props;
  return (
    <Box>
      {surveys.length !== 0 && 
      (
      <>
      <Box marginBottom="16px">
        <Typography variant="h1">Preguntas por responder </Typography>
      </Box>
      <Grid container spacing={2}>
        {surveys.map((survey, index) => (
          <Grid item xs={12} key={index}>
            <SurveyCardCondensed {...survey} />
          </Grid>
        ))}
      </Grid>
    </>)}
    </Box>
    
  );
}

SurveyPanelToDevelop.propTypes = {
  surveys: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.node.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};
