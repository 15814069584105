import { Box, Grid, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import SurveyCard from "./SurveyCard";

export default function SurveyPanelInProgress(props) {
  const { surveys } = props;
  return (
    <>    
      { surveys.length > 0 && (
      <Box>
      <Box marginBottom="16px">
        <Typography variant="h1">Preguntas en curso</Typography>
      </Box>
      <Grid container spacing={2}>
        {surveys.map((survey, index) => (
          <Grid item xs={12} key={index}>
            <SurveyCard {...survey} />
          </Grid>
        ))}
      </Grid>
      </Box>
      )}
    </>
  );
}

SurveyPanelInProgress.propTypes = {
  surveys: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.node.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      numberOfQuestions: PropTypes.string.isRequired,
      estimatedTime: PropTypes.string.isRequired,
      onContinue: PropTypes.func.isRequired,
      completition: PropTypes.shape({
        value: PropTypes.number,
        hint: PropTypes.string,
      }),
    })
  ).isRequired,
};
