import { Button, withStyles } from "@material-ui/core";

export const styles = (theme) => ({
  /* Styles applied to the root element. */
  root: {
    borderRadius: "67px",
  },
  label: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "157.33%",
    "&$outlinedPrimary": {
      color: "#2B44FF",
    },
  },
  endIcon: {
    marginLeft: "18px",
  },
  outlinedPrimary: {},
});

export default withStyles(styles, { name: "ButtonRounded" })(Button);
