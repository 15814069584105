import { Box, Container, Grid } from "@material-ui/core";
import DashboardPanelSurveys from "./DashboardPanelSurveys";
import DashboardPanelForm from "./DashboardPanelForm";
import DashboardSidePanel from "./DashboardSidePanel";
import DashboardCard from "./DashboardCard";
import { useState } from "react";
import AlertSuccess from "../components/AlertSuccess";

export default function DashboardLayout() {
  const [state, setState] = useState("surveys");
  const [success, setSuccess] = useState(false);
  const surveys = [
    {
      progress: 45,
      name: "Tienda de ropa Lalala",
      rut: "RUT 56.123.456-7",
      size: "Mediana",
      region: "Metropolitana",
    },
    {
      progress: 100,
      name: "Panadería un manjars",
      rut: "RUT 56.123.456-7",
      size: "Mediana",
      region: "Metropolitana",
    },
  ];
  return (
    <Container>
      {success && <AlertSuccess>Proceso guardado exitosamente</AlertSuccess>}
      <Grid container spacing={4}>
        <Grid item xs={12} md="auto">
          <Box padding="16px">
            <Box width={314}>
              <DashboardSidePanel
                name="Editha Fuentes"
                rut="RUT 10.991.740-0"
                email="blablabla@gmail.com"
                onEdit={() => {
                  setState("form");
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md>
          <Box padding="16px">
            {state === "surveys" && (
              <DashboardPanelSurveys
                onAdd={() => {
                  console.log("onAdd");
                }}
              >
                <Grid container spacing={2}>
                  {surveys.map((survey, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <DashboardCard {...survey} />
                    </Grid>
                  ))}
                </Grid>
              </DashboardPanelSurveys>
            )}
            {state === "form" && (
              <DashboardPanelForm
                values={{
                  email: "editha@gob.cl",
                  nombre: "Editha",
                  apellido: "Fuentes",
                  rut: "11.111.111-1",
                  fecha_nacimiento: "1988-10-06",
                  genero: "Femenino",
                  password: "",
                  password_new: "",
                }}
                onBack={() => {
                  setState("surveys");
                }}
                onSubmit={(v) => {
                  console.log("onSubmit", v);
                  setSuccess(true);
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
