import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const useStyle = makeStyles({
  completed: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.02em",
    color: "#19BF3E",
  },
});

export default function SurveyCardCondensed(props) {
  const { image, title, completed = false } = props;
  const classes = useStyle();
  return (
    <Paper>
      <Box padding="24px">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs="auto">
          <Box padding="8px">
            <img
              height="64px"
              src={image}
              alt={title}
            />
          </Box>
          </Grid>
          <Grid item xs>
            <Box style={{ opacity: 0.5 }}>
              {completed && (
                <Box className={classes.completed} marginBottom="5px">
                  Completado
                </Box>
              )}
              <Typography variant="h2">{title}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

SurveyCardCondensed.propTypes = {
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  completed: PropTypes.bool,
};
