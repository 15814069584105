import { Box, Container, Grid } from "@material-ui/core";
import { useState } from "react";
import QuestionChoice from "./QuestionChoice";
import QuestionPanel from "./QuestionPanel";
import QuestionSidePanel from "./QuestionSidePanel";
import QuestionSlider from "./QuestionSlider";

export default function QuestionLayout() {
  const type = "slider";
  const label = "Seleccione la alternativa que ipsum dolor sit amet";
  const [value, setValue] = useState(3);
  const options = [
    { label: "Si", value: true },
    { label: "No", value: false },
    { label: "Quizás", value: "hola" },
  ];
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md="auto">
          <Box padding="24px">
            <Box width={300}>
              <QuestionSidePanel
                image={
                  <img
                    src="/encuestas/cultura-y-liderazgo.svg"
                    alt="Cultura y liderazgo"
                  />
                }
                caption="Chequeo digital"
                companyName="Empresa distribuidora Nombre  y Asociados SPA"
                name="Cultura y liderazgo"
                description="Refiere a las formas en que tú y tus trabajadores se adaptan a la tecnología."
                completition={30}
                completitionHint="Quedan 9 preguntas"
                onExit={() => {
                  console.log("exit");
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md>
          <Box padding="24px">
            <QuestionPanel
              title={
                "Lorem ipsum,  ¿dolor sit amet consectetur adipiscing elit?"
              }
              subtitle={"Ej: Lorem ipsum dolor sit amet consectetur."}
              onContinue={() => {
                console.log("onContinue");
              }}
            >
              {type === "choice" && (
                <QuestionChoice
                  label={label}
                  value={value}
                  onChange={(v) => {
                    setValue(v);
                  }}
                  options={options}
                />
              )}
              {type === "slider" && (
                <QuestionSlider
                  value={value}
                  onChange={(v) => {
                    setValue(v);
                  }}
                  options={["1", "2", "3", "4", "5", "6", "7"]}
                  descriptions={["Poco", "Mas o menos", "Mucho"]}
                  hint="Deslice de 1 a 7 para completar su respuesta"
                />
              )}
            </QuestionPanel>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
