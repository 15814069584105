import {
  Box,
  Button,
  Collapse,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { Fragment, useState } from "react";
import ButtonRounded from "../components/ButtonRounded";
import Caption from "../components/Caption";
import ProgressBar from "../components/ProgressBar";
import ExpandLess from "../icons/ExpandLess";
import ExpandMore from "../icons/ExpandMore";

const useStyles = makeStyles(
  {
    root: {
      display: "flex",
      padding: "24px 0px",
      borderTop: "1px solid rgba(0,0,0,0.1)",
    },
    avatar: {
      height: 48,
      width: 48,
      marginRight: 16,
    },
    name: {
      color: "#0A2540",
    },
    buttonExpanded: {
      background: "rgba(43, 68, 255, 0.1)",
    },
  },
  { name: "ResultPanelMaturityByDimensionsItem" }
);

export default function ResultPanelMaturityByDimensionsItem(props) {
  const { image, name, tier, description, onViewOffer } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <Box className={classes.root}>
        <Box display="inline-flex" alignItems="center" flex={1}>
          <Box className={classes.avatar}>
            <img src={image} alt={name} style={{width: "40px"}} />
          </Box>
          <Typography variant="body1" className={classes.name}>
            {name}
          </Typography>
        </Box>
        <Box display="inline-flex" alignItems="center">
          <Caption color="primary">{tier.name}</Caption>
          <Box width="256px" marginLeft="16px" marginRight="16px">
            <ProgressBar color="primary" value={tier.value} />
          </Box>
          <ButtonRounded
            variant="outlined"
            color="primary"
            onClick={() => setOpen(!open)}
            endIcon={open ? <ExpandLess /> : <ExpandMore />}
            className={clsx({ [classes.buttonExpanded]: open })}
          >
            Detalle
          </ButtonRounded>
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box marginLeft="62px" marginBottom="24px">
          <Box marginBottom="10px">
            <Typography variant="body1">{description}</Typography>
          </Box>
          <Button variant="contained" color="primary" onClick={onViewOffer}>
            Ver cursos de este módulo
          </Button>
        </Box>
      </Collapse>
    </Fragment>
  );
}
