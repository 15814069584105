import React, {useState, useContext, useEffect} from 'react';
import {
  BrowserRouter as Router,
  useHistory,
  Link,
  useParams
} from "react-router-dom";
import clave from './../img/logo_clave.svg';
import './../scss/style.scss';


function MainPanel() {
  const me = 'hola';
  return (
    <>
    {sessionStorage.getItem('access_token') !== null}
    <div className="App" style={{width: '100%'}}>
    <div className="c-fullw"><img src={clave} alt="Contraseña Guardada" /></div>
        <div className="si-content" style={{textAlign:'center', fontSize: '14px'}}>
            <span style={{fontSize:'21px', fontWeight:'500'}}>Nueva contraseña guardada.</span>
            <br/><br/>
            vuelva a iniciar sesión para continuar
            <br/><br/>
            <Link to="/">
            <button type="submit" name='ingresar' className='btn-registro' style={{maxWidth: "170px"}} >Iniciar sesión</button>
            </Link>

        </div>
    </div>
    </>
  );
}

export default MainPanel;
