import React, { useState, useContext, useEffect } from "react";
import { ContextGlobal } from "./../context/globalContext";
import ReactJson from "react-json-view";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./../scss/style.scss";
import l_fast from "./../img/logoFast.png";
import l_best from "./../img/logoBancoEstado.jpg";
import l_serco from "./../img/logoSercotec.png";
import l_corfo from "./../img/Logo_Corfo.png";
import l_asech from "./../img/logoAsech.svg";
import close from "./../img/close.svg";

import { Box, Container, Grid, Button } from "@material-ui/core";
import SelectTextField from "../lib/components/SelectTextField";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import DashboardPanelSurveys from "../lib/dashboard/DashboardPanelSurveys";
import DashboardPanelForm from "../lib/dashboard/DashboardPanelForm";
import DashboardSidePanel from "../lib/dashboard/DashboardSidePanel";
import DashboardCard from "../lib/dashboard/DashboardCard";
import AlertSuccess from "../lib/components/AlertSuccess";
import CheckboxField from "../lib/components/CheckboxField";

import {
  BrowserRouter as Router,
  useHistory,
  Link,
  useParams,
} from "react-router-dom";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 650,
    height: 500,
    outline: 0,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const useStylesa = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover, &:focus, &:active": {
      border: "none",
      outline: "none",
    },
  },
  paper: {
    position: "absolute",
    width: "85%",
    height: "60%",
    maxWidth: 750,
    overflow: "auto",
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "10px",
    outline: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "&::-webkit-scrollbar": {
      width: "0.8em",
    },
    "&::-webkit-scrollbar-track": {
      background: "#eee",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#ccc",
      borderRadius: 5,
    },
  },
}));

const useStylesData = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 650,
    height: 500,
    outline: 0,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const valSchema = Yup.object().shape({
  empresatamano_id: Yup.string().when("step", {
    is: true,
    then: Yup.string().required("Este campo es Requerido (*)"),
  }),
  empresaempleado_id: Yup.string().when("step", {
    is: true,
    then: Yup.string().required("Este campo es Requerido (*)"),
  }),
  cargo_id: Yup.string().when("step", {
    is: true,
    then: Yup.string().required("Este campo es Requerido (*)"),
  }),
});

function MainPanel() {
  const { user } = useContext(ContextGlobal);
  const { dash, setDash } = useContext(ContextGlobal);
  const { authSes } = useContext(ContextGlobal);
  const { logOff } = useContext(ContextGlobal);
  const [tyc, setTyc] = useState();
  const [flgComp, setflgComp] = useState();
  const colaborador = sessionStorage.getItem("colabora");
  const [colaboradorTC, setColaboradorTC] = useState([]);
  const iniciativa = sessionStorage.getItem("colabora_ini");
  const [terminos, setTerminos] = useState([]);
  const [terminosColaborador, setTerminosColaborador] = useState([]);
  const [largo, setlargo] = useState([]);
  const [completa, setCompleta] = useState([]);

  const apiUrl = "/api";
  const classes = useStyles();
  const classesa = useStylesa();
  const [modalStyle] = React.useState(getModalStyle);
  const [modalStylea] = React.useState(getModalStyle);
  const [modalStyleData] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [open_a, setOpena] = React.useState(false);
  const [open_d, setOpenD] = React.useState(false);
  const [listatamannos, setListatammanos] = useState([]);
  const [listaemplea, setListaemplea] = useState([]);
  const [listacargos, setListacargos] = useState([]);
  const [listacompartir, setListacompartir] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenData = () => {
    setOpenD(true);
  };
  const handleCloseData = () => {
    setOpenD(false);
  };

  const handleOpenTC = (slug) => {
    return () => {
      setTerminosColaborador("");
      cargaTerminosColaborador(slug);
      setColaboradorTC(slug);
      handleOpen();
    };
  };

  const hOpen = () => {
    setOpena(true);
  };
  const hClose = () => {
    setOpena(false);
  };

  let history = useHistory();

  const decimalToHexString = (number) => {
    if (number < 0) {
      number = 0xffffffff + number + 1;
    }
    return number.toString(16).toUpperCase();
  };

  /*
  const acepto = () => {
    hClose();
    sessionStorage.setItem('aceptaTC', '0');
    aceptoTerminos();
  }

  const noacepto = () => {
    hClose();
    sessionStorage.setItem('aceptaTC', '0');
    setTyc(false);
  }
*/

  const aceptoTerminos = () => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    const result = apiAxios
      .post(
        "/terminosycondiciones/aceptartodos/" + colaborador + "/" + iniciativa
      )
      .then((response) => {
        if (response.data.data) {
          // console.log("AT:", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response?.data);
      });
  };

  const cargaTamanno = () => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    const result = apiAxios
      .get("/empresatamanos")
      .then((response) => {
        if (response.data.data) {
          let tmp = response.data.data.map((act) => ({
            value: act.id,
            label: act.nombre,
            hint: act.descripcion,
          }));
          setListatammanos(tmp);
        }
      })
      .catch((error) => {});
  };

  const cargaEmplea = () => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    const result = apiAxios
      .get("/empresacantidadempleados")
      .then((response) => {
        if (response.data.data) {
          let tmp = response.data.data.map((act) => ({
            value: act.id,
            label: act.nombre,
          }));
          setListaemplea(tmp);
        }
      })
      .catch((error) => {});
  };

  const cargaCargos = () => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    const result = apiAxios
      .get("/cargos")
      .then((response) => {
        if (response.data.data) {
          let tmp = response.data.data.map((act) => ({
            value: act.id,
            label: act.nombre,
          }));
          setListacargos(tmp);
        }
      })
      .catch((error) => {});
  };

  const cargaCompartir = () => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    const result = apiAxios
      .get("/terminosycondiciones/compartir")
      .then((response) => {
        if (response.data.data) {
          response.data.data[0].aceptado = true;
          setListacompartir(response.data.data);
          // console.log("Comp:", response.data.data);
        }
      })
      .catch((error) => {});
  };

  const aceptoCompartir = () => {
    hClose();

    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    const result = apiAxios
      .post("/terminosycondiciones/compartir", form_reg.values)
      .then((response) => {
        if (response.data.data) {
          // console.log("AT:", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response?.data);
      });
  };

  const getDash = async () => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    const result = await apiAxios
      .get("/dashboard/" + colaborador + (iniciativa ? "/" + iniciativa : ""))
      .then((response) => {
        // console.log("A:", response.data);
        setDash(response.data.data);

        if (response.data.debecompletarempresa === true) {
          cargaTamanno();
          cargaEmplea();
          cargaCargos();
          setCompleta(response.data.empresaporcompletar);
          form_reg.setFieldValue("rut", response.data.empresaporcompletar.rut);
          handleOpenData();
        } else {
          if (sessionStorage.getItem("aceptaTC") === "0") {
            setTyc(true);
          } else {
            setTyc(response.data.nohaaceptadotyccolaborador);
            if (response.data.nohaaceptadotyccolaborador === true) {
              setOpena(true);
            }
          }
        }

        //////////////////////////////////////// Compartir Informacion
        if (response.data.solicitarcompartirinformacion === true) {
          cargaCompartir();
          setflgComp(true);
          hOpen();
        } else {
          cargaCompartir();
        }

        setlargo(response.data.data.length);
      })
      .catch((error) => {
        console.log(error.response?.data);
      });
  };

  const cargaTerminos = () => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    const result = apiAxios
      .post("/terminosycondiciones/texto/" + colaborador)
      .then((response) => {
        if (response.data.data) {
          setTerminos(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response?.data);
      });
  };

  const cargaTerminosColaborador = (slug) => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    const result = apiAxios
      .post("/terminosycondiciones/texto/" + slug)
      .then((response) => {
        if (response.data.data) {
          setTerminosColaborador(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error.response?.data);
      });
  };

  const newSurvey = async (rut_pyme) => {
    let apiAxios = axios.create({
      baseURL: apiUrl,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    });
    const result = await apiAxios
      .post("/evaluacion", { rut_pyme: rut_pyme })
      .then((response) => {
        // console.log("Snew:", response.data.data);
        // console.log("ID:", response.data.data.id);
        history.push(
          "/mainsurvey/" + decimalToHexString(response.data.data.id)
        );
      })
      .catch((error) => {
        console.log(error.response?.data);
      });
  };

  const form_reg = useFormik({
    enableReinitialize: true,
    initialValues: {
      empresatamano_id: "",
      empresaempleado_id: "",
      cargo_id: "",
      rut: "",
      ...listacompartir
        ?.map((org) => {
          let obj = {};
          let obj2 = {};
          obj[org.slug] = {
            activo:
              flgComp ||
              org.aceptado ||
              ("" + colaborador).toLowerCase() === org.slug,
            iniciativas: org.iniciativas
              .map((ini) => {
                let obj2 = {};
                obj2[ini.slug] =
                  flgComp ||
                  ini.aceptado ||
                  ("" + colaborador).toLowerCase() === org.slug;
                return obj2;
              })
              .reduce((a, b) => ({ ...a, ...b }), {}),
          };
          return obj;
        })
        .reduce((a, b) => ({ ...a, ...b }), {}),
    },
    onSubmit: async (e) => {
      const apiAxios = axios.create({
        baseURL: apiUrl,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      });
      const result = apiAxios
        .post("/empresa/actualizacionhistorico", form_reg.values)
        .then((response) => {
          history.go(0);
        })
        .catch((error) => {});
    },
    validationSchema: valSchema,
  });

  const { touched, errors } = form_reg;
  const fieldErrors = {
    empresatamano_id: touched.empresatamano_id && errors.empresatamano_id,
    empresaempleado_id: touched.empresaempleado_id && errors.empresaempleado_id,
    cargo_id: touched.cargo_id && errors.cargo_id,
  };

  const handleColaborador = (slug) => {
    return () => {
      try {
        Object.keys(form_reg.values[slug].iniciativas).forEach((iniciativa) => {
          form_reg.setFieldValue(
            slug + ".iniciativas." + iniciativa,
            form_reg.values[slug].activo !== true
          );
        });
      } catch (error) {}
    };
  };

  const handleIniciativa = (inislug, slug) => {
    return () => {
      try {
        if (form_reg.values[slug].iniciativas[inislug] === false) {
          form_reg.setFieldValue(slug + ".activo", true);
        }
        Object.entries(form_reg.values[slug].iniciativas).forEach(
          ([iniciativa, estado]) => {
            if (estado === true) {
              form_reg.setFieldValue(slug + ".activo", true);
            }
          }
        );
      } catch (error) {}
    };
  };

  useEffect(() => {
    if (user.cantidadempresas < 1) {
      history.push("/registerpyme");
    }
    getDash();
    cargaTerminos();
  }, []);

  const [state, setState] = useState("surveys");
  const [success, setSuccess] = useState(false);

  console.log("D:", dash);

  /*   
  console.log("U:", user);
  console.log("TC:", tyc);
  console.log("L:", largo);
 */
  return (
    <>
      <Container>
        {success && <AlertSuccess>Proceso guardado exitosamente</AlertSuccess>}
        <Grid container spacing={4} justify="center">
          {state === "surveys" && (
            <>
              <Grid item xs={12} md={4}>
                <Box padding="16px">
                  <DashboardSidePanel
                    name={user.nombre + " " + user.apellido}
                    rut={user.rut}
                    email={user.email}
                    onEdit={() => {
                      setState("form");
                    }}
                    onShare={() => {
                      hOpen();
                    }}
                    onLogoff={() => {logOff();}}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box padding="16px">
                  <DashboardPanelSurveys
                    onAdd={() => {
                      history.push("/registerpyme");
                    }}
                  >
                    <Grid container spacing={2}>
                      {dash &&
                        dash.map((survey, index) => (
                          <Grid item xs={12} key={index}>
                            <DashboardCard
                              {...{
                                progress: survey?.ultimaevaluacion?.avance
                                  ? survey.ultimaevaluacion.avance
                                  : 0,
                                name: survey.nombre,
                                rut: survey.rut,
                                size: survey.empresatamano?.nombre,
                                region: survey.region.nombre,
                                regionId: survey.region.id,
                                id: survey?.ultimaevaluacion?.id
                                  ? survey.ultimaevaluacion.id
                                  : 0,
                                init: survey.puedeiniciarchequeo,
                                empresa_id:
                                  survey?.ultimaevaluacion?.empresa_id,
                                // TODO: Poner el valor del nivel de madurez como texto
                                maturityLevel: survey?.ultimaevaluacion?.nivel,
                                maturityLevelID: survey?.ultimaevaluacion?.nivel_id,
                              }}
                              onNewSurvey={() => {
                                newSurvey(survey.rut);
                              }}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </DashboardPanelSurveys>
                </Box>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6}>
            {state === "form" && (
              <DashboardPanelForm
                values={{
                  email: user.email,
                  nombre: user.nombre,
                  apellido: user.apellido,
                  rut: user.rut,
                  fecha_nacimiento: user.fecha_nacimiento,
                  genero: user.genero,
                  password: "",
                  password_new: "",
                }}
                logcu={user?.loggedbyclaveunica === true}
                onBack={() => {
                  setState("surveys");
                  authSes(sessionStorage.getItem("access_token"));
                }}
                onSubmit={(v) => {
                  //console.log("onSubmit", v);
                  setSuccess(true);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          open={open}
          onClose={handleClose}
        >
          <div style={modalStyle} className={classes.paper}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <span className="tct">Términos y Condiciones</span>
              </div>
              <div>
                <img src={close} onClick={handleClose} alt="Close" />
              </div>
            </div>
            <div
              style={{ width: "600px", height: "370px", overflowY: "scroll" }}
              className="tc"
              dangerouslySetInnerHTML={{
                __html: terminosColaborador?.texto_tyc,
              }}
            ></div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ width: "40%" }}>
                {/*                       <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        name="aceptar"
                        autoFocus
                        disableAutoFocus
                        style={{width: "100px", height: "45px"}}
                        onClick={handleClose}>Aceptar</Button> */}
              </div>
              {(() => {
                switch (colaboradorTC) {
                  case "bancoestado":
                    return (
                      <div style={{ textAlign: "right" }}>
                        <img
                          src={l_fast}
                          alt="FAST"
                          style={{ height: "40px", paddingRight: "25px" }}
                        />
                        <img
                          src={l_best}
                          alt="BancoEstado"
                          style={{ height: "40px" }}
                        />
                      </div>
                    );
                  case "corfo":
                    return (
                      <div style={{ textAlign: "right" }}>
                        <img
                          src={l_fast}
                          alt="FAST"
                          style={{ height: "40px", paddingRight: "25px" }}
                        />
                        <img
                          src={l_corfo}
                          alt="Corfo"
                          style={{ height: "40px" }}
                        />
                      </div>
                    );
                  case "sercotec":
                    return (
                      <div style={{ textAlign: "right" }}>
                        <img
                          src={l_fast}
                          alt="FAST"
                          style={{ height: "40px", paddingRight: "25px" }}
                        />
                        <img
                          src={l_serco}
                          alt="Sercotec"
                          style={{ height: "40px" }}
                        />
                      </div>
                    );
                  case "asech":
                    return (
                      <div style={{ textAlign: "right" }}>
                        <img
                          src={l_fast}
                          alt="FAST"
                          style={{ height: "40px", paddingRight: "25px" }}
                        />
                        <img
                          src={l_asech}
                          alt="Asech"
                          style={{ height: "40px" }}
                        />
                      </div>
                    );
                  default:
                    return (
                      <div style={{ textAlign: "right" }}>
                        <img
                          src={l_fast}
                          alt="FAST"
                          style={{ height: "40px", paddingRight: "25px" }}
                        />
                      </div>
                    );
                }
              })()}
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          disableAutoFocus
          open={open_a}
          onClose={hClose}
        >
          <div style={modalStylea} className={classesa.paper}>
            <div style={{ color: "black" }}>
              <span
                style={{
                  color: "black",
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "36px",
                }}
              >
                Autorización de Datos
              </span>
              <br />
              <br />
              Para acceder a los beneficios de nuestras iniciativas y a
              recomendaciones personalizadas, la plataforma e instituciones
              aliadas, requieren tener información sobre tus resultados en el
              Chequeo Digital.
              <br />
              <br />
              ¿Autorizas al Chequeo Digital a compartir los datos de tu pyme y
              resultados a los siguientes aliados?
              <br />
              <br />
              <Grid container spacing={2}>
                {listacompartir &&
                  listacompartir?.map((colaborador, index) => (
                    <>
                      <Grid item sm={4}>
                        <>
                          <Box paddingTop={1}>
                            <CheckboxField
                              label=""
                              type="checkbox"
                              name={colaborador.slug + ".activo"}
                              id={colaborador.slug + ".activo"}
                              checked={
                                colaborador.slug in form_reg.values &&
                                form_reg.values[colaborador.slug]["activo"]
                              }
                              value={
                                colaborador.slug in form_reg.values &&
                                form_reg.values[colaborador.slug]["activo"]
                              }
                              onChange={form_reg.handleChange}
                              onBlur={form_reg.handleBlur}
                              onClick={handleColaborador(colaborador.slug)}
                            />
                            <span
                              style={{
                                color: "black",
                                fontSize: "18px",
                                fontWeight: "600",
                                marginLeft: "-20px",
                              }}
                            >
                              {colaborador.nombre}
                            </span>
                            <br />
                            <Link
                              style={{
                                color: "black",
                                fontSize: "12px",
                                textDecorationLine: "underline",
                                marginLeft: "20px",
                              }}
                              onClick={handleOpenTC(colaborador.slug)}
                            >
                              Ver Términos y Condiciones
                            </Link>
                          </Box>
                        </>
                        {colaborador.iniciativas &&
                          colaborador.iniciativas.map((iniciativ, indx) => (
                            <>
                              <Grid item sm={12}>
                                <CheckboxField
                                  label={iniciativ.nombre}
                                  type="checkbox"
                                  name={
                                    colaborador.slug +
                                    ".iniciativas." +
                                    iniciativ.slug
                                  }
                                  id={
                                    colaborador.slug +
                                    ".iniciativas." +
                                    iniciativ.slug
                                  }
                                  checked={
                                    colaborador.slug in form_reg.values &&
                                    form_reg.values[colaborador.slug][
                                      "iniciativas"
                                    ][iniciativ.slug]
                                  }
                                  value={
                                    colaborador.slug in form_reg.values &&
                                    form_reg.values[colaborador.slug][
                                      "iniciativas"
                                    ][iniciativ.slug]
                                  }
                                  onChange={form_reg.handleChange}
                                  onBlur={form_reg.handleBlur}
                                  onClick={handleIniciativa(
                                    iniciativ.slug,
                                    colaborador.slug
                                  )}
                                  style={{ marginLeft: "10px" }}
                                />
                              </Grid>
                            </>
                          ))}
                      </Grid>
                    </>
                  ))}
              </Grid>
            </div>
            <br />
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ width: "50%" }}>
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  name="noaceptar"
                  style={{
                    width: "180px",
                    height: "40px",
                    background: "#2B44FF",
                    color: "white",
                    border: "2px solid white",
                  }}
                  onClick={aceptoCompartir}
                >
                  Enviar
                </Button>
              </div>
            </div>
            {/* Autoriza al Ministerio de Economía, Fomento y Turismo a compartir los datos de tu pyme y resultados del Chequeo Digital 
                    para acceder a las iniciativas del programa. */}
            <br />
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
          open={open_d}
          onClose={handleCloseData}
        >
          <div style={modalStyleData} className={classes.paper}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <span className="tct">Requiere completar Datos</span>
                <br />
                <br />
                Debes completar los datos de <strong>
                  {completa?.nombre}
                </strong>{" "}
                para continuar tu Chequeo Digital.
                <br />
                <br />
              </div>
              <div>
                <img src={close} onClick={handleCloseData} alt="Close" />
              </div>
            </div>
            <div>
              <form action="" onSubmit={form_reg.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <SelectTextField
                      label="TAMAÑO DE LA EMPRESA"
                      name="empresatamano_id"
                      id="empresatamano_id"
                      value={form_reg.values.empresatamano_id}
                      error={Boolean(fieldErrors.empresatamano_id)}
                      helperText={fieldErrors.empresatamano_id}
                      onChange={form_reg.handleChange}
                      onBlur={form_reg.handleBlur}
                      options={listatamannos}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <SelectTextField
                      label="NÚMERO DE TRABAJADORES"
                      name="empresaempleado_id"
                      id="empresaempleado_id"
                      value={form_reg.values.empresaempleado_id}
                      error={Boolean(fieldErrors.empresaempleado_id)}
                      helperText={fieldErrors.empresaempleado_id}
                      onChange={form_reg.handleChange}
                      onBlur={form_reg.handleBlur}
                      options={listaemplea}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <SelectTextField
                      label="Área en la cual trabaja"
                      name="cargo_id"
                      id="cargo_id"
                      value={form_reg.values.cargo_id}
                      error={Boolean(fieldErrors.cargo_id)}
                      helperText={fieldErrors.cargo_id}
                      onChange={form_reg.handleChange}
                      onBlur={form_reg.handleBlur}
                      options={listacargos}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      name="ingresar"
                      disabled={!(form_reg.isValid && form_reg.dirty)}
                      fullWidth
                    >
                      Actualizar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Modal>
      </div>
      {/*           <div style={{ marginTop: "50px" }}>
          <ReactJson src={form_reg.values} collapsed="false" />
        </div> */}
    </>
  );
}

export default MainPanel;
