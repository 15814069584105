import React, {useContext, useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import './scss/style.scss';
import MainPanel from './component/MainPanel';
import LoginPanel from './component/LoginPanel';
import RegisterPanel from "./component/RegisterPanel";
import Register from "./component/RegisterForm";
import RegisterCompany from "./component/RegisterCompany";
import RecoveryPanel from "./component/RecoverPanel";
import RecoveryDniPanel from "./component/RecoverDniPanel";
import RecoverMsg from "./component/RecoverMsg";
import RegisterMsg from "./component/RegisterMsg";
import RecoverDniMsg from "./component/RecoverDniMsg.js";
import RegisterMsgcu from "./component/RegisterMsgcu";
import ClaveUnica from "./component/ClaveUnica";
import RegisterPassword from "./component/RegisterPassword";
import DatosClaveUnica from "./component/DatosClaveUnica";
import CorreoMsg from "./component/CorreoMsg";
import CorreoErrorMsg from "./component/CorreoErrorMsg";
import RegisterPasswordMsg from "./component/RegisterPasswordMsg";
import MainSurvey from "./component/MainSurvey";
import MainQuestion from "./component/Question";
import SurveyResult from "./component/SurveyResult";
import SurveyOffer from "./component/SurveyOffer";

import Header from "./component/Header"
import {ContextGlobal} from './context/globalContext'
import ThemeProvider from './lib/theme';
import ResultLayout from './lib/result/ResultLayout';
import DashboardLayout from './lib/dashboard/DashboardLayout';
import QuestionLayout from './lib/question/QuestionLayout';
import SurveysLayout from './lib/surveys/SurveysLayout';
import OfferLayout from './lib/offer/OfferLayout';
import LandingColaborador from './component/LandingColaborador';

const App = () => {
    
    const {vg} = useContext(ContextGlobal);
    const {user} = useContext(ContextGlobal);
    const {authSes} = useContext(ContextGlobal);
    const [readSes, setReadSes] = useState(false);

    useEffect( () => {
        if (sessionStorage.getItem('access_token') !== null && !readSes) {
          authSes(sessionStorage.getItem('access_token')); 
          setReadSes(true)
        }}
    )

    return(
    <ThemeProvider>
      <Router>
        <Header/>
        <main>
          <Route path="/" exact={true}>
            {user.requiere_registro_claveunica === 1 ? <DatosClaveUnica/> :
            (user.email_verified_at === null ? <RegisterMsgcu/> :
            (user.nombre === "" ? <RegisterPanel/> :  
            (vg.log === true ? <MainPanel/> : <RegisterPanel/>))) }
          </Route>
          <Route path="/login" exact={true}>
            <LoginPanel/>
          </Route>
          <Route path="/register" exact={true}>
            <RegisterPanel/>
          </Route>
          <Route path="/recovery" exact={true}>
            <RecoveryPanel/>
          </Route>
          <Route path="/recoverydni" exact={true}>
            <RecoveryDniPanel/>
          </Route>
          <Route path="/recovermsg" exact={true}>
            <RecoverMsg/>
          </Route>
          <Route path="/recoverdnimsg" exact={true}>
            <RecoverDniMsg/>
          </Route>          
          <Route path="/registerform" exact={true}>
            <Register/>
          </Route>
          <Route path="/registerpyme" exact={true}>
            <RegisterCompany/>
          </Route>
          <Route path="/registermsg" exact={true}>
            <RegisterMsg/>
          </Route>
          <Route path="/registercumsg" exact={true}>
            <RegisterMsgcu/>
          </Route>
          <Route path="/correomsg" exact={true}>
            <CorreoMsg/>
          </Route>
          <Route path="/correomsgerr" exact={true}>
            <CorreoErrorMsg/>
          </Route>
          <Route path="/datosclaveunica" exact={true}>
            <DatosClaveUnica/>
          </Route>
          <Route path="/passwordmsg" exact={true}>
            <RegisterPasswordMsg/>
          </Route>

          <Route path="/resultado" exact={true}>
            <ResultLayout/>
          </Route>
          <Route path="/dashboard" exact={true}>
            <DashboardLayout/>
          </Route>
          <Route path="/question" exact={true}>
            <QuestionLayout/>
          </Route>
          <Route path="/survey" exact={true}>
            <SurveysLayout/>
          </Route>
          <Route path="/oferta" exact={true}>
            <OfferLayout/>
          </Route>

          <Route path="/colaborador/:organizacion/:iniciativa?" exact={true} component={LandingColaborador}/>
          
          <Route path="/resultsurvey/:cid/:recent?">
            {(vg.log === true ? <SurveyResult/> : <LoginPanel/>)}
          </Route>
          <Route path="/offersurvey/:cid/dim/:did" component={SurveyOffer} />
          <Route path="/mainsurvey/:sid" component={MainSurvey} />
          <Route path="/survey/:sid/stage/:tid" component={MainQuestion} />
          <Route path="/password/reset/:email/:token" component={RegisterPassword} />
          <Route path="/login/claveunica/:token" component={ClaveUnica} />

        </main>
      </Router>
    </ThemeProvider>
  )
}

export default App;
